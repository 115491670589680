import React from 'react'

interface ArrowUpProps {
  color: string
  thickness?: string
}

export const ArrowUp = ({ color, thickness }: ArrowUpProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711C5.68342 10.0976 6.31658 10.0976 6.70711 9.70711L11 5.41421L11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21L13 5.41421L17.2929 9.70711C17.6834 10.0976 18.3166 10.0976 18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289L12.7071 2.29289Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}

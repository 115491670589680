import React from 'react'
import { Container } from './WrapKlarna.styles'

interface WrapKlarnaProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  children: React.ReactNode
  bgColor?: string
}

export const WrapKlarna = ({ children, bgColor }: WrapKlarnaProps) => {
  return <Container bgColor={bgColor}>{children}</Container>
}

import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const DesktopSettingsErrorIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        width="304"
        height="54"
        viewBox="0 0 304 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.5C0 2.73858 2.23858 0.5 5 0.5H299C301.761 0.5 304 2.73858 304 5.5V53.5H0V5.5Z"
          fill="#DCDBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 14.5C31.6863 14.5 29 17.1863 29 20.5C29 23.8137 31.6863 26.5 35 26.5H254C257.314 26.5 260 23.8137 260 20.5C260 17.1863 257.314 14.5 254 14.5H35ZM9 24.5C11.2091 24.5 13 22.7091 13 20.5C13 18.2909 11.2091 16.5 9 16.5C6.79086 16.5 5 18.2909 5 20.5C5 22.7091 6.79086 24.5 9 24.5ZM25 20.5C25 22.7091 23.2091 24.5 21 24.5C18.7909 24.5 17 22.7091 17 20.5C17 18.2909 18.7909 16.5 21 16.5C23.2091 16.5 25 18.2909 25 20.5ZM268 24.5C270.209 24.5 272 22.7091 272 20.5C272 18.2909 270.209 16.5 268 16.5C265.791 16.5 264 18.2909 264 20.5C264 22.7091 265.791 24.5 268 24.5ZM293.5 14.5C292.672 14.5 292 15.1716 292 16C292 16.8284 292.672 17.5 293.5 17.5C294.328 17.5 295 16.8284 295 16C295 15.1716 294.328 14.5 293.5 14.5ZM293.5 19C292.672 19 292 19.6716 292 20.5C292 21.3284 292.672 22 293.5 22C294.328 22 295 21.3284 295 20.5C295 19.6716 294.328 19 293.5 19ZM292 25C292 24.1716 292.672 23.5 293.5 23.5C294.328 23.5 295 24.1716 295 25C295 25.8284 294.328 26.5 293.5 26.5C292.672 26.5 292 25.8284 292 25ZM10 38.5C7.23858 38.5 5 40.7386 5 43.5V53.5H299V43.5C299 40.7386 296.761 38.5 294 38.5H10Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 29.5C39.9706 29.5 44 25.4706 44 20.5C44 15.5294 39.9706 11.5 35 11.5C30.0294 11.5 26 15.5294 26 20.5C26 25.4706 30.0294 29.5 35 29.5ZM35 31.5C41.0751 31.5 46 26.5751 46 20.5C46 14.4249 41.0751 9.5 35 9.5C28.9249 9.5 24 14.4249 24 20.5C24 26.5751 28.9249 31.5 35 31.5Z"
          fill="#EC0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5556 20.5C34.2804 20.5 34.8894 20.022 35.0621 19.375L38.6111 19.375C38.8259 19.375 39 19.2071 39 19C39 18.7929 38.8259 18.625 38.6111 18.625L35.0621 18.625C34.8894 17.978 34.2804 17.5 33.5556 17.5C32.6964 17.5 32 18.1716 32 19C32 19.8284 32.6964 20.5 33.5556 20.5ZM34.3333 19C34.3333 19.4142 33.9851 19.75 33.5556 19.75C33.126 19.75 32.7778 19.4142 32.7778 19C32.7778 18.5858 33.126 18.25 33.5556 18.25C33.9851 18.25 34.3333 18.5858 34.3333 19Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.9379 21.625C36.1106 20.978 36.7196 20.5 37.4444 20.5C38.3036 20.5 39 21.1716 39 22C39 22.8284 38.3036 23.5 37.4444 23.5C36.7196 23.5 36.1106 23.022 35.9379 22.375L32.3889 22.375C32.1741 22.375 32 22.2071 32 22C32 21.7929 32.1741 21.625 32.3889 21.625L35.9379 21.625ZM36.6667 22C36.6667 21.5858 37.0149 21.25 37.4444 21.25C37.874 21.25 38.2222 21.5858 38.2222 22C38.2222 22.4142 37.874 22.75 37.4444 22.75C37.0149 22.75 36.6667 22.4142 36.6667 22Z"
          fill="black"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};
  padding: ${(props) => (props.padding ? props.padding : '38px 25px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  border: ${(props) => (props.border ? props.border : 'none')};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
  color: ${(props) => (props.color ? props.color : 'black')};

  @media (max-width: 900px) {
    width: calc(100% - 40px);
    height: auto !important;
    min-width: auto;
    margin: 10px 20px;
    padding: 15px;
    box-shadow: none !important;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto !important;
    min-width: auto;
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '10px')};
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : '10px')};
    box-shadow: none !important;
  }
`

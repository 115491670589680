import { Button, ContentContainer, Header } from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import { devLog } from '../../methods/devLog'
import {
  ButtonText,
  IntroParagraph,
  IntroAddressParagraph,
  UploadTypeList,
} from '../../styles/generic.styles'
import {
  HorizontalIconContainer,
  IkanoStyleWrap,
} from '../../styles/ikano.styles'
import {
  Divider,
  JitpayGeneralConditionsSpacerMobile,
} from '../../styles/jitpay.styles'
import {
  ContentContainerBottomLeft,
  ContentContainerBottomFixed,
  MarginOnly,
} from '../../styles/layout.styles'
import { IkanoIDBackIcon } from './IkanoIDBackIcon'
import { IkanoIDFrontIcon } from './IkanoIDFrontIcon'

export const AddressVerificationTwoSidedIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { context } = store.ScenarioState
  const { addressVerificationTwoSidedIkano: trans } =
    store.TranslationsState.translations

  const [mobileVersion, setMobileVersion] = useState(false)

  const handleProceed = async () => {
    try {
      store.ScenarioState.setCurrentPage(1)
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <IkanoStyleWrap>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {!context?.isRetryAttempt ? trans.header : trans.headerRetry}
        </Header>
        <IntroParagraph>
          {!context?.isRetryAttempt
            ? trans.addressDescription
            : trans.addressDescriptionRetry}
          <br />
          <MarginOnly margin="10px 0 20px 0">
            <IntroAddressParagraph fontFamily={theme.globals.fontFamilyBold}>
              {context?.expectedAddress?.street}
            </IntroAddressParagraph>
            <IntroAddressParagraph fontFamily={theme.globals.fontFamilyBold}>
              {context?.expectedAddress?.postalCode}{' '}
              {context?.expectedAddress?.city}
            </IntroAddressParagraph>
          </MarginOnly>
        </IntroParagraph>

        <IntroParagraph
          color={theme.globals.linkColor}
          colorHover={theme.globals.linkColorHover}
        >
          {!context?.isRetryAttempt
            ? trans.descriptionPartOne
            : trans.descriptionPartOneRetry}
        </IntroParagraph>
        <UploadTypeList />
        <IntroParagraph
          color={theme.globals.linkColor}
          colorHover={theme.globals.linkColorHover}
        >
          {!context?.isRetryAttempt
            ? trans.descriptionPartTwo
            : trans.descriptionPartTwoRetry}
        </IntroParagraph>
        <Divider margin="30px 0" />
        <HorizontalIconContainer>
          <IkanoIDFrontIcon />
          <IkanoIDBackIcon />
        </HorizontalIconContainer>
        {mobileVersion ? <JitpayGeneralConditionsSpacerMobile /> : <></>}
        <ContentContainerBottomFixed>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            id="btn-proceed"
            onClick={handleProceed}
            {...theme.button}
            minWidth="224px"
            paddingMobile="14px 40px"
          >
            <ButtonText>{trans.proceed}</ButtonText>
          </Button>
        </ContentContainerBottomFixed>
      </IkanoStyleWrap>
    </ContentContainer>
  )
})

import React from 'react'

export const ResidencePermit = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="residence-permit">
        <rect
          width="48"
          height="48"
          rx="10"
          className="document--tenary--fill"
        />
        <g id="Union">
          <path
            d="M35 18C35.5523 18 36 18.4477 36 19C36 19.5523 35.5523 20 35 20L26 20C25.4477 20 25 19.5523 25 19C25 18.4477 25.4477 18 26 18L35 18Z"
            className="document--primary--fill"
          />
          <path
            d="M29 23C29 22.4477 28.5523 22 28 22H26C25.4477 22 25 22.4477 25 23C25 23.5523 25.4477 24 26 24H28C28.5523 24 29 23.5523 29 23Z"
            className="document--primary--fill"
          />
          <path
            d="M33 26C33.5523 26 34 26.4477 34 27C34 27.5523 33.5523 28 33 28H26C25.4477 28 25 27.5523 25 27C25 26.4477 25.4477 26 26 26H33Z"
            className="document--primary--fill"
          />
          <path
            d="M20 20C20.5523 20 21 20.4477 21 21V23.0858L22.7071 24.7929C23.0976 25.1834 23.0976 25.8166 22.7071 26.2071C22.3799 26.5343 21.8824 26.5874 21.5 26.3663V30H18.5V27.5C18.5 26.9477 18.0523 26.5 17.5 26.5C16.9477 26.5 16.5 26.9477 16.5 27.5V30H13.5V26.3663C13.1176 26.5874 12.6201 26.5343 12.2929 26.2071C11.9024 25.8166 11.9024 25.1834 12.2929 24.7929L16.7222 20.3636C17.1518 19.934 17.8482 19.934 18.2778 20.3636L19 21.0858V21C19 20.4477 19.4477 20 20 20Z"
            className="document--primary--fill"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 16C7 14.3431 8.34315 13 10 13H38C39.6569 13 41 14.3431 41 16V34C41 35.6569 39.6569 37 38 37H10C8.34315 37 7 35.6569 7 34V16ZM10 15C9.44771 15 9 15.4477 9 16V34C9 34.5523 9.44772 35 10 35H38C38.5523 35 39 34.5523 39 34V16C39 15.4477 38.5523 15 38 15H10Z"
            className="document--primary--fill"
          />
        </g>
      </g>
    </svg>
  )
}

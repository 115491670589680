import { action, makeAutoObservable } from 'mobx'
import { keepAlive } from '../methods/keepAlive'

const KEEP_SESSION_ALIVE_INTERVAL_MS = 10000

export class TrackingStore {
  rootStore
  shouldKeepAlive: boolean = true
  keepSessionAliveInterval: ReturnType<typeof setTimeout> = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setShouldKeepAlive(payload: boolean): void {
    this.shouldKeepAlive = payload
  }

  @action async keepSessionAlive() {
    if (this.shouldKeepAlive) {
      keepAlive()
    }
  }

  @action initKeepSessionAliveInterval() {
    this.shouldKeepAlive = true

    if (!this.keepSessionAliveInterval) {
      this.keepSessionAliveInterval = setInterval(
        () => void this.keepSessionAlive(),
        KEEP_SESSION_ALIVE_INTERVAL_MS
      )
    }
  }

  @action disposeKeepSessionAliveInterval() {
    this.shouldKeepAlive = false

    if (this.keepSessionAliveInterval) {
      clearTimeout(this.keepSessionAliveInterval)
      this.keepSessionAliveInterval = null
    }
  }
}

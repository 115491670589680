import styled from 'styled-components'

interface BarContainerInterface {
  border?: string
  height?: string
  width?: string
  borderRadius?: string
  background?: string
}

interface ContainerInterface {
  margin?: string
  padding?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-progress-bar',
})<ContainerInterface>`
  box-sizing: border-box;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
`

interface CounterInterface {
  margin?: string
  padding?: string
  fontSize?: string
  color?: string
}

export const Counter = styled.h2.attrs({
  className: 'authflow-progress-bar-counter',
})<CounterInterface>`
  width: 100%;
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  color: ${(props) => (props.color ? props.color : '#000')};
`

export const BarContainer = styled.div.attrs({
  className: 'authflow-progress-bar__container',
})<BarContainerInterface>`
  border: ${(props) => (props.border ? props.border : '1px solid gray')};
  height: ${(props) => (props.height ? props.height : '32px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  background: ${(props) => (props.background ? props.background : 'blue')};
  overflow: hidden;
  box-sizing: border-box;
`

interface BarInterface {
  barFill?: string
  width: string
}

export const Bar = styled.div.attrs({
  className: 'authflow-progress-bar__bar',
})<BarInterface>`
  background: ${(props) => (props.barFill ? props.barFill : 'blue')};
  height: 100%;
  background-size: 300% 300%;
  transition: all 0.3s ease-out;
  width: ${(props) => (props.width ? props.width : '0%')};
  animation: gradient 1s ease-out infinite;

  @keyframes gradient {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 50% 50%;
    }
  }
`

interface InfoMessageInterface {
  textAlign?: string
  fontSize?: string
  margin?: string
}

export const InfoMessage = styled.div.attrs({
  className: 'authflow-progress-bar-info-message',
})<InfoMessageInterface>`
  width: 100%;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  margin: ${(props) => (props.margin ? props.margin : '6px 0 0 0')};
`

import styled from 'styled-components'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../components/App'
import { ButtonText, ParagraphCentered } from '../styles/generic.styles'
import {
  CenteredContent,
  ContentContainerBottomFixed,
} from '../styles/layout.styles'
import { SafariLogo } from '../components/SafariLogo'
import { forceRedirect } from '../methods/forceRedirect'
import {
  Button,
  Header,
  ContentContainer,
  LoadingSpinner,
} from '../components/Generic'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f7f9fc; */
  border-radius: 50%;
  width: 140px;
  height: 140px;
`

export const ErrorIosBrowserNotSupportedPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { ErrorIosBrowserNotSupportedPage: trans } =
    store.TranslationsState.translations

  const [isProceeding, setIsProceeding] = useState(false)

  const handleProceed = () => {
    setIsProceeding(true)

    const location = window.location.href
    const url = new URL(location)
    const goToSafariPathEncoded = url.searchParams.get('goToSafariPath')
    const goToSafariPath = decodeURIComponent(goToSafariPathEncoded)

    if (goToSafariPath) {
      const goToSafariUrl = `x-safari-${process.env.WEB_API_URL}${goToSafariPath}`
      forceRedirect(goToSafariUrl)
    } else {
      setIsProceeding(false)
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <Wrapper>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.header}
        </Header>

        <Icon>
          <SafariLogo />
        </Icon>

        <ParagraphCentered>{trans.description}</ParagraphCentered>

        <ContentContainerBottomFixed>
          <CenteredContent>
            <Button
              onClick={handleProceed}
              {...theme.button}
              disabled={isProceeding}
              minWidth="224px"
              paddingMobile="14px 40px"
              id="page-submit"
            >
              {isProceeding ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <ButtonText>{trans.proceed}</ButtonText>
              )}
            </Button>
          </CenteredContent>
        </ContentContainerBottomFixed>
      </Wrapper>
    </ContentContainer>
  )
})

import { AppStateStore } from './AppState.store'
import { InterfaceStore } from './Interface.store'
import { ScenarioStore } from './Scenario.store'
import { TrackingStore } from './Tracking.store'
import { TranslationsStore } from './Translations.store'

export class RootStore {
  AppState: AppStateStore
  TranslationsState: TranslationsStore
  InterfaceState: InterfaceStore
  ScenarioState: ScenarioStore
  TrackingState: TrackingStore

  constructor() {
    this.AppState = new AppStateStore(this)
    this.TranslationsState = new TranslationsStore(this)
    this.InterfaceState = new InterfaceStore(this)
    this.ScenarioState = new ScenarioStore(this)
    this.TrackingState = new TrackingStore(this)
  }
}

export const rootStore = new RootStore()

import { WrapKontomatik } from '../../components/Generic'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../components/App'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'

let embedKontomatik = null

export const KontomatikPage = observer(() => {
  const store = useContext(StoreContext)
  const { kontomatikSelectedBank } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { language } = store.TranslationsState

  const getKontomatikContext = async (): Promise<void> => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(`${apiUrl}/kontomatik/get-context`, {
        withCredentials: true,
      })

      const { providerClientId, bankStatementUpload } = res.data

      store.AppState.setShouldUploadBankStatement(bankStatementUpload)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      embedKontomatik = (window as any).embedKontomatik

      embedKontomatik({
        client: providerClientId,
        fluidWidth: true,
        divId: 'kontomatik',
        locale: language,
        onUnsupportedTarget: function (target) {
          store.ScenarioState.setCurrentPage(currentPageIndex + 1)
          store.AppState.setKontomatikSelectedBank('other')
          store.AppState.setShouldUploadBankStatement(true)
        },
        onSuccess: function (target, sessionId, sessionIdSignature, options) {
          const apiUrl = process.env.WEB_API_URL

          axiosInstance.post(
            `${apiUrl}/kontomatik/authentication-complete`,
            {
              sessionId: sessionId,
              sessionIdSignature: sessionIdSignature,
            },
            {
              withCredentials: true,
            }
          )

          store.ScenarioState.setCurrentPage(currentPageIndex + 1)
        },
        onTargetSelected: (data: { name: string; officialName: string }) => {
          store.AppState.setKontomatikSelectedBank(data.name)
        },
      })
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    getKontomatikContext()
  }, [])

  useEffect(() => {
    if (
      kontomatikSelectedBank === 'Pko' ||
      kontomatikSelectedBank === 'Inteligo' ||
      kontomatikSelectedBank === 'PkoApi'
    ) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)

      store.AppState.setKontomatikSelectedBank('pko')
      store.AppState.setShouldUploadBankStatement(true)
    }
  }, [kontomatikSelectedBank])

  return (
    <WrapKontomatik
      {...theme.wrapKontomatik}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      padding="20px"
    >
      <div id="kontomatik" />
    </WrapKontomatik>
  )
})

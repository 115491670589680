import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const AndroidPadlockErrorIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        width="304"
        height="142"
        viewBox="0 0 304 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 27C0 12.0883 12.0883 0 27 0H277C291.912 0 304 12.0883 304 27V142H0V27Z"
          fill="#DCDBE2"
        />
        <path
          d="M52 55C52 45.0589 60.0589 37 70 37H213C222.941 37 231 45.0589 231 55C231 64.9411 222.941 73 213 73H70C60.0589 73 52 64.9411 52 55Z"
          fill="white"
        />
        <path
          d="M15 55C15 48.9249 19.9249 44 26 44C32.0751 44 37 48.9249 37 55C37 61.0751 32.0751 66 26 66C19.9249 66 15 61.0751 15 55Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263 46H251C249.343 46 248 47.3431 248 49V61C248 62.6569 249.343 64 251 64H263C264.657 64 266 62.6569 266 61V49C266 47.3431 264.657 46 263 46ZM251 44C248.239 44 246 46.2386 246 49V61C246 63.7614 248.239 66 251 66H263C265.761 66 268 63.7614 268 61V49C268 46.2386 265.761 44 263 44H251Z"
          fill="white"
        />
        <path
          d="M283 47.625C283 46.1753 284.175 45 285.625 45C287.075 45 288.25 46.1753 288.25 47.625C288.25 49.0747 287.075 50.25 285.625 50.25C284.175 50.25 283 49.0747 283 47.625Z"
          fill="white"
        />
        <path
          d="M283 55.5C283 54.0503 284.175 52.875 285.625 52.875C287.075 52.875 288.25 54.0503 288.25 55.5C288.25 56.9497 287.075 58.125 285.625 58.125C284.175 58.125 283 56.9497 283 55.5Z"
          fill="white"
        />
        <path
          d="M283 63.375C283 61.9253 284.175 60.75 285.625 60.75C287.075 60.75 288.25 61.9253 288.25 63.375C288.25 64.8247 287.075 66 285.625 66C284.175 66 283 64.8247 283 63.375Z"
          fill="white"
        />
        <path
          d="M15 18.5C15 16.0147 17.0147 14 19.5 14H47.5C49.9853 14 52 16.0147 52 18.5C52 20.9853 49.9853 23 47.5 23H19.5C17.0147 23 15 20.9853 15 18.5Z"
          fill="white"
        />
        <path
          d="M272 18.5C272 16.0147 274.015 14 276.5 14H283.5C285.985 14 288 16.0147 288 18.5C288 20.9853 285.985 23 283.5 23H276.5C274.015 23 272 20.9853 272 18.5Z"
          fill="white"
        />
        <path
          d="M251 18.5C251 16.0147 253.015 14 255.5 14H262.5C264.985 14 267 16.0147 267 18.5C267 20.9853 264.985 23 262.5 23H255.5C253.015 23 251 20.9853 251 18.5Z"
          fill="white"
        />
        <path
          d="M8 100C8 93.3726 13.3726 88 20 88H284C290.627 88 296 93.3726 296 100V142H8V100Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70 80C83.8071 80 95 68.8071 95 55C95 41.1929 83.8071 30 70 30C56.1929 30 45 41.1929 45 55C45 68.8071 56.1929 80 70 80ZM70 84C86.0163 84 99 71.0163 99 55C99 38.9837 86.0163 26 70 26C53.9837 26 41 38.9837 41 55C41 71.0163 53.9837 84 70 84Z"
          fill="#EC0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67 51H72V49.5C72 48.1193 70.8807 47 69.5 47C68.1193 47 67 48.1193 67 49.5V51ZM74 51C75.1046 51 76 51.8954 76 53V62C76 63.1046 75.1046 64 74 64H65C63.8954 64 63 63.1046 63 62V53C63 51.8954 63.8954 51 65 51V49.5C65 47.0147 67.0147 45 69.5 45C71.9853 45 74 47.0147 74 49.5V51Z"
          fill="black"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

import React from 'react'

interface DeviceMobileProps {
  color: string
  thickness?: string
}

export const DeviceMobile = ({ color, thickness }: DeviceMobileProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20C5 21.5067 6.07616 23 7.71429 23H16.2857C17.9238 23 19 21.5067 19 20V4C19 2.49332 17.9238 1 16.2857 1H7.71429C6.07616 1 5 2.49332 5 4V20ZM7.71429 21C7.45886 21 7 20.7025 7 20V4C7 3.29754 7.45886 3 7.71429 3H16.2857C16.5411 3 17 3.29754 17 4V20C17 20.7025 16.5411 21 16.2857 21H7.71429Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}

import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import { MicropaymentInfoContent } from '../../styles/generic.styles'
import { MicropaymentInfoGraph } from '../../styles/ikano.styles'
import { IkanoOpenbankingInfoGraph } from './IkanoOpenbankingInfoGraph'
import { TermsAndConditions } from '../../components/TermsAndConditions/TermsAndConditions'

export const TermsAndConditionsMicropaymentInfoIkanoOpenBankingPage = observer(
  () => {
    const store = useContext(StoreContext)
    const { micropaymentInfoIkanoOpenBankingPage: trans } =
      store.TranslationsState.translations

    return (
      <TermsAndConditions
        showHeader={false}
        showTitle={false}
        buttonText={trans.proceed}
        additionalParagraph={
          <>
            <MicropaymentInfoGraph>
              <IkanoOpenbankingInfoGraph />
            </MicropaymentInfoGraph>

            {trans.content.split('\n').map((item, i) => (
              <MicropaymentInfoContent key={i}>{item}</MicropaymentInfoContent>
            ))}
          </>
        }
      />
    )
  }
)

import React, { useEffect, useState } from 'react'

import { Icon } from '../Icon/Icon'
import { Condition, Content, GoTo, MainLabel } from './DocumentCheckbox.styles'

interface DocumentCheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  inputName: string
  description: string
  externalUrl: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDecision: (event: any) => void
  value: boolean
  iconColor?: string
  iconHover?: string
  width?: string
  contentFontsize?: string
  margin?: string
  iconSize?: string
  onClick?: () => void
}

export const DocumentCheckbox = ({
  inputName,
  description,
  externalUrl,
  handleDecision,
  value,
  iconColor,
  iconHover,
  width,
  contentFontsize,
  margin,
  iconSize,
  onClick,
}: DocumentCheckboxProps) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Condition width={width}>
      <Content margin={margin} contentFontsize={contentFontsize}>
        <MainLabel htmlFor={inputName} data-testid={`${inputName}_label`}>
          <input
            id={inputName}
            type="checkbox"
            name={inputName}
            onChange={(event) => {
              setInputValue(event.target.checked)
              handleDecision({
                checked: event.target.checked,
                id: event.target.id,
              })
            }}
            checked={inputValue}
            data-testid={`${inputName}_input`}
          />
          <span className="label-text">{description}</span>
        </MainLabel>
        {onClick ? (
          <GoTo onClick={onClick} data-testid={`${inputName}_link`}>
            <Icon
              type="external-link"
              size={iconSize ? iconSize : '18px'}
              color={iconColor}
              colorHover={iconHover}
            />
          </GoTo>
        ) : (
          <GoTo
            target="_blank"
            href={externalUrl}
            data-testid={`${inputName}_link`}
          >
            <Icon
              type="external-link"
              size={iconSize ? iconSize : '18px'}
              color={iconColor}
              colorHover={iconHover}
            />
          </GoTo>
        )}
      </Content>
    </Condition>
  )
}

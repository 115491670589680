import React from 'react'

export const Bank = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M24 16C24.5523 16 25 15.5523 25 15C25 14.4477 24.5523 14 24 14C23.4477 14 23 14.4477 23 15C23 15.5523 23.4477 16 24 16Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4632 10.1563C23.7908 9.94789 24.2094 9.94789 24.537 10.1563L35.537 17.1563C35.9121 17.395 36.0845 17.8527 35.9602 18.2796C35.8359 18.7065 35.4447 19 35.0001 19H33V27C34.6569 27 36 28.3431 36 30C36 30.3506 35.9398 30.6872 35.8293 31H36C37.6569 31 39 32.3431 39 34C39 35.6569 37.6569 37 36 37H12C10.3431 37 9 35.6569 9 34C9 32.3431 10.3431 31 12 31H12.1707C12.0602 30.6872 12 30.3506 12 30C12 28.3431 13.3431 27 15 27V19L13.0001 19C12.5555 19 12.1643 18.7065 12.04 18.2796C11.9157 17.8527 12.0881 17.395 12.4632 17.1563L23.4632 10.1563ZM16.5 17L24 12L31.5 17L16.5 17ZM19 20C19 19.4477 18.5523 19 18 19C17.4477 19 17 19.4477 17 20V26C17 26.5523 17.4477 27 18 27C18.5523 27 19 26.5523 19 26V20ZM23 20C23 19.4477 22.5523 19 22 19C21.4477 19 21 19.4477 21 20V26C21 26.5523 21.4477 27 22 27C22.5523 27 23 26.5523 23 26V20ZM26 19C26.5523 19 27 19.4477 27 20V26C27 26.5523 26.5523 27 26 27C25.4477 27 25 26.5523 25 26V20C25 19.4477 25.4477 19 26 19ZM31 20C31 19.4477 30.5523 19 30 19C29.4477 19 29 19.4477 29 20V26C29 26.5523 29.4477 27 30 27C30.5523 27 31 26.5523 31 26V20ZM14 30C14 29.4477 14.4477 29 15 29H33C33.5523 29 34 29.4477 34 30C34 30.5523 33.5523 31 33 31H15C14.4477 31 14 30.5523 14 30ZM12 33C11.4477 33 11 33.4477 11 34C11 34.5523 11.4477 35 12 35H36C36.5523 35 37 34.5523 37 34C37 33.4477 36.5523 33 36 33H12Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

import React from 'react'
import { Container } from './WrapXs2a.styles'

interface WrapXs2aProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  display?: string
  linkColor?: string
  linkColorHover?: string
  fontFamily?: string
  fontFamilyHeadline?: string
  bgColor?: string
  input?: {
    bgColor?: string
    padding?: string
    borderRadius?: string
    border?: string
    boxShadow?: string
    margin?: string
    color?: string
    colorFocus?: string
    bgColorFocus?: string
    borderFocus?: string
    bgColorDisabled?: string
    borderDisabled?: string
    colorDisabled?: string
    fontSize?: string
    fontWeight?: string
    outline?: string
  }
  button?: {
    bgColor?: string
    padding?: string
    paddingMobile?: string
    borderRadius?: string
    minWidth?: string
    width?: string
    widthMobile?: string
    border?: string
    boxShadow?: string
    margin?: string
    color?: string
    bgColorHover?: string
    borderHover?: string
    colorHover?: string
    bgColorDisabled?: string
    borderDisabled?: string
    colorDisabled?: string
    fontSize?: string
    fontWeight?: string
  }
}

export const WrapXs2a = (props: WrapXs2aProps) => {
  return (
    <Container {...props}>
      <div id="XS2A-Form" data-xs2a="" />
    </Container>
  )
}

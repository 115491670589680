import React, { useEffect, useMemo } from 'react'

import { Container, DigitInput } from './OtpInput.styles'

export type OtpInputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  value: string
  valueLength: number
  onChange: (value: string) => void
  width?: string
  columnGap?: string
  marginTop?: string
  marginBottom?: string
  inputHeight?: string
  inputBorder?: string
  borderRadius?: string
  fontSize?: string
  focusColor?: string
  bgColor?: string
  focusBgColor?: string
  fontColor?: string
  firstInputRef?: React.RefObject<HTMLInputElement>
}

export const RE_DIGIT = new RegExp(/^[a-zA-Z0-9]*$/)

export function OtpInput({
  value,
  valueLength,
  onChange,
  width,
  columnGap,
  marginTop,
  marginBottom,
  inputHeight,
  inputBorder,
  borderRadius,
  fontSize,
  focusColor,
  bgColor,
  focusBgColor,
  fontColor,
  firstInputRef,
}: OtpInputProps) {
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items: Array<string> = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null

    if (nextElementSibling) {
      nextElementSibling.focus()
    } else {
      target.blur()
    }
  }

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const removeAt = (string: string, index: number) =>
    string.substring(0, index - 1) + string.substring(index, string.length)

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '
    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue)

      if (!isTargetValueDigit) {
        return
      }

      focusToNextInput(target)
    } else {
      const newValue = removeAt(value, idx + 1)
      onChange(newValue)
    }
  }

  const inputOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    target.setSelectionRange(0, targetValue.length)

    if (e.key === 'Backspace') {
      if (targetValue !== '') return
      else return focusToPrevInput(target)
    }

    if (e.key !== 'Backspace' || targetValue !== '') {
      return
    }

    focusToPrevInput(target)
  }

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e

    const prevInputEl = target.previousElementSibling as HTMLInputElement | null

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }

    target.setSelectionRange(0, target.value.length)
  }

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return () => {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  return (
    <Container
      width={width}
      columnGap={columnGap}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {valueItems.map((digit, idx) => (
        <DigitInput
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className="otp-input"
          value={digit ? digit : ''}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyUp={inputOnKeyUp}
          onFocus={inputOnFocus}
          height={inputHeight}
          border={inputBorder}
          borderRadius={borderRadius}
          fontSize={fontSize}
          focusColor={focusColor}
          bgColor={bgColor}
          focusBgColor={focusBgColor}
          fontColor={fontColor}
          ref={idx === 0 ? firstInputRef : null}
        />
      ))}
    </Container>
  )
}

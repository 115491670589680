import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { devLog } from './devLog'

export const configureAwsRum = (): AwsRum | undefined => {
  const guestRoleArn = process.env.AWS_RUM_ROLE_ARN
  const identityPoolId = process.env.AWS_RUM_IDENTITY_POOL_ID
  const endpoint = process.env.AWS_RUM_ENDPOINT
  const applicationId = process.env.AWS_RUM_APPLICATION_ID
  const region = process.env.AWS_RUM_REGION

  if (
    !guestRoleArn ||
    !identityPoolId ||
    !endpoint ||
    !applicationId ||
    !region
  ) {
    devLog('Unable to configure AWS RUM')
    devLog('guestRoleArn', guestRoleArn)
    devLog('identityPoolId', identityPoolId)
    devLog('endpoint', endpoint)
    devLog('applicationId', applicationId)
    devLog('region', region)
    return undefined
  }

  let awsRum: AwsRum | undefined

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn,
      identityPoolId,
      endpoint,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: false,
      enableXRay: false,
    }

    const APPLICATION_VERSION: string = '1.0.0'

    awsRum = new AwsRum(applicationId, APPLICATION_VERSION, region, config)
  } catch (error) {
    console.error('RUM error')
    console.error(error)
  }

  return awsRum
}

import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const MobileInitIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        width="168"
        height="314"
        viewBox="0 0 168 314"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M144.713 3C155.759 3 164.713 11.9543 164.713 23L164.713 291C164.713 302.046 155.759 311 144.713 311L22.4996 311C11.4539 311 2.49956 302.046 2.49956 291L2.49957 23C2.49957 11.9543 11.4539 2.99999 22.4996 2.99999L144.713 3Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.713 0.499999C157.139 0.5 167.213 10.5736 167.213 23L167.213 291C167.213 303.426 157.139 313.5 144.713 313.5L22.4996 313.5C10.0732 313.5 -0.000440487 303.426 -0.000439944 291L-0.00042823 23C-0.000427686 10.5736 10.0732 0.499993 22.4996 0.499994L144.713 0.499999ZM162.213 23C162.213 13.335 154.378 5.5 144.713 5.5L22.4996 5.49999C12.8346 5.49999 4.99957 13.335 4.99957 23L4.99956 291C4.99956 300.665 12.8346 308.5 22.4996 308.5L144.713 308.5C154.378 308.5 162.213 300.665 162.213 291L162.213 23Z"
          fill="#DCDBE2"
        />
        <path
          d="M127.753 183.693H154.446V232.973H127.753V183.693Z"
          fill="#FCFBFD"
        />
        <path
          d="M39.46 292.52L12.7666 292.52L12.7666 243.24L39.46 243.24L39.46 292.52Z"
          fill="#FCFBFD"
        />
        <path
          d="M12.7666 44.0664H78.4733V132.36H12.7666V44.0664Z"
          fill="#F6F5FA"
        />
        <path
          d="M12.7666 183.693H119.54V232.973H12.7666V183.693Z"
          fill="#FCFBFD"
        />
        <path
          d="M154.446 292.52L47.673 292.52L47.673 243.24L154.446 243.24L154.446 292.52Z"
          fill="#F6F5FA"
        />
        <path
          d="M88.7402 44.0664H154.447V132.36H88.7402V44.0664Z"
          fill="#FCFBFD"
        />
        <path
          d="M4.99121 23C4.99121 13.0589 13.0501 5 22.9912 5H144.212C154.154 5 162.212 13.0589 162.212 23V34H4.99121V23Z"
          fill="#FCFBFD"
        />
        <rect
          x="59.9932"
          y="15.3203"
          width="47.2267"
          height="14.3733"
          rx="7.18667"
          fill="#DCDBE2"
        />
        <path
          d="M15.6074 124C15.6074 122.343 16.9506 121 18.6074 121H148.607C150.264 121 151.607 122.343 151.607 124V172.515H15.6074V124Z"
          fill="#DCDBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.607 172.516H83.6074V193.122H148.607C150.264 193.122 151.607 191.779 151.607 190.122V172.516ZM124.518 181.486C124.92 181.084 124.92 180.432 124.518 180.029C124.116 179.627 123.463 179.627 123.061 180.029L117.608 185.483L115.245 183.12C114.843 182.718 114.19 182.718 113.788 183.12C113.386 183.523 113.386 184.175 113.788 184.577L117.608 188.397L124.518 181.486Z"
          fill="#61BC75"
        />
        <path
          d="M28.1436 131.303C26.4366 131.303 25.0527 132.687 25.0527 134.394C25.0527 136.101 26.4366 137.485 28.1436 137.485H85.6689C87.376 137.485 88.7598 136.101 88.7598 134.394C88.7598 132.687 87.376 131.303 85.6689 131.303H28.1436Z"
          fill="#BDC1CB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.6074 172.516H15.6074V190.122C15.6074 191.779 16.9506 193.122 18.6074 193.122H83.6074V172.516ZM53.427 180.029C53.8293 180.432 53.8293 181.084 53.427 181.486L51.0646 183.849L53.427 186.211C53.8293 186.614 53.8293 187.266 53.427 187.668C53.0246 188.071 52.3723 188.071 51.9699 187.668L49.6075 185.306L47.2452 187.668C46.8428 188.071 46.1905 188.071 45.7881 187.668C45.3857 187.266 45.3857 186.613 45.7881 186.211L48.1504 183.849L45.7881 181.486C45.3857 181.084 45.3857 180.432 45.7881 180.029C46.1905 179.627 46.8428 179.627 47.2452 180.029L49.6075 182.392L51.9699 180.029C52.3723 179.627 53.0246 179.627 53.427 180.029Z"
          fill="#BDC1CB"
        />
        <path
          d="M25.0527 146.758C25.0527 145.051 26.4366 143.667 28.1436 143.667H85.6689C87.376 143.667 88.7598 145.051 88.7598 146.758C88.7598 148.465 87.376 149.849 85.6689 149.849H28.1436C26.4366 149.849 25.0527 148.465 25.0527 146.758Z"
          fill="#BDC1CB"
        />
        <path
          d="M28.1417 156.03C26.4346 156.03 25.0508 157.414 25.0508 159.121C25.0508 160.828 26.4346 162.212 28.1417 162.212H60.5194C62.2265 162.212 63.6103 160.828 63.6103 159.121C63.6103 157.414 62.2265 156.03 60.5194 156.03H28.1417Z"
          fill="#BDC1CB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.333 136.969C102.333 133.84 105.02 131.303 108.333 131.303C111.647 131.303 114.333 133.84 114.333 136.969V142.882C113.91 143.063 113.536 143.335 113.235 143.674C111.075 143.803 109.363 145.595 109.363 147.787V150.795C109.029 150.85 108.684 150.878 108.333 150.878C105.02 150.878 102.333 148.341 102.333 145.212V136.969ZM109.363 160.151V151.839C109.027 151.885 108.683 151.909 108.333 151.909C104.417 151.909 101.242 148.91 101.242 145.212V143.666C101.242 143.097 100.754 142.636 100.151 142.636C99.549 142.636 99.0605 143.097 99.0605 143.666V145.212C99.0605 149.7 102.635 153.4 107.242 153.909V160.151H105.333C104.731 160.151 104.242 160.613 104.242 161.182C104.242 161.751 104.731 162.212 105.333 162.212H109.915C109.564 161.606 109.363 160.902 109.363 160.151ZM122.64 143.666C121.781 143.666 121.012 144.199 120.71 145.003L120.439 145.727H116.575C116.575 145.158 116.114 144.696 115.545 144.696C114.976 144.696 114.515 145.158 114.515 145.727H113.484C112.346 145.727 111.424 146.649 111.424 147.787V160.151C111.424 161.289 112.346 162.211 113.484 162.211H138.212C139.35 162.211 140.272 161.289 140.272 160.151V147.787C140.272 146.649 139.35 145.727 138.212 145.727H131.257L130.986 145.003C130.684 144.199 129.915 143.666 129.056 143.666H122.64ZM135.121 147.787C134.552 147.787 134.09 148.249 134.09 148.818C134.09 149.387 134.552 149.848 135.121 149.848H137.181C137.75 149.848 138.212 149.387 138.212 148.818C138.212 148.249 137.75 147.787 137.181 147.787H135.121ZM125.848 160.151C129.262 160.151 132.03 157.383 132.03 153.969C132.03 150.555 129.262 147.787 125.848 147.787C122.434 147.787 119.666 150.555 119.666 153.969C119.666 157.383 122.434 160.151 125.848 160.151ZM125.848 158.09C128.124 158.09 129.969 156.245 129.969 153.969C129.969 151.693 128.124 149.848 125.848 149.848C123.572 149.848 121.727 151.693 121.727 153.969C121.727 156.245 123.572 158.09 125.848 158.09Z"
          fill="black"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

import React, { Component } from 'react'
import {
  Container,
  WebcamCapture,
  VideoContainer,
  DeviceSelect,
  HiddenSourceWrap,
  HiddenSourceScale,
  LoadingWrapper,
} from './WebcamPhotoCapture.styles'
import { SelectInput } from '../SelectInput/SelectInput'
import { Icon } from '../Icon/Icon'
import * as mobileConsole from 'js-mobile-console'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

interface WebcamPhotoCaptureProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  fontFamily?: string
  containerPadding?: string
  containerBorder?: string
  viewerWidth?: string
  viewerHeight?: string
  videoContainerBorderRadius?: string
  dotsHorizontalColor?: string
  selectStyles?: {
    borderRadius: string
    borderWidth: string
    color?: string
    colorDisabled?: string
    backgroundColor?: string
    borderColorFocus?: string
    colorFocus?: string
    backgroundColorFocus?: string
    colorSelected?: string
    backgroundColorSelected?: string
    menuMarginTop?: string
    menuBorderWidth?: string
    indicatorColorFocus?: string
    indicatorColorHover?: string
    danger: string
    dangerLight: string
  }
  loadingSpinnerStyles?: {
    primary: string
    bgColor: string
  }
  showFrame?: boolean
  onPhotoCapture?: (photoDataUrl: string | Blob) => void
  outputFormat?: 'image/png' | 'image/jpeg' | 'image/webp'
  outputType?: 'blob' | 'base64'
  debug?: boolean
}

interface WebcamPhotoCaptureState {
  videoDevices: MediaDeviceInfo[]
  selectedDevice?: { value: string; label: string }
  error?: string
  photoDataUrl?: string
  width?: number
  height?: number
  isLoading?: boolean
}

export class WebcamPhotoCapture extends Component<
  WebcamPhotoCaptureProps,
  WebcamPhotoCaptureState
> {
  videoRef = React.createRef<HTMLVideoElement>()
  hiddenCanvasRef = React.createRef<HTMLCanvasElement>()

  constructor(props: WebcamPhotoCaptureProps) {
    super(props)
    this.state = {
      videoDevices: [],
    }
  }

  error = (error: string) => {
    this.setState({ error })
  }

  setStreamWithDimensions = async (deviceId: string) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: deviceId,
        width: { ideal: 20000 },
      },
    })

    // eslint-disable-next-line prefer-const
    let { width, height } = stream.getTracks()[0].getSettings()

    if (this.props.debug) {
      stream.getTracks().map((track) => {
        console.log('stream', track.getSettings(), track.label)
        console.log('aspect ratio', track.getSettings().aspectRatio)
      })

      console.log('dimensions', width, height)
      if (width > height) {
        console.log('landscape')
      } else {
        console.log('portrait')
      }
    }

    this.setState({ width, height })

    if (this.videoRef.current) {
      this.videoRef.current.srcObject = stream
    }

    this.setState({ isLoading: false })
  }

  preStreamInit = async (device: { label: string; value: string }) => {
    this.setState({ isLoading: true })

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: device.value,
      },
    })
    if (this.videoRef.current) {
      this.videoRef.current.srcObject = stream
    }

    this.setState({ selectedDevice: device })
  }

  initCamera = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const filteredDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    )
    this.setState({ videoDevices: filteredDevices })

    if (filteredDevices.length > 0) {
      const selectedDevice = {
        value: filteredDevices[0].deviceId,
        label: filteredDevices[0].label,
      }

      //failsafe stream setup for mobile, before real setup in componentDidUpdate after waitForMoreCameraDevices
      await this.preStreamInit(selectedDevice)

      await this.waitForMoreCameraDevices()
    } else {
      this.error('No webcam found on this device.')
    }
  }

  waitForMoreCameraDevices = async () => {
    await new Promise((resolve) => setTimeout(resolve, 400))
    const devices = await navigator.mediaDevices.enumerateDevices()
    const filteredDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    )
    this.setState({ videoDevices: filteredDevices })
  }

  async componentDidMount() {
    if (this.props.debug) {
      mobileConsole.show()
    }

    try {
      this.initCamera()
    } catch (error) {
      try {
        // Retry after 400ms
        await new Promise((resolve) => setTimeout(resolve, 400))

        this.initCamera()
      } catch (error) {
        this.error('Error accessing webcam: ' + error)
      }
    }
  }

  async componentDidUpdate(
    _: WebcamPhotoCaptureProps,
    prevState: WebcamPhotoCaptureState
  ) {
    if (
      prevState.videoDevices.length &&
      !prevState.videoDevices[0].deviceId &&
      this.state.videoDevices.length &&
      this.state.videoDevices[0].deviceId.length
    ) {
      await this.setStreamWithDimensions(this.state.videoDevices[0].deviceId)
    }

    if (
      this.state.selectedDevice !== prevState.selectedDevice &&
      this.state.selectedDevice?.value
    ) {
      try {
        await this.setStreamWithDimensions(this.state.selectedDevice.value)
      } catch (error) {
        this.setState({ error: 'Error while switching webcam.' })
      }
    }
  }

  downloadBlob = (blob: Blob, fileFormat: string) => {
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = 'image.' + fileFormat.split('/')[1]
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )
    document.body.removeChild(link)
  }

  takePhoto = async () => {
    if (this.videoRef.current && this.hiddenCanvasRef.current) {
      const video = this.videoRef.current
      const canvas = this.hiddenCanvasRef.current
      const context = canvas.getContext('2d')

      canvas.width = this.state.width
      canvas.height = this.state.height

      if (this.props.debug) {
        console.log('canvas', canvas.width, canvas.height)
      }

      context.drawImage(video, 0, 0, canvas.width, canvas.height)

      const fileFormat = this.props.outputFormat || 'image/png'

      // base64 for <img /> preview & base64 mode export
      const dataUrl = canvas.toDataURL(fileFormat)
      this.setState({ photoDataUrl: dataUrl })

      if (this.props.outputType === 'blob') {
        canvas.toBlob((blob) => {
          if (blob) {
            this.props.onPhotoCapture(blob)

            if (this.props.debug) {
              this.downloadBlob(blob, fileFormat)
            }
          } else {
            this.setState({ error: 'Failed to create blob from taken photo' })
          }
        }, fileFormat)
      } else {
        this.props.onPhotoCapture(dataUrl)
      }
    }
  }

  reset = () => {
    this.setState({ photoDataUrl: undefined })
  }

  render() {
    const {
      containerPadding,
      containerBorder,
      viewerWidth,
      viewerHeight,
      dotsHorizontalColor,
      selectStyles,
      loadingSpinnerStyles,
      showFrame,
      videoContainerBorderRadius,
      fontFamily,
    } = this.props

    const { error, selectedDevice, photoDataUrl, videoDevices, isLoading } =
      this.state

    const videoDevicesSelectOptions = videoDevices.map((device) => ({
      value: device.deviceId,
      label: device.label,
    }))
    const videoDevicesSelectedIndex = videoDevicesSelectOptions.findIndex(
      (item) => item.value === selectedDevice?.value
    )

    return (
      <Container
        border={containerBorder}
        padding={containerPadding}
        height={viewerHeight}
        width={viewerWidth}
        fontFamily={fontFamily}
      >
        <WebcamCapture>
          <VideoContainer borderRadius={videoContainerBorderRadius}>
            {showFrame && !isLoading ? <div className="frame" /> : <></>}
            {error && <p className="error-message">{error}</p>}
            {!error && (
              <video
                autoPlay
                loop
                muted
                // eslint-disable-next-line react/no-unknown-property
                webkit-playsinline="true"
                playsInline
                className="video"
                ref={this.videoRef}
              />
            )}
            {photoDataUrl && <img src={photoDataUrl} alt="Captured" />}
            {!error && videoDevices.length > 1 && !photoDataUrl && (
              <DeviceSelect>
                <Icon
                  type="dots-horizontal"
                  size="32px"
                  color={dotsHorizontalColor}
                />
                <SelectInput
                  id="deviceSelect"
                  options={videoDevicesSelectOptions}
                  value={videoDevicesSelectOptions[videoDevicesSelectedIndex]}
                  onChange={(value: { label: string; value: string }) => {
                    this.preStreamInit(value)
                  }}
                  {...selectStyles}
                />
              </DeviceSelect>
            )}
          </VideoContainer>

          <HiddenSourceWrap>
            <HiddenSourceScale>
              <canvas ref={this.hiddenCanvasRef} />
            </HiddenSourceScale>
          </HiddenSourceWrap>

          {isLoading && (
            <LoadingWrapper>
              <LoadingSpinner {...loadingSpinnerStyles} height="100%" />
            </LoadingWrapper>
          )}
        </WebcamCapture>
      </Container>
    )
  }
}

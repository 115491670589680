import React, { useContext } from 'react'
import './stepper.styles'
import { StoreContext } from '../../components/App'

export const Stepper = () => {
  const store = useContext(StoreContext)
  const { jitPayWelcomePage: trans } = store.TranslationsState.translations
  const { context } = store.ScenarioState

  return (
    <div className="wrapper option-1 option-1-1">
      <ol className="c-stepper">
        <li className="c-stepper__item">
          <h3 className="c-stepper__title">{trans.stepOne}</h3>
        </li>
        <li className="c-stepper__item">
          <h3 className="c-stepper__title">{trans.stepTwo}</h3>
        </li>
        {context?.configurationId !== 'jitpay.qes' && (
          <li className="c-stepper__item">
            <h3 className="c-stepper__title">{trans.stepThree}</h3>
            <p className="c-stepper__desc">{trans.stepThreeDesc}</p>
          </li>
        )}
      </ol>
    </div>
  )
}

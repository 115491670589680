import { rootStore } from '../store/Root.store'

export const forceRedirect = (redirectUrl: string) => {
  rootStore.TrackingState.setShouldKeepAlive(false)
  rootStore.InterfaceState.setSendBeforeunloadEvent(false)
  window.location.href = redirectUrl
}

export const forcePathname = (redirectPathname: string) => {
  rootStore.InterfaceState.setSendBeforeunloadEvent(false)
  window.location.pathname = redirectPathname
}

export const forceReload = () => {
  rootStore.InterfaceState.setSendBeforeunloadEvent(false)
  window.location.reload()
}

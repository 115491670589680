import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const IntroIcon = () => (
  <HorizontalIconContainer>
    <svg
      width="184"
      height="128"
      viewBox="0 0 184 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="181"
        height="113"
        rx="8.5"
        fill="white"
        stroke="#DCDBE2"
        strokeWidth="3"
      />
      <path
        d="M9 127H175C179.08 127 182.446 123.946 182.938 120H1.06189C1.55399 123.946 4.92038 127 9 127Z"
        fill="#DCDBE2"
        stroke="#DCDBE2"
        strokeWidth="2"
      />
      <path
        d="M112.375 114L9.99998 114C6.134 114 3 110.866 3 107V107C3 107 3.5 109 5 109C7 109 9.5 109 9.5 109L112.375 109L112.375 114Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 3L174 3C177.866 3 181 6.13401 181 10V10C181 10 180.502 7.99999 179.007 7.99999C177.014 7.99999 174.522 7.99999 174.522 7.99999L72 8V3Z"
        fill="#DCDBE2"
      />
      <path
        d="M112 3L10 3C6.13401 3 3 6.13401 3 10V10C3 10 3.49829 7.99999 4.99314 7.99999C6.98629 7.99999 9.47772 7.99999 9.47772 7.99999L112 8V3Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 114L174 114C177.866 114 181 110.866 181 107V107C181 107 180.502 109 179.007 109C177.014 109 174.522 109 174.522 109L72 109L72 114Z"
        fill="#DCDBE2"
      />
      <circle cx="92" cy="4" r="1.5" fill="white" stroke="black" />
      <path d="M3 8H181V15H3V8Z" fill="#F6F5FA" />
      <path
        d="M53 12C53 10.8954 53.8954 10 55 10H76C77.1046 10 78 10.8954 78 12V15H53V12Z"
        fill="white"
      />
      <rect x="7" y="12" width="17" height="2" rx="1" fill="white" />
      <rect x="32" y="12" width="18" height="2" rx="1" fill="white" />
      <path
        d="M28 11V14"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <rect
        x="56.6592"
        y="11.75"
        width="17.3412"
        height="2.25"
        rx="1.125"
        fill="#F6F5FA"
      />
      <rect
        x="43"
        y="45"
        width="98"
        height="35"
        rx="1"
        stroke="#CED4DC"
        strokeWidth="2"
      />
      <path d="M52 53H56L66 72H62L52 53Z" fill="black" />
      <path d="M66 53H62L52 72H56L66 53Z" fill="black" />
      <path d="M74 53H78L88 72H84L74 53Z" fill="black" />
      <path d="M88 53H84L74 72H78L88 53Z" fill="black" />
      <path d="M96 53H100L110 72H106L96 53Z" fill="black" />
      <path d="M110 53H106L96 72H100L110 53Z" fill="black" />
      <path d="M118 53H122L132 72H128L118 53Z" fill="black" />
      <path d="M132 53H128L118 72H122L132 53Z" fill="black" />
    </svg>
  </HorizontalIconContainer>
)

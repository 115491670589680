import { ContentContainer, Header } from '../components/Generic'
import { IntroParagraph } from '../styles/generic.styles'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'
import {
  MarginOnly,
  MobileSpacerForContentContainerBottomFixed,
} from '../styles/layout.styles'
import { DesktopIcon } from '../components/SwitchToMobile/icons/DesktopIcon'

export const ContinueOnComputerPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { SwitchToMobile: trans } = store.TranslationsState.translations

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.continueOnComputerPage.header}
      </Header>

      <IntroParagraph>
        {trans.continueOnComputerPage.description}
      </IntroParagraph>

      <MarginOnly margin="20px 0" />

      <DesktopIcon />

      <MobileSpacerForContentContainerBottomFixed />
    </ContentContainer>
  )
})

import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const DefaultErrorIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="105"
        viewBox="0 0 104 105"
        fill="none"
      >
        <circle
          cx="52"
          cy="52.2832"
          r="50.25"
          fill="white"
          stroke="#EC0000"
          strokeWidth="3.5"
        />
        <path
          d="M28.5265 38.1665C28.5265 33.8127 32.2418 30.2832 36.825 30.2832C41.4082 30.2832 45.1235 33.8127 45.1235 38.1665V46.3931C44.5387 46.6441 44.0208 47.0225 43.6044 47.4936C40.6173 47.6733 38.25 50.1668 38.25 53.2165V57.4008C37.787 57.4769 37.3109 57.5165 36.825 57.5165C32.2418 57.5165 28.5265 53.987 28.5265 49.6332V38.1665Z"
          fill="black"
        />
        <path
          d="M38.25 70.4165V58.8522C37.7848 58.9166 37.309 58.9499 36.825 58.9499C31.4085 58.9499 27.0176 54.7787 27.0176 49.6332V47.4832C27.0176 46.6916 26.3421 46.0499 25.5088 46.0499C24.6755 46.0499 24 46.6916 24 47.4832V49.6332C24 55.877 28.9442 61.0237 35.3162 61.7331V70.4165H32.6757C31.8424 70.4165 31.1669 71.0583 31.1669 71.8499C31.1669 72.6415 31.8424 73.2832 32.6757 73.2832H39.0126C38.5276 72.4399 38.25 71.4608 38.25 70.4165Z"
          fill="black"
        />
        <path
          d="M61.05 67.5499C64.198 67.5499 66.75 64.983 66.75 61.8165C66.75 58.6501 64.198 56.0832 61.05 56.0832C57.902 56.0832 55.35 58.6501 55.35 61.8165C55.35 64.983 57.902 67.5499 61.05 67.5499Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.6126 47.4832C55.4245 47.4832 54.3611 48.2244 53.944 49.3433L53.5687 50.3499H48.225C48.225 49.5583 47.587 48.9165 46.8 48.9165C46.013 48.9165 45.375 49.5583 45.375 50.3499H43.95C42.376 50.3499 41.1 51.6333 41.1 53.2165V70.4165C41.1 71.9997 42.376 73.2832 43.95 73.2832H78.15C79.724 73.2832 81 71.9997 81 70.4165V53.2165C81 51.6333 79.724 50.3499 78.15 50.3499H68.5312L68.156 49.3433C67.7388 48.2244 66.6755 47.4832 65.4874 47.4832H56.6126ZM73.875 53.2165C73.088 53.2165 72.45 53.8583 72.45 54.6499C72.45 55.4415 73.088 56.0832 73.875 56.0832H76.725C77.512 56.0832 78.15 55.4415 78.15 54.6499C78.15 53.8583 77.512 53.2165 76.725 53.2165H73.875ZM61.05 70.4165C65.772 70.4165 69.6 66.5662 69.6 61.8165C69.6 57.0669 65.772 53.2165 61.05 53.2165C56.328 53.2165 52.5 57.0669 52.5 61.8165C52.5 66.5662 56.328 70.4165 61.05 70.4165Z"
          fill="black"
        />
        <rect
          x="85.6462"
          y="15.6367"
          width="3.54848"
          height="100.523"
          rx="1.77424"
          transform="rotate(45 85.6462 15.6367)"
          fill="#EC0000"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

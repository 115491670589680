import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import { scenarios } from '../../constants/scenarios'

import { SignicatBranding } from '../SignicatBranding/SignicatBranding'
import { TopBar } from '../TopBar'

import { StoreContext } from '../App'
import { rootStore } from '../../store/Root.store'
import { ContentWrap, ContentRouterWrap } from '../../styles/layout.styles'
import { devLog } from '../../methods/devLog'
import { PAGES, PAGES_WITHOUT_KEEP_ALIVE } from './pages'

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { currentScenarioId, currentPageIndex } = store.ScenarioState
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState

  useEffect(() => {
    devLog(currentScenarioId)
  }, [currentScenarioId])

  const currentStep = scenarios?.[currentScenarioId]?.[currentPageIndex]
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [pageWidth])

  const renderScenarioPage = () => {
    const path = window.location.pathname.split('/')[1]
    const errorPaths = ['error', 'error-session-expired', 'error-already-used']

    if (errorPaths.some((v) => path === v)) {
      return <PAGES.Error />
    }

    const Handler =
      PAGES[scenarios[currentScenarioId][currentPageIndex]] || PAGES.NotFound

    if (PAGES_WITHOUT_KEEP_ALIVE.includes(currentStep)) {
      store.TrackingState.setShouldKeepAlive(false)
    } else {
      store.TrackingState.setShouldKeepAlive(true)
    }

    devLog(
      'Currently rendered page: ',
      scenarios[currentScenarioId][currentPageIndex]
    )

    return <Handler />
  }

  const scenariosWithNoDefaultBrandingPlacements = ['error']

  const stepsWithNoDefaultBrandingPlacements = [
    'MicropaymentKlarnaJitpay',
    'MicropaymentKlarna',
    'MicropaymentKlarnaJitpayStandalone',
  ]

  const stepsWith1000pxBranding = [
    'VideoIdJitpay',
    'VideoId',
    'VideoIdIkano',
    'SignDocument',
    'SignDocumentIkano',
  ]

  const getBranding = () => {
    if (
      scenariosWithNoDefaultBrandingPlacements.includes(currentScenarioId) ||
      stepsWithNoDefaultBrandingPlacements.includes(currentStep)
    ) {
      return <></>
    }

    if (stepsWith1000pxBranding.includes(currentStep)) {
      // branding hidden for all steps until we find a solution
      return <SignicatBranding width="1000px" hideOnMobile />
    }

    // branding hidden for all steps until we find a solution
    return <SignicatBranding width="560px" hideOnMobile />
  }

  return (
    <ContentRouterWrap
      className="content-router"
      showBackgroundImage={!isMobile}
      {...theme.contentWrap}
    >
      <TopBar />
      <ContentWrap
        color={theme.globals.linkColor}
        fontFamily={theme.globals.fontFamily}
        fontFamilyHeadline={theme.globals.fontFamilyHeadline}
        iframeContent={rootStore.AppState.iframeContent}
        bgColor={theme.globals.bgColor}
      >
        {renderScenarioPage()}
        {getBranding()}
      </ContentWrap>
    </ContentRouterWrap>
  )
})

import styled from 'styled-components'

export const PopupWrapper = styled.div`
  & > div > div > div > div:first-child > svg {
    display: none;
  }

  & > div > div {
    width: 500px;
  }
`

export const LoadingWrapper = styled.div`
  height: 460px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepList = styled.ol`
  padding: 0 0 0 20px;
  margin: 10px 0 0 0;

  & > li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ListWrapper = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
`

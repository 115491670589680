import React from 'react'

interface UserProps {
  color: string
}

export const User = ({ color }: UserProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="account_circle_black_24dp 1" clipPath="url(#clip0_407_512)">
        <path
          id="Vector"
          d="M16.0003 2.66602C8.64033 2.66602 2.66699 8.63935 2.66699 15.9993C2.66699 23.3594 8.64033 29.3327 16.0003 29.3327C23.3603 29.3327 29.3337 23.3594 29.3337 15.9993C29.3337 8.63935 23.3603 2.66602 16.0003 2.66602ZM9.42699 24.3727C10.0003 23.1727 13.4937 21.9993 16.0003 21.9993C18.507 21.9993 22.0137 23.1727 22.5737 24.3727C20.7603 25.8127 18.4803 26.666 16.0003 26.666C13.5203 26.666 11.2403 25.8127 9.42699 24.3727ZM24.4803 22.4394C22.5737 20.1193 17.947 19.3327 16.0003 19.3327C14.0537 19.3327 9.42699 20.1193 7.52033 22.4394C6.16033 20.6527 5.33366 18.426 5.33366 15.9993C5.33366 10.1193 10.1203 5.33268 16.0003 5.33268C21.8803 5.33268 26.667 10.1193 26.667 15.9993C26.667 18.426 25.8403 20.6527 24.4803 22.4394ZM16.0003 7.99935C13.4137 7.99935 11.3337 10.0793 11.3337 12.666C11.3337 15.2527 13.4137 17.3327 16.0003 17.3327C18.587 17.3327 20.667 15.2527 20.667 12.666C20.667 10.0793 18.587 7.99935 16.0003 7.99935ZM16.0003 14.666C14.8937 14.666 14.0003 13.7727 14.0003 12.666C14.0003 11.5593 14.8937 10.666 16.0003 10.666C17.107 10.666 18.0003 11.5593 18.0003 12.666C18.0003 13.7727 17.107 14.666 16.0003 14.666Z"
          fill={color ? color : '#280b60'}
        />
      </g>
      <defs>
        <clipPath id="clip0_407_512">
          <rect width="32" height="32" fill={color ? color : '#280b60'} />
        </clipPath>
      </defs>
    </svg>
  )
}

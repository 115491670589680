import React from 'react'

export const SocietyStatement = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M33.2 18.5C33.2 22.5514 30.4307 25.956 26.6817 26.9242C26.8272 27.5012 26.925 28.0972 26.9706 28.7077C31.5774 27.5967 35 23.448 35 18.5C35 12.701 30.299 8 24.5 8C18.701 8 14 12.701 14 18.5C14 18.7665 14.0099 19.0308 14.0294 19.2923C14.6076 19.1529 15.2044 19.0613 15.8154 19.022C15.8052 18.8493 15.8 18.6752 15.8 18.5C15.8 13.6951 19.6951 9.8 24.5 9.8C29.3049 9.8 33.2 13.6951 33.2 18.5Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 38.2C21.3049 38.2 25.2 34.3049 25.2 29.5C25.2 24.6951 21.3049 20.8 16.5 20.8C11.6951 20.8 7.8 24.6951 7.8 29.5C7.8 34.3049 11.6951 38.2 16.5 38.2ZM16.5 40C22.299 40 27 35.299 27 29.5C27 23.701 22.299 19 16.5 19C10.701 19 6 23.701 6 29.5C6 35.299 10.701 40 16.5 40Z"
        className="document--primary--fill"
      />
      <path
        d="M15.7318 25.2662C14.4975 25.5608 13.5144 26.3749 12.9589 27.431H17.1457C17.6428 27.431 18.0457 27.8339 18.0457 28.331C18.0457 28.828 17.6428 29.231 17.1457 29.231H12.4643C12.4482 29.5391 12.4646 29.8533 12.5162 30.1693H15.6245C16.1215 30.1693 16.5245 30.5723 16.5245 31.0693C16.5245 31.5664 16.1215 31.9693 15.6245 31.9693H13.2381C14.2233 33.3969 15.9974 34.1423 17.7502 33.724C18.5579 33.5312 19.2545 33.1186 19.7957 32.5623C20.1424 32.2061 20.7122 32.1983 21.0684 32.5449C21.4247 32.8915 21.4325 33.4613 21.0858 33.8176C20.3146 34.6103 19.3185 35.2003 18.168 35.4748C15.2612 36.1685 12.3534 34.6253 11.1863 31.9693H10.3C9.80294 31.9693 9.4 31.5664 9.4 31.0693C9.4 30.5723 9.80294 30.1693 10.3 30.1693H10.6993C10.663 29.8543 10.6512 29.5407 10.6627 29.231H10.3C9.80294 29.231 9.4 28.828 9.4 28.331C9.4 27.8339 9.80294 27.431 10.3 27.431H11.0022C11.6621 25.5387 13.2283 24.0131 15.314 23.5154C16.8216 23.1556 18.3343 23.3991 19.6004 24.0876C20.037 24.3251 20.1985 24.8716 19.961 25.3083C19.7236 25.7449 19.1771 25.9064 18.7404 25.6689C17.847 25.1831 16.7879 25.0142 15.7318 25.2662Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 11.1C24.9971 11.1 25.4 11.5029 25.4 12V13.1755C26.824 13.417 28.0694 14.2254 28.6191 15.4251C28.8261 15.877 28.6276 16.4111 28.1757 16.6182C27.7238 16.8252 27.1897 16.6267 26.9826 16.1749C26.7611 15.6912 26.1966 15.2215 25.4 15.0127V17.6926C26.0262 17.7693 26.6773 17.8792 27.2238 18.0854C27.6323 18.2394 28.0565 18.4724 28.3811 18.8491C28.7227 19.2457 28.9 19.7393 28.9 20.3L28.9 20.3044C28.8975 22.1926 27.2603 23.4988 25.4 23.822V23.926C24.8983 23.1267 24.2919 22.3997 23.6 21.7643V19.3073C22.9738 19.2307 22.3227 19.1207 21.7762 18.9146C21.3677 18.7605 20.9435 18.5276 20.6189 18.1508C20.2773 17.7543 20.1 17.2606 20.1 16.7C20.1 14.8094 21.7383 13.5014 23.6 13.178V12C23.6 11.5029 24.0029 11.1 24.5 11.1ZM21.9 16.7C21.9 16.0652 22.5009 15.2899 23.6 15.0085V17.4919C23.1297 17.4282 22.7298 17.3505 22.4113 17.2304C22.1636 17.1369 22.0409 17.0436 21.9827 16.976C21.9414 16.9282 21.9 16.8593 21.9 16.7ZM25.4 21.9915V19.5081C25.8703 19.5718 26.2702 19.6495 26.5887 19.7696C26.8365 19.863 26.9591 19.9564 27.0174 20.024C27.0583 20.0715 27.0994 20.1397 27.1 20.2967L27.1 20.3C27.1 20.9348 26.4991 21.7101 25.4 21.9915Z"
        className="document--primary--fill"
      />
      <path
        d="M28.7236 28.116C28.8627 28.3262 28.9991 28.5335 29.1391 28.7603C29.2134 28.8809 29.2804 28.9941 29.3397 29.1H28C27.5029 29.1 27.1 29.5029 27.1 30C27.1 30.497 27.5029 30.9 28 30.9H29.5204C29.4687 31.0154 29.4048 31.1352 29.328 31.2604C28.9727 31.8394 28.3786 32.4671 27.5659 33.1841C27.5005 33.2354 27.4366 33.2915 27.3747 33.3527C27.2042 33.517 27.1119 33.7345 27.1011 33.9563C27.0892 34.201 27.1767 34.4496 27.3636 34.6365C27.3697 34.6425 27.3758 34.6485 27.382 34.6544C27.4126 34.6833 27.4448 34.7097 27.4783 34.7336C27.8163 34.9746 28.2834 34.9539 28.5992 34.6716C28.6319 34.6433 28.6644 34.6149 28.6969 34.5865C28.8142 34.5063 29.0262 34.4432 29.4466 34.4755C29.9535 34.5145 30.5523 34.6744 31.2632 34.8683L31.3092 34.8809C31.9629 35.0592 32.7199 35.2658 33.4153 35.3193C34.1288 35.3741 34.9871 35.2857 35.6364 34.6365C35.9879 34.285 35.9879 33.7151 35.6364 33.3637C35.2849 33.0122 34.7151 33.0122 34.3636 33.3637C34.2629 33.4644 34.0587 33.5634 33.5534 33.5246C33.0465 33.4856 32.4477 33.3256 31.7368 33.1317L31.6909 33.1192C31.299 33.0123 30.8701 32.8953 30.4395 32.8071C30.5952 32.6075 30.737 32.4058 30.8622 32.2017C31.1149 31.7899 31.3077 31.3551 31.4108 30.9H33C33.4971 30.9 33.9 30.497 33.9 30C33.9 29.5029 33.4971 29.1 33 29.1H31.3174C31.1659 28.6561 30.9122 28.2062 30.6709 27.8151C30.5602 27.6357 30.4336 27.4404 30.3078 27.2489C29.8082 27.5812 29.2784 27.8719 28.7236 28.116Z"
        className="document--primary--fill"
      />
      <path
        d="M31.902 25.9471C31.9819 25.9496 32.0436 25.9635 32.0891 25.9822C32.5424 26.1685 32.7562 26.3921 32.877 26.6022C33.0093 26.8321 33.0798 27.1328 33.1012 27.5466C33.1269 28.0429 33.5502 28.4245 34.0466 28.3988C34.543 28.373 34.9245 27.9498 34.8988 27.4534C34.8704 26.9056 34.7702 26.2834 34.4374 25.7047C34.1486 25.2026 33.7181 24.7905 33.1299 24.4829C32.7653 25.0078 32.354 25.4979 31.902 25.9471Z"
        className="document--primary--fill"
      />
      <path
        d="M34.603 21.3697C37.8756 22.6194 40.2 25.7883 40.2 29.5C40.2 34.3049 36.3049 38.2 31.5 38.2C28.9965 38.2 26.7399 37.1425 25.1527 35.4499C24.8088 35.949 24.4229 36.4169 24 36.8485C25.9058 38.7933 28.562 40 31.5 40C37.299 40 42 35.299 42 29.5C42 24.9078 39.052 21.0042 34.9453 19.5783C34.9448 19.5835 34.9442 19.5887 34.9437 19.5939C34.8851 20.1604 34.7814 20.7135 34.6363 21.2496C34.6255 21.2897 34.6144 21.3298 34.603 21.3697Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

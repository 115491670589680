import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { IntroParagraph } from '../../styles/generic.styles'
import { Divider } from '../../styles/jitpay.styles'

import { TermsAndConditions } from '../../components/TermsAndConditions/TermsAndConditions'

export const TermsAndConditionsWithMicropaymentIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { ikanoWelcomePage: trans } = store.TranslationsState.translations

  return (
    <TermsAndConditions
      header={trans.heading}
      showTitle={false}
      additionalParagraph={
        <>
          <IntroParagraph
            color={theme.globals.linkColor}
            colorHover={theme.globals.linkColorHover}
          >
            {trans.descriptionPartOne}
            <a
              href="https://eidas.ec.europa.eu/efda/tl-browser/#/screen/tl/ES/42"
              target="_blank"
              rel="noreferrer"
            >
              {trans.serviceProvider}
            </a>
          </IntroParagraph>

          <Divider margin="30px 0" />
        </>
      }
    />
  )
})

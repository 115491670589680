import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  OtpInput,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import {
  ContentContainerBottom,
  ContentContainerBottomLeft,
} from '../styles/layout.styles'

export const OtpPage = observer(() => {
  const store = useContext(StoreContext)
  const { currentPageIndex, currentScenarioId } = store.ScenarioState
  const { theme } = store.InterfaceState
  const { otpPage: trans } = store.TranslationsState.translations

  const [otpValue, setOtpValue] = useState('')
  const [pageStatus, setPageStatus] = useState('code')
  const [delay, setDelay] = useState<number>(8000)
  const [verified, setVerified] = useState(false)
  const [testsCounter, setTestsCounter] = useState(10)

  const handlePooling = async () => {
    try {
      if (currentScenarioId !== 'localTests') {
        const apiUrl = process.env.WEB_API_URL
          ? process.env.WEB_API_URL
          : 'https://dev-02-web-api.attempt.signicat.io'

        const res = await axiosInstance.post(
          `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/proceed-signature`,
          {},
          {
            withCredentials: true,
          }
        )

        if (res.data && res.data.status) {
          const { status } = res.data
          if (status === 'success') {
            setVerified(true)
            currentScenarioId !== 'localTests' && handleSendCode()
            setPageStatus('code')
          }
        }
      } else {
        if (testsCounter !== 0) {
          setTestsCounter(testsCounter - 1)
        } else {
          setVerified(true)
          setPageStatus('code')
        }
      }
    } catch (e) {
      devLog(e)
    }
  }

  const handleProceed = async () => {
    try {
      if (pageStatus === 'code') {
        if (currentScenarioId !== 'localTests') {
          const apiUrl = process.env.WEB_API_URL
            ? process.env.WEB_API_URL
            : 'https://dev-02-web-api.attempt.signicat.io'

          await axiosInstance.post(
            `${apiUrl}/electronic-id/sign`,
            {
              challenge_code: otpValue,
            },
            {
              withCredentials: true,
            }
          )
        }

        store.ScenarioState.setCurrentPage(currentPageIndex + 1)
      } else if (pageStatus === 'verifying') {
        setPageStatus('code')
        currentScenarioId !== 'localTests' && handleSendCode()
      }
    } catch (e) {
      devLog(e)
    }
  }

  const handleSendCode = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      await axiosInstance.post(
        `${apiUrl}/electronic-id/request-signature`,
        {},
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }

  useInterval(
    () => {
      pageStatus === 'verifying' && handlePooling()
    },
    !verified ? delay : null
  )

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      {pageStatus === 'code' && (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>
          <OtpInput
            {...theme.otpInput}
            value={otpValue}
            valueLength={6}
            onChange={(value) => setOtpValue(value)}
            marginTop="40px"
            marginBottom="40px"
            inputHeight="68px"
          />
          <ContentContainerBottom>
            <ContentContainerBottomLeft />
            <Button
              onClick={() => handleProceed()}
              {...theme.button}
              width="164px"
              paddingMobile="14px 40px"
            >
              {trans.verify}
            </Button>
          </ContentContainerBottom>
        </>
      )}
      {pageStatus === 'verifying' && (
        <>
          <h2>{trans.headerVerifying}</h2>
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            {...theme.loadingSpinner}
          />
          <ContentContainerBottom>
            <ContentContainerBottomLeft />
            <Button
              disabled={true}
              {...theme.button}
              width="164px"
              paddingMobile="14px 40px"
            >
              {trans.verify}
            </Button>
          </ContentContainerBottom>
        </>
      )}
    </ContentContainer>
  )
})

import React from 'react'
import { JitpayInfoContainer } from '../../styles/jitpay.styles'

export const JitpayInfoGraph = () => {
  return (
    <JitpayInfoContainer>
      <svg
        width="216"
        height="73"
        viewBox="0 0 216 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_34_1293)">
          <path
            d="M178.496 6.69723V14.5142C174.933 8.91868 168.61 5.19531 161.391 5.19531C150.26 5.19531 141.234 14.0671 141.234 24.9997C141.234 35.9323 150.26 44.8041 161.398 44.8041C168.61 44.8041 174.94 41.0807 178.503 35.4852V43.3022H215.772V6.69723H178.496Z"
            fill="#2A0062"
          />
          <path
            d="M154.435 29.1494C156.882 29.1494 158.511 27.7313 158.511 25.8173C158.511 23.994 157.266 23.016 154.933 22.5619C153.546 22.3035 152.152 21.8494 152.152 20.7387C152.152 20.0122 152.835 19.3904 154.066 19.3904C155.296 19.3904 156.384 19.9563 156.384 20.8295C156.384 21.046 156.327 21.1927 156.27 21.3744L157.935 21.9402C158.12 21.6119 158.255 21.2137 158.255 20.7526C158.255 18.9503 156.569 17.7139 154.236 17.7139C151.918 17.7139 150.403 19.132 150.403 20.7317C150.403 22.7715 151.939 23.7355 154.329 24.1197C156.107 24.4341 156.662 25.0279 156.662 25.8662C156.662 26.7743 155.829 27.4519 154.457 27.4519C153.184 27.4519 152.01 26.907 152.01 25.7963C152.01 25.6147 152.046 25.4121 152.103 25.1955L150.438 24.5249C150.268 24.8393 150.118 25.2863 150.118 25.7474C150.132 27.8012 151.768 29.1494 154.435 29.1494Z"
            fill="white"
          />
          <path
            d="M159.834 19.2858C159.834 19.8865 160.296 20.3406 160.908 20.3406C161.52 20.3406 162.003 19.8865 162.003 19.2858C162.003 18.685 161.527 18.21 160.908 18.21C160.303 18.21 159.834 18.685 159.834 19.2858Z"
            fill="white"
          />
          <path
            d="M161.761 21.4648H160.075V28.9814H161.761V21.4648Z"
            fill="white"
          />
          <path
            d="M167.01 27.5499C165.766 27.5499 164.805 26.6976 164.805 25.2586C164.805 23.6728 165.773 22.7298 167.01 22.7298C168.269 22.7298 169.215 23.7497 169.215 25.1468C169.208 26.5509 168.248 27.5499 167.01 27.5499ZM169.137 21.4514V22.4713C168.66 21.7588 167.928 21.3047 166.712 21.3047C164.692 21.3047 163.077 22.8695 163.077 25.2516C163.077 27.5429 164.613 28.9819 166.541 28.9819C167.835 28.9819 168.724 28.4371 169.116 27.6337V28.4371C169.116 29.8552 168.561 30.8541 167.096 30.8541C166.036 30.8541 165.239 30.3302 164.997 29.3103L163.461 29.946C164.016 31.6016 165.318 32.2932 167.096 32.2932C169.521 32.2932 170.801 30.6585 170.801 28.3602V21.4444H169.137V21.4514Z"
            fill="white"
          />
          <path
            d="M174.25 24.7836C174.25 23.4563 175.068 22.7228 176.064 22.7228C177.01 22.7228 177.636 23.3934 177.636 24.6369V28.9889H179.321V24.071C179.321 22.3036 178.19 21.3047 176.633 21.3047C175.687 21.3047 174.798 21.654 174.222 22.5551V21.4584H172.536V28.975H174.222V24.7696L174.25 24.7836Z"
            fill="white"
          />
          <path
            d="M185.687 25.1679C185.687 23.5123 186.897 22.7299 187.949 22.7299C189.08 22.7299 189.898 23.4215 190.047 24.5322L191.584 24.0642C191.264 22.3178 189.806 21.2979 187.949 21.2979C185.851 21.2979 184.08 22.8626 184.08 25.1749C184.08 27.557 185.695 29.1428 188.191 29.1428C190.047 29.1428 191.584 28.1019 191.989 26.125L190.453 25.5801C190.282 26.6908 189.635 27.7247 188.12 27.7247C186.911 27.7177 185.687 26.7467 185.687 25.1679Z"
            fill="white"
          />
          <path
            d="M196.712 27.6541C195.502 27.6541 194.436 26.725 194.436 25.2511C194.436 23.7771 195.495 22.7921 196.712 22.7921C197.842 22.7921 199.009 23.7212 199.009 25.2511C198.988 26.7809 197.828 27.6541 196.712 27.6541ZM198.938 21.4439V22.6664C198.497 21.8141 197.586 21.2832 196.363 21.2832C194.236 21.2832 192.75 22.9388 192.75 25.2511C192.75 27.4725 194.272 29.1281 196.363 29.1281C197.53 29.1281 198.419 28.6601 199.03 27.71V28.9814H200.645V21.4439H198.938Z"
            fill="white"
          />
          <path
            d="M205.979 29.1353C207.423 29.1353 208.404 28.2621 208.554 26.7532L206.939 26.4388C206.882 27.3121 206.527 27.7102 205.879 27.7102C205.403 27.7102 204.912 27.3959 204.912 26.6694V22.8831H207.821V21.4441H204.912V19.041H203.234V21.4441H201.697V22.8622H203.234V26.5855C203.219 28.0945 204.108 29.1353 205.979 29.1353Z"
            fill="white"
          />
          <path
            d="M180.822 19.2858C180.822 19.8865 181.285 20.3406 181.896 20.3406C182.508 20.3406 182.992 19.8865 182.992 19.2858C182.992 18.685 182.515 18.21 181.896 18.21C181.285 18.21 180.822 18.685 180.822 19.2858Z"
            fill="white"
          />
          <path
            d="M182.75 21.4648H181.064V28.9814H182.75V21.4648Z"
            fill="white"
          />
        </g>
        <path
          d="M81 24.5H110.5M110.5 24.5L104 18M110.5 24.5L104 31"
          stroke="#CCCED0"
          strokeLinecap="round"
        />
        <path
          d="M3.77148 70.2109C2.68945 70.2109 1.92969 69.832 1.49219 69.0742C1.05859 68.3164 0.841797 67.2363 0.841797 65.834C0.841797 64.4316 1.05859 63.3496 1.49219 62.5879C1.92969 61.8262 2.68945 61.4453 3.77148 61.4453C4.85352 61.4453 5.61328 61.8262 6.05078 62.5879C6.48438 63.3496 6.70117 64.4316 6.70117 65.834C6.70117 67.2363 6.48242 68.3164 6.04492 69.0742C5.61133 69.832 4.85352 70.2109 3.77148 70.2109ZM4.75 68.0723C4.90234 67.5566 4.97852 66.8105 4.97852 65.834C4.97852 64.8105 4.90039 64.0527 4.74414 63.5605C4.5918 63.0684 4.26758 62.8223 3.77148 62.8223C3.27539 62.8223 2.94727 63.0684 2.78711 63.5605C2.62695 64.0527 2.54688 64.8105 2.54688 65.834C2.54688 66.8105 2.62695 67.5586 2.78711 68.0781C2.94727 68.5938 3.27539 68.8516 3.77148 68.8516C4.26758 68.8516 4.59375 68.5918 4.75 68.0723Z"
          fill="#363745"
        />
        <path
          d="M9.53711 70.5977C9.4668 70.8867 9.33594 71.1367 9.14453 71.3477C8.9375 71.5742 8.69336 71.7441 8.41211 71.8574C8.13477 71.9746 7.94336 72.0332 7.83789 72.0332V71.3887C8.17383 71.2949 8.41992 71.1387 8.57617 70.9199C8.73633 70.7012 8.82812 70.3945 8.85156 70H7.83789V68.2539H9.64258V69.7656C9.64258 70.0312 9.60742 70.3086 9.53711 70.5977Z"
          fill="#363745"
        />
        <path
          d="M13.791 70.2109C12.709 70.2109 11.9492 69.832 11.5117 69.0742C11.0781 68.3164 10.8613 67.2363 10.8613 65.834C10.8613 64.4316 11.0781 63.3496 11.5117 62.5879C11.9492 61.8262 12.709 61.4453 13.791 61.4453C14.873 61.4453 15.6328 61.8262 16.0703 62.5879C16.5039 63.3496 16.7207 64.4316 16.7207 65.834C16.7207 67.2363 16.502 68.3164 16.0645 69.0742C15.6309 69.832 14.873 70.2109 13.791 70.2109ZM14.7695 68.0723C14.9219 67.5566 14.998 66.8105 14.998 65.834C14.998 64.8105 14.9199 64.0527 14.7637 63.5605C14.6113 63.0684 14.2871 62.8223 13.791 62.8223C13.2949 62.8223 12.9668 63.0684 12.8066 63.5605C12.6465 64.0527 12.5664 64.8105 12.5664 65.834C12.5664 66.8105 12.6465 67.5586 12.8066 68.0781C12.9668 68.5938 13.2949 68.8516 13.791 68.8516C14.2871 68.8516 14.6133 68.5918 14.7695 68.0723Z"
          fill="#363745"
        />
        <path
          d="M21.707 70H19.9961V64.1406H17.998V63.0039C18.5254 62.9805 18.8945 62.9453 19.1055 62.8984C19.4414 62.8242 19.7148 62.6758 19.9258 62.4531C20.0703 62.3008 20.1797 62.0977 20.2539 61.8438C20.2969 61.6914 20.3184 61.5781 20.3184 61.5039H21.707V70Z"
          fill="#363745"
        />
        <path
          d="M34.1113 66.2266H29.916V68.4473H34.6973V70H28.1523V61.3633H34.4863V62.8926H29.916V64.7266H34.1113V66.2266Z"
          fill="#363745"
        />
        <path
          d="M41.2129 66.6719V61.3633H43.0469V66.6719C43.0469 67.5898 42.9043 68.3047 42.6191 68.8164C42.0879 69.7539 41.0742 70.2227 39.5781 70.2227C38.082 70.2227 37.0664 69.7539 36.5312 68.8164C36.2461 68.3047 36.1035 67.5898 36.1035 66.6719V61.3633H37.9375V66.6719C37.9375 67.2656 38.0078 67.6992 38.1484 67.9727C38.3672 68.457 38.8438 68.6992 39.5781 68.6992C40.3086 68.6992 40.7832 68.457 41.002 67.9727C41.1426 67.6992 41.2129 67.2656 41.2129 66.6719Z"
          fill="#363745"
        />
        <path
          d="M48.4434 66.6133H46.5801V70H44.8164V61.3633H49.0527C49.6582 61.375 50.123 61.4492 50.4473 61.5859C50.7754 61.7227 51.0527 61.9238 51.2793 62.1895C51.4668 62.4082 51.6152 62.6504 51.7246 62.916C51.834 63.1816 51.8887 63.4844 51.8887 63.8242C51.8887 64.2344 51.7852 64.6387 51.5781 65.0371C51.3711 65.4316 51.0293 65.7109 50.5527 65.875C50.9512 66.0352 51.2324 66.2637 51.3965 66.5605C51.5645 66.8535 51.6484 67.3027 51.6484 67.9082V68.4883C51.6484 68.8828 51.6641 69.1504 51.6953 69.291C51.7422 69.5137 51.8516 69.6777 52.0234 69.7832V70H50.0371C49.9824 69.8086 49.9434 69.6543 49.9199 69.5371C49.873 69.2949 49.8477 69.0469 49.8438 68.793L49.832 67.9902C49.8242 67.4395 49.7227 67.0723 49.5273 66.8887C49.3359 66.7051 48.9746 66.6133 48.4434 66.6133ZM49.5391 65.043C49.8984 64.8789 50.0781 64.5547 50.0781 64.0703C50.0781 63.5469 49.9043 63.1953 49.5566 63.0156C49.3613 62.9141 49.0684 62.8633 48.6777 62.8633H46.5801V65.1836H48.625C49.0312 65.1836 49.3359 65.1367 49.5391 65.043Z"
          fill="#363745"
        />
        <circle cx="26" cy="24" r="24" fill="#EDEEEF" />
        <path
          d="M40 24C40 31.732 33.732 38 26 38C18.268 38 12 31.732 12 24C12 16.268 18.268 10 26 10C33.732 10 40 16.268 40 24Z"
          fill="white"
        />
        <path
          d="M18 20.7959C17.4477 20.7959 17 21.2436 17 21.7959C17 22.3482 17.4477 22.7959 18 22.7959H19.0392C19.0129 23.1299 19 23.4641 19 23.796C19 24.1279 19.0129 24.462 19.0391 24.7959H18C17.4477 24.7959 17 25.2436 17 25.7959C17 26.3482 17.4477 26.7959 18 26.7959H19.3627C19.7537 28.3551 20.4689 29.7998 21.5466 30.8663C22.4385 31.7488 23.573 32.3626 24.9334 32.5397C26.2851 32.7157 27.7876 32.4518 29.4166 31.7052C29.9187 31.4751 30.1392 30.8816 29.9091 30.3795C29.679 29.8774 29.0854 29.657 28.5833 29.8871C27.2124 30.5154 26.0899 30.6734 25.1916 30.5564C24.3019 30.4406 23.5615 30.0465 22.9534 29.4447C22.2844 28.7826 21.7711 27.86 21.4389 26.7959H25C25.5523 26.7959 26 26.3482 26 25.7959C26 25.2436 25.5523 24.7959 25 24.7959H21.046C21.0155 24.4672 21 24.1331 21 23.796C21 23.4589 21.0155 23.1246 21.046 22.7959H25C25.5523 22.7959 26 22.3482 26 21.7959C26 21.2436 25.5523 20.7959 25 20.7959H21.439C21.7712 19.7319 22.2845 18.8094 22.9534 18.1475C23.5615 17.5457 24.3019 17.1516 25.1916 17.0357C26.0899 16.9188 27.2124 17.0768 28.5833 17.7052C29.0854 17.9353 29.6789 17.7148 29.9091 17.2127C30.1392 16.7107 29.9187 16.1171 29.4167 15.887C27.7876 15.1404 26.2851 14.8765 24.9334 15.0525C23.5731 15.2296 22.4385 15.8433 21.5466 16.7259C20.469 17.7922 19.7538 19.2369 19.3628 20.7959H18Z"
          fill="#2C3E4F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 40C34.8366 40 42 32.8366 42 24C42 15.1634 34.8366 8 26 8C17.1634 8 10 15.1634 10 24C10 32.8366 17.1634 40 26 40ZM40 24C40 31.732 33.732 38 26 38C18.268 38 12 31.732 12 24C12 16.268 18.268 10 26 10C33.732 10 40 16.268 40 24Z"
          fill="#2C3E4F"
        />
        <defs>
          <clipPath id="clip0_34_1293">
            <rect
              width="75"
              height="40"
              fill="white"
              transform="translate(141 5)"
            />
          </clipPath>
        </defs>
      </svg>
    </JitpayInfoContainer>
  )
}

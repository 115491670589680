import styled from 'styled-components'

interface ContainerProps {
  bgColor?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-klarna-wrap-container',
})<ContainerProps>`
  margin-top: 40%;
  background-color: ${(props) => `${props.bgColor}`};

  @media (max-width: 600px) {
    padding-top: 40%;
    margin-top: 0;
    box-sizing: border-box;
  }
`

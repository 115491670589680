import React from 'react'

interface DotsHorizontalProps {
  color: string
  thickness?: string
}

export const DotsHorizontal = ({ color, thickness }: DotsHorizontalProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="12"
        fill={`${color ? color : '#280b60'}`}
        r={`${thickness ? thickness : '2'}`}
      />
      <circle
        cx="12"
        cy="12"
        fill={`${color ? color : '#280b60'}`}
        r={`${thickness ? thickness : '2'}`}
      />
      <circle
        cx="4"
        cy="12"
        fill={`${color ? color : '#280b60'}`}
        r={`${thickness ? thickness : '2'}`}
      />
    </svg>
  )
}

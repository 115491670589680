import React from 'react'

export const DrivingLicense = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M35 18C35.5523 18 36 18.4477 36 19C36 19.5523 35.5523 20 35 20H26C25.4477 20 25 19.5523 25 19C25 18.4477 25.4477 18 26 18H35Z"
        className="document--primary--fill"
      />
      <path
        d="M28 22C28.5523 22 29 22.4477 29 23C29 23.5523 28.5523 24 28 24H26C25.4477 24 25 23.5523 25 23C25 22.4477 25.4477 22 26 22H28Z"
        className="document--primary--fill"
      />
      <path
        d="M34 27C34 26.4477 33.5523 26 33 26H26C25.4477 26 25 26.4477 25 27C25 27.5523 25.4477 28 26 28H33C33.5523 28 34 27.5523 34 27Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5402 20C14.5686 20 13.7376 20.6982 13.5701 21.6552L13.1537 24.0347C12.7738 24.1376 12.4795 24.4594 12.4245 24.8658L12.1536 26.8658C12.0789 27.4172 12.4668 27.9128 13 27.9897V28C13 28.5523 13.4477 29 14 29H15C15.5523 29 16 28.5523 16 28H19C19 28.5523 19.4477 29 20 29H21C21.5523 29 22 28.5523 22 28V27.9913C22.5354 27.9206 22.9285 27.4281 22.8595 26.876L22.6095 24.876C22.5566 24.453 22.2446 24.1193 21.8447 24.0261L21.4298 21.6552C21.2624 20.6982 20.4313 20 19.4598 20H15.5402ZM19.8098 24L19.4598 22H15.5402L15.1902 24H19.8098ZM15.5 26C15.5 26.5523 15.0523 27 14.5 27C13.9477 27 13.5 26.5523 13.5 26C13.5 25.4477 13.9477 25 14.5 25C15.0523 25 15.5 25.4477 15.5 26ZM21.5 26C21.5 26.5523 21.0523 27 20.5 27C19.9477 27 19.5 26.5523 19.5 26C19.5 25.4477 19.9477 25 20.5 25C21.0523 25 21.5 25.4477 21.5 26Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16C7 14.3431 8.34315 13 10 13H38C39.6569 13 41 14.3431 41 16V34C41 35.6569 39.6569 37 38 37H10C8.34315 37 7 35.6569 7 34V16ZM10 15C9.44771 15 9 15.4477 9 16V34C9 34.5523 9.44772 35 10 35H38C38.5523 35 39 34.5523 39 34V16C39 15.4477 38.5523 15 38 15H10Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

import React from 'react'
import { IconContainer } from '../../styles/ikano.styles'

export const IkanoNamensabgleich = () => {
  return (
    <IconContainer>
      <svg
        width="218"
        height="93"
        viewBox="0 0 218 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M108.9 3.6832H61.4C60.2 1.6832 58 0.283203 55.5 0.283203C51.7 0.283203 48.7 3.3832 48.7 7.0832C48.7 10.8832 51.8 13.8832 55.5 13.8832C58 13.8832 60.2 12.4832 61.4 10.4832H108.9V3.6832Z"
          fill="url(#paint0_linear_3502_37741)"
        />
        <path
          d="M108.6 89.4828L156.1 89.4828C157.3 91.4828 159.5 92.8828 162 92.8828C165.8 92.8828 168.8 89.7828 168.8 86.0828C168.8 82.2828 165.7 79.2828 162 79.2828C159.5 79.2828 157.3 80.6828 156.1 82.6828L108.6 82.6828L108.6 89.4828Z"
          fill="url(#paint1_linear_3502_37741)"
        />
        <path
          d="M9.19985 12.6832C9.09985 12.6832 8.99985 12.5832 8.89985 12.4832L1.89985 4.3832C1.89985 4.2832 1.79985 4.2832 1.79985 4.3832V12.1832C1.79985 12.3832 1.69985 12.3832 1.59985 12.3832H0.799854C0.599854 12.3832 0.599854 12.2832 0.599854 12.1832V1.4832C0.599854 1.2832 0.599854 1.2832 0.699854 1.2832C0.799854 1.2832 0.899854 1.2832 0.999854 1.4832L7.89985 9.5832C7.99985 9.6832 7.99985 9.6832 7.99985 9.5832V1.6832C7.99985 1.4832 8.09985 1.4832 8.19985 1.4832H8.99985C9.09985 1.4832 9.09985 1.4832 9.19985 1.5832C9.19985 1.5832 9.29985 1.6832 9.29985 1.7832V12.4832C9.29985 12.5832 9.29985 12.6832 9.19985 12.6832Z"
          fill="#999999"
        />
        <path
          d="M20.3998 12.3832H19.4998C19.3998 12.3832 19.2998 12.2832 19.1998 12.1832L18.1998 9.8832C18.1998 9.7832 17.9998 9.6832 17.8998 9.6832H13.4998C13.3998 9.6832 13.1998 9.7832 13.1998 9.8832L12.1998 12.1832C12.0998 12.3832 11.9998 12.3832 11.8998 12.3832H11.0998C10.9998 12.3832 10.9998 12.3832 10.9998 12.2832C10.9998 12.1832 10.9998 12.1832 10.9998 12.0832L15.5998 1.3832C15.4998 1.2832 15.4998 1.2832 15.5998 1.2832C15.6998 1.2832 15.6998 1.3832 15.7998 1.4832L20.3998 12.1832C20.3998 12.2832 20.3998 12.2832 20.3998 12.3832ZM17.4998 8.2832L15.6998 3.9832C15.6998 3.8832 15.5998 3.8832 15.4998 3.9832L13.6998 8.2832C13.5998 8.4832 13.6998 8.4832 13.7998 8.4832H17.2998C17.3998 8.4832 17.3998 8.4832 17.3998 8.3832C17.4998 8.4832 17.4998 8.3832 17.4998 8.2832Z"
          fill="#999999"
        />
        <path
          d="M34.2 12.3832H33.5C33.4 12.3832 33.3 12.2832 33.2 12.1832L31.9 4.8832C31.9 4.8832 31.9 4.7832 31.8 4.7832C31.8 4.7832 31.7 4.7832 31.7 4.8832L28.2 12.4832C28.1 12.5832 28.1 12.6832 28 12.6832C27.9 12.6832 27.8 12.5832 27.8 12.4832L24.2 4.8832V4.7832C24.2 4.7832 24.2 4.7832 24.1 4.7832C24.1 4.7832 24.1 4.7832 24.1 4.8832L22.9 12.1832C22.9 12.3832 22.8 12.3832 22.6 12.3832H21.9C21.8 12.3832 21.8 12.3832 21.7 12.2832C21.7 12.2832 21.6 12.1832 21.7 12.0832L23.5 1.3832C23.5 1.2832 23.6 1.2832 23.7 1.2832C23.8 1.2832 23.9 1.3832 24 1.4832L28 9.9832C28.1 10.1832 28.1 10.1832 28.2 9.9832L32.2 1.4832C32.2 1.2832 32.3 1.2832 32.4 1.2832C32.5 1.2832 32.6 1.3832 32.6 1.4832L34.5 12.1832C34.5 12.2832 34.5 12.2832 34.5 12.3832C34.3 12.3832 34.3 12.3832 34.2 12.3832Z"
          fill="#999999"
        />
        <path
          d="M42.1 12.3828H36.4C36.4 12.3828 36.3 12.3828 36.3 12.2828C36.3 12.1828 36.2 12.1828 36.2 12.0828V1.68281C36.2 1.58281 36.2 1.58281 36.3 1.48281C36.4 1.38281 36.4 1.38281 36.4 1.38281H42C42.2 1.38281 42.2 1.48281 42.2 1.58281V2.28281C42.2 2.38281 42.2 2.38281 42.1 2.48281C42.1 2.48281 42 2.58281 41.9 2.58281H37.6C37.4 2.58281 37.4 2.68281 37.4 2.78281V5.98281C37.4 6.18281 37.4 6.28281 37.6 6.28281H41.5C41.6 6.28281 41.7 6.38281 41.7 6.48281V7.28281C41.7 7.38281 41.6 7.48281 41.5 7.48281H37.6C37.4 7.48281 37.4 7.58281 37.4 7.68281V10.9828C37.4 11.0828 37.4 11.0828 37.5 11.1828C37.6 11.2828 37.5 11.2828 37.6 11.2828H42.1C42.3 11.2828 42.3 11.3828 42.3 11.4828V12.1828C42.3 12.3828 42.2 12.3828 42.1 12.3828Z"
          fill="#999999"
        />
        <path
          d="M184.3 91.7828H182.5C182.4 91.7828 182.2 91.6828 182.2 91.5828L177.8 84.9828C177.7 84.7828 177.6 84.7828 177.6 85.0828V91.5828C177.6 91.6828 177.6 91.6828 177.5 91.7828C177.5 91.7828 177.4 91.8828 177.3 91.8828H175.5C175.4 91.8828 175.4 91.8828 175.3 91.7828C175.3 91.7828 175.2 91.6828 175.2 91.5828V81.0828C175.2 80.8828 175.3 80.8828 175.4 80.8828H177.2C177.4 80.8828 177.5 80.9828 177.6 81.0828L182.1 87.6828C182.1 87.7828 182.2 87.7828 182.2 87.7828C182.2 87.7828 182.3 87.6828 182.3 87.5828V81.0828C182.3 80.8828 182.4 80.8828 182.5 80.8828H184.3C184.5 80.8828 184.5 80.9828 184.5 81.0828V91.5828C184.5 91.6828 184.5 91.6828 184.4 91.7828C184.4 91.7828 184.4 91.7828 184.3 91.7828Z"
          fill="#4C4C4C"
        />
        <path
          d="M195.9 91.7832H194.1C194 91.7832 193.9 91.6832 193.8 91.5832L193.1 89.7832C193 89.6832 192.9 89.5832 192.8 89.5832H188.6C188.5 89.5832 188.4 89.6832 188.3 89.7832L187.6 91.4832C187.5 91.6832 187.4 91.6832 187.3 91.6832H185.6C185.4 91.6832 185.4 91.5832 185.4 91.4832L189.6 80.9832C189.7 80.8832 189.8 80.7832 189.9 80.7832H191.4C191.6 80.7832 191.7 80.8832 191.7 80.9832L196 91.4832C196.1 91.6832 196.1 91.7832 195.9 91.7832ZM192.1 87.4832L190.7 84.0832C190.6 83.9832 190.6 83.9832 190.5 84.0832L189.1 87.4832V87.5832L189.2 87.6832H191.8C192.1 87.6832 192.2 87.5832 192.1 87.4832Z"
          fill="#4C4C4C"
        />
        <path
          d="M209.5 91.7832H207.7C207.5 91.7832 207.5 91.6832 207.4 91.5832L206.5 85.3832C206.5 85.2832 206.5 85.2832 206.4 85.2832C206.3 85.2832 206.3 85.2832 206.3 85.3832L204 91.5832C204 91.7832 203.9 91.7832 203.7 91.7832H202.6C202.5 91.7832 202.4 91.6832 202.3 91.5832L199.9 85.2832C199.9 85.2832 199.9 85.1832 199.8 85.1832C199.8 85.1832 199.8 85.1832 199.7 85.1832C199.7 85.1832 199.7 85.1832 199.7 85.2832L198.8 91.5832C198.8 91.7832 198.7 91.7832 198.5 91.7832H196.7C196.6 91.7832 196.5 91.6832 196.5 91.5832L198 81.0832C198 80.9832 198 80.9832 198.1 80.8832L198.2 80.7832H200C200.2 80.7832 200.3 80.8832 200.3 80.9832L202.9 87.5832C202.9 87.5832 202.9 87.6832 203 87.6832C203.1 87.6832 203.1 87.6832 203.1 87.6832C203.1 87.6832 203.1 87.6832 203.2 87.5832L205.6 80.9832C205.6 80.8832 205.8 80.7832 205.9 80.7832H207.7C207.9 80.7832 207.9 80.8832 208 80.9832L209.5 91.4832C209.7 91.6832 209.6 91.7832 209.5 91.7832Z"
          fill="#4C4C4C"
        />
        <path
          d="M217.3 91.7828H211.1C211 91.7828 211 91.7828 210.9 91.6828C210.9 91.6828 210.8 91.5828 210.8 91.4828V81.0828C210.8 80.8828 210.9 80.8828 211 80.8828H217.1C217.3 80.8828 217.3 80.9828 217.3 81.0828V82.5828C217.3 82.7828 217.2 82.7828 217.1 82.7828H213.2C213.1 82.7828 213.1 82.7828 213 82.8828C213 82.8828 212.9 82.9828 212.9 83.0828V85.0828C212.9 85.2828 213 85.2828 213.1 85.2828H216.6C216.7 85.2828 216.7 85.2828 216.8 85.3828L216.9 85.4828V86.9828C216.9 87.1828 216.8 87.1828 216.7 87.1828H213.2C213 87.1828 213 87.2828 213 87.3828V89.4828C213 89.5828 213.1 89.6828 213.2 89.6828H217.2C217.4 89.6828 217.4 89.7828 217.4 89.8828V91.3828C217.4 91.4828 217.4 91.4828 217.3 91.5828C217.4 91.7828 217.4 91.7828 217.3 91.7828Z"
          fill="#4C4C4C"
        />
        <path
          d="M124.4 63.5838C123.6 62.1838 122 61.9838 120.6 62.4838C111.4 67.8838 98.8998 64.3838 94.0998 54.0838H105.7C107.3 54.0838 108.4 52.9838 108.4 51.3838C108.4 49.7838 107.3 48.6838 105.7 48.6838H92.3998C92.0998 47.0838 92.0998 45.3838 92.3998 43.5838H105.7C107.3 43.5838 108.4 42.4838 108.4 40.8838C108.4 39.2838 107.3 38.1838 105.7 38.1838H94.0998C98.9998 27.8838 111.4 24.3838 120.6 29.7838C122 30.5838 123.6 30.0838 124.4 28.6838C125.2 27.2838 124.7 25.6838 123.3 24.8838C110.3 17.5838 93.1998 23.4838 88.0998 38.1838H83.9998C82.3998 38.1838 81.2998 39.2838 81.2998 40.8838C81.2998 42.4838 82.3998 43.5838 83.9998 43.5838H86.9998C86.6998 45.4838 86.6998 47.0838 86.9998 48.9838H83.9998C82.3998 48.9838 81.2998 50.0838 81.2998 51.6838C81.2998 53.2838 82.3998 54.3838 83.9998 54.3838H88.0998C93.1998 68.9838 110.3 74.9838 123.3 67.3838C124.7 66.5838 124.9 64.8838 124.4 63.5838Z"
          fill="url(#paint2_linear_3502_37741)"
        />
        <path
          d="M108.6 89.4836C84.9 89.4836 65.7 70.2836 65.7 46.5836C65.7 22.8836 85 3.68359 108.6 3.68359C132.2 3.68359 151.5 22.8836 151.5 46.5836C151.5 70.2836 132.3 89.4836 108.6 89.4836ZM108.6 10.4836C88.7 10.4836 72.5 26.6836 72.5 46.5836C72.5 66.4836 88.7 82.6836 108.6 82.6836C128.5 82.6836 144.7 66.4836 144.7 46.5836C144.7 26.6836 128.5 10.4836 108.6 10.4836Z"
          fill="#EA0709"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3502_37741"
            x1="48.6719"
            y1="7.1205"
            x2="108.915"
            y2="7.1205"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A6A8AB" />
            <stop offset="0.5602" stopColor="#A6A8AB" />
            <stop offset="1" stopColor="#58595B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3502_37741"
            x1="168.89"
            y1="86.0511"
            x2="122.6"
            y2="86.0511"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A4292B" />
            <stop offset="0.3294" stopColor="#DC292B" />
            <stop offset="0.4756" stopColor="#E01E20" />
            <stop offset="0.7761" stopColor="#E80D0F" />
            <stop offset="1" stopColor="#EA0709" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3502_37741"
            x1="90.6712"
            y1="23.8043"
            x2="125.603"
            y2="68.1471"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.158" stopColor="#A6A8AB" />
            <stop offset="0.9272" stopColor="#EA0709" />
          </linearGradient>
        </defs>
      </svg>
    </IconContainer>
  )
}

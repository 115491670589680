import React from 'react'

export const IdentityCard = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M17 23C18.6569 23 20 21.6569 20 20C20 18.3431 18.6569 17 17 17C15.3431 17 14 18.3431 14 20C14 21.6569 15.3431 23 17 23Z"
        className="document--primary--fill"
      />
      <path
        d="M12 29C12 26.2386 14.2386 24 17 24C19.7614 24 22 26.2386 22 29V31H12V29Z"
        className="document--primary--fill"
      />
      <path
        d="M36 18C36 17.4477 35.5523 17 35 17H26C25.4477 17 25 17.4477 25 18C25 18.5523 25.4477 19 26 19H35C35.5523 19 36 18.5523 36 18Z"
        className="document--primary--fill"
      />
      <path
        d="M29 22C29 21.4477 28.5523 21 28 21H26C25.4477 21 25 21.4477 25 22C25 22.5523 25.4477 23 26 23H28C28.5523 23 29 22.5523 29 22Z"
        className="document--primary--fill"
      />
      <path
        d="M33 25C33.5523 25 34 25.4477 34 26C34 26.5523 33.5523 27 33 27H26C25.4477 27 25 26.5523 25 26C25 25.4477 25.4477 25 26 25H33Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C8.34315 12 7 13.3431 7 15V33C7 34.6569 8.34315 36 10 36H38C39.6569 36 41 34.6569 41 33V15C41 13.3431 39.6569 12 38 12H10ZM9 15C9 14.4477 9.44771 14 10 14H38C38.5523 14 39 14.4477 39 15V33C39 33.5523 38.5523 34 38 34H10C9.44772 34 9 33.5523 9 33V15Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

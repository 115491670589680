import React, { useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import axios from 'axios'
import { observer } from 'mobx-react'
import { Header, LoadingSpinner, Button } from '../../Generic'
import { StoreContext } from '../../App'
import { usePageVisibility } from '../../../hooks/usePageVisibility'

import {
  Divider,
  ButtonText,
  IntroParagraph,
} from '../../../styles/generic.styles'

import {
  ContentContainerBottomFixedHorizontal,
  MarginOnly,
  Margined,
  MobileSpacerForContentContainerBottomFixed,
} from '../../../styles/layout.styles'
import { Code, CodeWrapper } from '../SwitchToMobile.styles'
import { devLog } from '../../../methods/devLog'
import { forceRedirect } from '../../../methods/forceRedirect'
import { DesktopIcon } from '../icons/DesktopIcon'
import { actionReport } from '../../../methods/actionReport'

interface ShowPinProps {
  nextStep: () => void
  stayUrl: string
  generatePinCodeUrl: string
  isPinCodeConsumedUrl: string
  closeDesktopSessionUrl: string
  shouldProceedToPollingStep?: boolean
}

export const ShowPin = observer(
  ({
    nextStep,
    stayUrl,
    generatePinCodeUrl,
    isPinCodeConsumedUrl,
    closeDesktopSessionUrl,
    shouldProceedToPollingStep,
  }: ShowPinProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { SwitchToMobile: trans } = store.TranslationsState.translations

    const [loading, setLoading] = useState(true)
    const [isPinCodeConsumed, setIsPinCodeConsumed] = useState(false)
    const [error, setError] = useState(false)
    const [pinCode, setPinCode] = useState('0000')
    const [shouldPoll, setShouldPoll] = useState(true)

    const isVisible = usePageVisibility()

    const getCode = async () => {
      try {
        const { data } = await axios.post(
          generatePinCodeUrl,
          {},
          {
            withCredentials: true,
          }
        )

        setPinCode(data.pinCode)
        setLoading(false)
      } catch (error) {
        devLog(error)
      }
    }

    const checkIfPinCodeIsConsumed = async () => {
      try {
        const { data } = await axios.get(isPinCodeConsumedUrl, {
          withCredentials: true,
        })

        if (!data.isConsumed) return
        setIsPinCodeConsumed(true)

        if (shouldProceedToPollingStep) {
          nextStep()
        } else if (data?.shouldCloseSession) {
          forceRedirect(closeDesktopSessionUrl)
        } else {
          setError(true)

          actionReport({
            type: 'event.onboarding-web.go-to-mobile.DESKTOP_WRONG_PIN_PAGE_OPENED',
            payload: {},
          })
        }
      } catch (error) {
        devLog(error)
      }
    }

    useEffect(() => {
      actionReport({
        type: 'event.onboarding-web.go-to-mobile.DESKTOP_SHOW_PIN_PAGE_OPENED',
        payload: {},
      })

      void getCode()
    }, [])

    useEffect(() => {
      setShouldPoll(isVisible)
    }, [isVisible])

    useInterval(
      checkIfPinCodeIsConsumed,
      !loading && !isPinCodeConsumed && shouldPoll ? 3000 : null
    )

    return !error ? (
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.showPin.header}
        </Header>

        <IntroParagraph>{trans.showPin.description}</IntroParagraph>

        <Margined margin="0 0 60px" />

        <CodeWrapper>
          {!loading && <Code data-testid="pinCode">{pinCode}</Code>}

          <LoadingSpinner
            width="40px"
            bgColor="blue"
            padding="50px 0 20px 0"
            {...theme.loadingSpinner}
          />

          <IntroParagraph> {trans.showPin.spinner} </IntroParagraph>
        </CodeWrapper>

        <Divider margin="30px 0 0 0" width="100%" />
      </>
    ) : (
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.showPin.errorHeader}
        </Header>

        <IntroParagraph>{trans.showPin.errorDescription}</IntroParagraph>

        <MarginOnly margin="20px 0" />

        <DesktopIcon />

        <ContentContainerBottomFixedHorizontal border="none" mobileGap="10px">
          <Button
            onClick={() => {
              if (stayUrl?.length) {
                forceRedirect(stayUrl)
              }
            }}
            {...theme.button}
            padding="10px 20px"
            paddingMobile="14px 10px"
          >
            <ButtonText>{trans.showPin.errorProceed}</ButtonText>
          </Button>
        </ContentContainerBottomFixedHorizontal>

        <MobileSpacerForContentContainerBottomFixed />
      </>
    )
  }
)

import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import {
  Divider,
  MicropaymentInfoContent,
  MicropaymentInfoGraph,
} from '../../styles/generic.styles'
import { DTPInfoGraph } from './DTPInfoGraph'
import { TermsAndConditions } from '../../components/TermsAndConditions/TermsAndConditions'

export const TermsAndConditionsDTPInfoPage = observer(() => {
  const store = useContext(StoreContext)
  const { DTPInfoPage: trans } = store.TranslationsState.translations

  return (
    <TermsAndConditions
      showHeader={true}
      header={trans.header}
      showTitle={false}
      buttonText={trans.proceed}
      additionalParagraph={
        <>
          <MicropaymentInfoContent>{trans.content}</MicropaymentInfoContent>

          <MicropaymentInfoGraph>
            <DTPInfoGraph />
          </MicropaymentInfoGraph>

          <MicropaymentInfoContent>{trans.openBanking}</MicropaymentInfoContent>
        </>
      }
    />
  )
})

import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const DesktopIcon = () => (
  <HorizontalIconContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="129"
      viewBox="0 0 184 129"
      fill="none"
    >
      <rect
        x="1.5"
        y="1.78345"
        width="181"
        height="113"
        rx="8.5"
        fill="white"
        stroke="#DCDBE2"
        strokeWidth="3"
      />
      <path
        d="M1.06189 120.283C1.55399 124.23 4.92038 127.283 9 127.283H175C179.08 127.283 182.446 124.23 182.938 120.283H1.06189Z"
        fill="#DCDBE2"
        stroke="#DCDBE2"
        strokeWidth="2"
      />
      <path
        d="M112.375 114.283L9.99998 114.283C6.134 114.283 3 111.149 3 107.283C3 107.283 3.5 109.283 5 109.283C7 109.283 9.5 109.283 9.5 109.283L112.375 109.283L112.375 114.283Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 3.28345L174 3.28345C177.866 3.28345 181 6.41745 181 10.2834C181 10.2834 180.502 8.28343 179.007 8.28343C177.014 8.28343 174.522 8.28343 174.522 8.28343L72 8.28344V3.28345Z"
        fill="#DCDBE2"
      />
      <path
        d="M112 3.28345L10 3.28345C6.13401 3.28345 3 6.41745 3 10.2834C3 10.2834 3.49828 8.28343 4.99314 8.28343C6.98628 8.28343 9.47771 8.28343 9.47771 8.28343L112 8.28344V3.28345Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 114.283L174 114.283C177.866 114.283 181 111.149 181 107.283C181 107.283 180.502 109.283 179.007 109.283C177.014 109.283 174.522 109.283 174.522 109.283L72 109.283L72 114.283Z"
        fill="#DCDBE2"
      />
      <circle cx="92" cy="4.28345" r="1.5" fill="white" stroke="black" />
      <path d="M3 8.28345H181V15.2834H3V8.28345Z" fill="#F6F5FA" />
      <path d="M12 20.2834H37V27.2834H12V20.2834Z" fill="#FCFBFD" />
      <path d="M73 20.2834H98V27.2834H73V20.2834Z" fill="#FCFBFD" />
      <path d="M110 20.2834H135V27.2834H110V20.2834Z" fill="#F6F5FA" />
      <path d="M147 20.2834H172V27.2834H147V20.2834Z" fill="#FCFBFD" />
      <path d="M98 32.2834H172V71.2834H98V32.2834Z" fill="#FCFBFD" />
      <path d="M12 83.2834H172V104.283H12V83.2834Z" fill="#F6F5FA" />
      <path d="M55 32.2834H86V71.2834H55V32.2834Z" fill="#FCFBFD" />
      <path d="M12 32.2834H43V71.2834H12V32.2834Z" fill="#F6F5FA" />
      <path
        d="M53 12.2834C53 11.1789 53.8954 10.2834 55 10.2834H76C77.1046 10.2834 78 11.1789 78 12.2834V15.2834H53V12.2834Z"
        fill="white"
      />
      <rect x="7" y="12.2834" width="17" height="2" rx="1" fill="white" />
      <rect x="32" y="12.2834" width="18" height="2" rx="1" fill="white" />
      <path
        d="M28 11.2834V14.2834"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <rect
        x="56.6592"
        y="12.0334"
        width="17.3412"
        height="2.25"
        rx="1.125"
        fill="#F6F5FA"
      />
    </svg>
  </HorizontalIconContainer>
)

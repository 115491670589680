import styled from 'styled-components'

const circleSize = '1rem'
const spacing = '0.25rem'

interface StepperProps {
  color: string
  fontSizeTitle: string
  fontSizeDesc: string
}

export const StepperWrapper = styled.div<StepperProps>`
  .c-stepper {
    display: flex;
    margin: 30px 0;
  }

  .c-stepper__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    &:before {
      --size: 3rem;
      content: '';
      display: block;
      width: ${circleSize};
      height: ${circleSize};
      border-radius: 50%;
      border: 3px solid ${(props) => props.color};
      margin: 0 auto;
    }

    &:not(:last-child) {
      &:after {
        content: '';
        position: relative;
        top: calc(${circleSize} / 2 + ${spacing} / 2);
        width: calc(100% - ${circleSize});
        left: calc(50% + ${circleSize} / 2);
        height: 2px;
        background-color: ${(props) => props.color};
        order: -1;
      }
    }

    &:last-child {
      .c-stepper__title {
        margin: 0.7rem 0 -0.5rem 0;
      }
    }
  }

  .c-stepper__title {
    font-weight: bold;
    font-size: ${(props) => props.fontSizeTitle};
    margin: 0.5rem 0 -0.5 0;
  }

  .c-stepper__desc {
    color: grey;
    font-size: ${(props) => props.fontSizeDesc};
    padding-left: ${spacing};
    padding-right: ${spacing};
  }

  .wrapper {
    max-width: 1000px;
    margin: 2rem auto 0;

    ol {
      padding-inline-start: 0;
    }
  }
`

import React from 'react'
import { Container } from './WrapKontomatik.styles'

interface WrapKontomatikProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  bgColor?: string
  textColor?: string
  borderColor?: string
  headerColor?: string
  primaryColor?: string
  primaryReverseColor?: string
  secondaryColor?: string
  mainHoverColor?: string
  mainHoverReverseColor?: string
  listHoverColor?: string
  disabledColor?: string
  errorColor?: string
  borderRadius?: string
  buttonBorderRadius?: string
  padding?: string
  minWidth?: string
  width?: string
  border?: string
  boxShadow?: string
  margin?: string
  paddingMobile?: string
  marginMobile?: string
  touchAction?: string
}

export const WrapKontomatik = (props: WrapKontomatikProps) => {
  // eslint-disable-next-line react/prop-types
  return <Container {...props}>{props.children}</Container>
}

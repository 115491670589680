import en from './en.json'
import bg from './languages/bg.json'
import cs from './languages/cs.json'
import da from './languages/da.json'
import deBmw from './languages/de-bmw.json'
import deIkano from './languages/de-ikano.json'
import de from './languages/de.json'
import el from './languages/el.json'
import enBmw from './languages/en-bmw.json'
import es from './languages/es.json'
import fi from './languages/fi.json'
import fr from './languages/fr.json'
import hu from './languages/hu.json'
import itBmw from './languages/it-bmw.json'
import it from './languages/it.json'
import nl from './languages/nl.json'
import no from './languages/no.json'
import plBmw from './languages/pl-bmw.json'
import pl from './languages/pl.json'
import pt from './languages/pt.json'
import ro from './languages/ro.json'
import sk from './languages/sk.json'
import sl from './languages/sl.json'
import svBmw from './languages/sv-bmw.json'
import sv from './languages/sv.json'

export {
  bg,
  cs,
  da,
  de,
  deBmw,
  deIkano,
  el,
  en,
  enBmw,
  es,
  fi,
  fr,
  hu,
  it,
  itBmw,
  nl,
  no,
  pl,
  plBmw,
  pt,
  ro,
  sk,
  sl,
  sv,
  svBmw,
}

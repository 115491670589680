import { Button, ContentContainer } from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'

import { forceRedirect } from '../../methods/forceRedirect'
import { CompletePageIcon } from '../../pages/ikano/CompletePageIcon'
import { ButtonText, ParagraphCentered } from '../../styles/generic.styles'
import {
  ContentContainerBottomCenteredIkano,
  VerticallyCenteredContainerIkano,
} from '../../styles/layout.styles'

export const CompleteIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { ikanoCompletePage: trans } = store.TranslationsState.translations
  const [mobileVersion, setMobileVersion] = useState(false)
  const apiUrl = process.env.WEB_API_URL
  const [disabled, setDisabled] = useState(false)

  const handleProceed = () => {
    setDisabled(true)
    forceRedirect(`${apiUrl}/micropayment/complete?status=success`)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {!mobileVersion ? (
        <>
          <CompletePageIcon
            fillColor={theme.icon.color2}
            backgroundColor={theme.icon.backgroundColor ?? 'white'}
          />
          <ParagraphCentered>{trans.message}</ParagraphCentered>
        </>
      ) : (
        <VerticallyCenteredContainerIkano>
          <CompletePageIcon
            fillColor={theme.icon.color2}
            backgroundColor={theme.icon.backgroundColor ?? 'white'}
          />
          <ParagraphCentered>{trans.message}</ParagraphCentered>
        </VerticallyCenteredContainerIkano>
      )}
      <ContentContainerBottomCenteredIkano>
        <Button
          disabled={disabled}
          onClick={handleProceed}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
          id="page-submit"
          type="submit"
        >
          <ButtonText>{trans.submit}</ButtonText>
        </Button>
      </ContentContainerBottomCenteredIkano>
    </ContentContainer>
  )
})

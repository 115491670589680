import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const DesktopPadlockErrorIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        width="304"
        height="54"
        viewBox="0 0 304 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.5C0 2.73858 2.23858 0.5 5 0.5H299C301.761 0.5 304 2.73858 304 5.5V53.5H0V5.5Z"
          fill="#DCDBE2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 14.5C31.6863 14.5 29 17.1863 29 20.5C29 23.8137 31.6863 26.5 35 26.5H254C257.314 26.5 260 23.8137 260 20.5C260 17.1863 257.314 14.5 254 14.5H35ZM9 24.5C11.2091 24.5 13 22.7091 13 20.5C13 18.2909 11.2091 16.5 9 16.5C6.79086 16.5 5 18.2909 5 20.5C5 22.7091 6.79086 24.5 9 24.5ZM25 20.5C25 22.7091 23.2091 24.5 21 24.5C18.7909 24.5 17 22.7091 17 20.5C17 18.2909 18.7909 16.5 21 16.5C23.2091 16.5 25 18.2909 25 20.5ZM268 24.5C270.209 24.5 272 22.7091 272 20.5C272 18.2909 270.209 16.5 268 16.5C265.791 16.5 264 18.2909 264 20.5C264 22.7091 265.791 24.5 268 24.5ZM293.5 14.5C292.672 14.5 292 15.1716 292 16C292 16.8284 292.672 17.5 293.5 17.5C294.328 17.5 295 16.8284 295 16C295 15.1716 294.328 14.5 293.5 14.5ZM293.5 19C292.672 19 292 19.6716 292 20.5C292 21.3284 292.672 22 293.5 22C294.328 22 295 21.3284 295 20.5C295 19.6716 294.328 19 293.5 19ZM292 25C292 24.1716 292.672 23.5 293.5 23.5C294.328 23.5 295 24.1716 295 25C295 25.8284 294.328 26.5 293.5 26.5C292.672 26.5 292 25.8284 292 25ZM10 38.5C7.23858 38.5 5 40.7386 5 43.5V53.5H299V43.5C299 40.7386 296.761 38.5 294 38.5H10Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 29.5C39.9706 29.5 44 25.4706 44 20.5C44 15.5294 39.9706 11.5 35 11.5C30.0294 11.5 26 15.5294 26 20.5C26 25.4706 30.0294 29.5 35 29.5ZM35 31.5C41.0751 31.5 46 26.5751 46 20.5C46 14.4249 41.0751 9.5 35 9.5C28.9249 9.5 24 14.4249 24 20.5C24 26.5751 28.9249 31.5 35 31.5Z"
          fill="#EC0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5385 19.4273H36.4615V18.8747C36.4615 18.366 36.031 17.9536 35.5 17.9536C34.969 17.9536 34.5385 18.366 34.5385 18.8747V19.4273ZM37.2308 19.4273C37.6556 19.4273 38 19.7572 38 20.1642V23.48C38 23.8869 37.6556 24.2168 37.2308 24.2168H33.7692C33.3444 24.2168 33 23.8869 33 23.48V20.1642C33 19.7572 33.3444 19.4273 33.7692 19.4273V18.8747C33.7692 17.9591 34.5441 17.2168 35.5 17.2168C36.4559 17.2168 37.2308 17.9591 37.2308 18.8747V19.4273Z"
          fill="black"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

import styled from 'styled-components'

interface ContainerProps {
  bgColor?: string
  width?: string
  padding?: string
  heightMobile?: string
  paddingMobile?: string
  borderRadius?: string
  minWidth?: string
  minHeight?: string
  border?: string
  boxShadow?: string
  margin?: string
  marginMobile?: string
  color?: string
  touchAction?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-content-container',
})<ContainerProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};
  padding: ${(props) => (props.padding ? props.padding : '38px 25px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  width: ${(props) => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  border: ${(props) => (props.border ? props.border : 'none')};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
  color: ${(props) => (props.color ? props.color : 'black')};
  touch-action: ${(props) => (props.touchAction ? props.touchAction : 'auto')};

  @media (max-width: 980px) {
    width: calc(100% - 40px);
    min-width: auto;
    margin: 10px 20px;
    padding: 15px;
    height: ${(props) =>
      props.heightMobile ? `${props.heightMobile} !important` : 'auto'};
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: auto;
    min-height: auto;
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '10px')};
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : '10px')};
    height: ${(props) =>
      props.heightMobile ? `${props.heightMobile} !important` : 'auto'};
  }
`

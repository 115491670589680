import { useEffect, useState } from 'react'

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(
    document.visibilityState !== 'hidden'
  )
  const onVisibilityChange = () =>
    setIsVisible(document.visibilityState !== 'hidden')

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  })

  return isVisible
}

import React from 'react'

interface VideoProps {
  color: string
  thickness?: string
}

export const Video = ({ color, thickness }: VideoProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1348_126234)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34315 20 3 20H13C14.6569 20 16 18.6569 16 17V14.5307L20.7286 18.4249C22.0334 19.4994 24.0001 18.5713 24.0001 16.8811V7.28972C24.0001 5.54447 21.9211 4.63648 20.6408 5.8226L16 10.1222V7C16 5.34315 14.6569 4 13 4H3ZM2 7C2 6.44772 2.44772 6 3 6H13C13.5523 6 14 6.44772 14 7V17C14 17.5523 13.5523 18 13 18H3C2.44772 18 2 17.5523 2 17V7ZM16.5193 12.3675L22.0001 7.28972V16.8811L16.5193 12.3675Z"
          fill={`${color ? color : '#280b60'}`}
          stroke={`${color ? color : '#280b60'}`}
          strokeWidth={`${thickness ? thickness : '0'}`}
          strokeLinecap="round"
          className="colorized--fill colorized--stroke"
        />
      </g>
      <defs>
        <clipPath id="clip0_1348_126234">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { ContentContainer, LoadingSpinner } from '../components/Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'

import { EnterPin } from '../components/GoToMobile/steps/EnterPin'
import { Error } from '../components/GoToMobile/steps/Error'
import { Intro } from '../components/GoToMobile/steps/Intro'
import { devLog } from '../methods/devLog'
import { GetSwitchToMobileContextResponse } from './SwitchToMobilePage'
import { getOnboardingContext } from '../methods/getOnboardingContext'

export const ContinueOnMobilePage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const [step, setStep] = useState(0)
  const apiUrl = process.env.WEB_API_URL

  const [context, setContext] = useState<
    GetSwitchToMobileContextResponse | undefined
  >()

  const { startMobileSessionUrl, closeMobileSessionUrl, verifyPinCodeUrl } =
    context?.endpoints || {}

  const nextStep = () => {
    setStep(step + 1)
  }

  const initComponent = async () => {
    try {
      const { data } = await axios.get<GetSwitchToMobileContextResponse>(
        `${apiUrl}/${process.env.GO_TO_MOBILE_WEB_API_PATH}/get-context`,
        {
          withCredentials: true,
        }
      )

      devLog(data)
      setContext(data)

      nextStep()
    } catch (error) {
      devLog(error)
    }
  }

  useEffect(() => {
    void initComponent()
  }, [])

  const renderCurrentStep = () => {
    if (!context)
      return (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )

    switch (step) {
      case 0:
        return (
          <>
            <LoadingSpinner
              width="68px"
              padding="100px 0"
              height="400px"
              {...theme.loadingSpinner}
            />
          </>
        )
      case 1:
        return (
          <Intro
            nextStep={nextStep}
            startMobileSessionUrl={startMobileSessionUrl}
          />
        )
      case 2:
        return (
          <EnterPin
            nextStep={nextStep}
            maxAttempts={context?.pinCode?.maxAttempts}
            verifyPinCodeUrl={verifyPinCodeUrl}
            closeMobileSessionUrl={closeMobileSessionUrl}
          />
        )
      case 3:
        return <Error />

      default:
        return <></>
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {renderCurrentStep()}
    </ContentContainer>
  )
})

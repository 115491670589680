import React from 'react'

export const IkanoIDBackIcon = () => {
  return (
    <svg
      width="184"
      height="116"
      viewBox="0 0 184 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 17C17.1193 17 16 18.1193 16 19.5C16 20.8807 17.1193 22 18.5 22H74.5C75.8807 22 77 20.8807 77 19.5C77 18.1193 75.8807 17 74.5 17H18.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M109.5 17C108.119 17 107 18.1193 107 19.5C107 20.8807 108.119 22 109.5 22H165.5C166.881 22 168 20.8807 168 19.5C168 18.1193 166.881 17 165.5 17H109.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M18.5 26C17.1193 26 16 27.1193 16 28.5C16 29.8807 17.1193 31 18.5 31H49.5C50.8807 31 52 29.8807 52 28.5C52 27.1193 50.8807 26 49.5 26H18.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M109.5 26C108.119 26 107 27.1193 107 28.5C107 29.8807 108.119 31 109.5 31H140.5C141.881 31 143 29.8807 143 28.5C143 27.1193 141.881 26 140.5 26H109.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M18.5 45C17.1193 45 16 46.1193 16 47.5C16 48.8807 17.1193 50 18.5 50H42.5C43.8807 50 45 48.8807 45 47.5C45 46.1193 43.8807 45 42.5 45H18.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M18.5 54C17.1193 54 16 55.1193 16 56.5C16 57.8807 17.1193 59 18.5 59H30.5C31.8807 59 33 57.8807 33 56.5C33 55.1193 31.8807 54 30.5 54H18.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M59.5 45C58.1193 45 57 46.1193 57 47.5C57 48.8807 58.1193 50 59.5 50H83.5C84.8807 50 86 48.8807 86 47.5C86 46.1193 84.8807 45 83.5 45H59.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M59.5 54C58.1193 54 57 55.1193 57 56.5C57 57.8807 58.1193 59 59.5 59H71.5C72.8807 59 74 57.8807 74 56.5C74 55.1193 72.8807 54 71.5 54H59.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M100.5 45C99.1193 45 98 46.1193 98 47.5C98 48.8807 99.1193 50 100.5 50H124.5C125.881 50 127 48.8807 127 47.5C127 46.1193 125.881 45 124.5 45H100.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M100.5 54C99.1193 54 98 55.1193 98 56.5C98 57.8807 99.1193 59 100.5 59H112.5C113.881 59 115 57.8807 115 56.5C115 55.1193 113.881 54 112.5 54H100.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M141.5 45C140.119 45 139 46.1193 139 47.5C139 48.8807 140.119 50 141.5 50H165.5C166.881 50 168 48.8807 168 47.5C168 46.1193 166.881 45 165.5 45H141.5Z"
        fill="#DCDBE2"
      />
      <path
        d="M141.5 54C140.119 54 139 55.1193 139 56.5C139 57.8807 140.119 59 141.5 59H153.5C154.881 59 156 57.8807 156 56.5C156 55.1193 154.881 54 153.5 54H141.5Z"
        fill="#DCDBE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0H174C179.523 0 184 4.47715 184 10V106C184 111.523 179.523 116 174 116H10C4.47715 116 0 111.523 0 106V10ZM10 2H174C178.418 2 182 5.58172 182 10V69H2V10C2 5.58172 5.58172 2 10 2Z"
        fill="#DCDBE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174 2H10C5.58172 2 2 5.58172 2 10V69H182V10C182 5.58172 178.418 2 174 2ZM18.5 17C17.1193 17 16 18.1193 16 19.5C16 20.8807 17.1193 22 18.5 22H74.5C75.8807 22 77 20.8807 77 19.5C77 18.1193 75.8807 17 74.5 17H18.5ZM109.5 17C108.119 17 107 18.1193 107 19.5C107 20.8807 108.119 22 109.5 22H165.5C166.881 22 168 20.8807 168 19.5C168 18.1193 166.881 17 165.5 17H109.5ZM16 28.5C16 27.1193 17.1193 26 18.5 26H49.5C50.8807 26 52 27.1193 52 28.5C52 29.8807 50.8807 31 49.5 31H18.5C17.1193 31 16 29.8807 16 28.5ZM109.5 26C108.119 26 107 27.1193 107 28.5C107 29.8807 108.119 31 109.5 31H140.5C141.881 31 143 29.8807 143 28.5C143 27.1193 141.881 26 140.5 26H109.5ZM16 47.5C16 46.1193 17.1193 45 18.5 45H42.5C43.8807 45 45 46.1193 45 47.5C45 48.8807 43.8807 50 42.5 50H18.5C17.1193 50 16 48.8807 16 47.5ZM18.5 54C17.1193 54 16 55.1193 16 56.5C16 57.8807 17.1193 59 18.5 59H30.5C31.8807 59 33 57.8807 33 56.5C33 55.1193 31.8807 54 30.5 54H18.5ZM57 47.5C57 46.1193 58.1193 45 59.5 45H83.5C84.8807 45 86 46.1193 86 47.5C86 48.8807 84.8807 50 83.5 50H59.5C58.1193 50 57 48.8807 57 47.5ZM59.5 54C58.1193 54 57 55.1193 57 56.5C57 57.8807 58.1193 59 59.5 59H71.5C72.8807 59 74 57.8807 74 56.5C74 55.1193 72.8807 54 71.5 54H59.5ZM98 47.5C98 46.1193 99.1193 45 100.5 45H124.5C125.881 45 127 46.1193 127 47.5C127 48.8807 125.881 50 124.5 50H100.5C99.1193 50 98 48.8807 98 47.5ZM100.5 54C99.1193 54 98 55.1193 98 56.5C98 57.8807 99.1193 59 100.5 59H112.5C113.881 59 115 57.8807 115 56.5C115 55.1193 113.881 54 112.5 54H100.5ZM139 47.5C139 46.1193 140.119 45 141.5 45H165.5C166.881 45 168 46.1193 168 47.5C168 48.8807 166.881 50 165.5 50H141.5C140.119 50 139 48.8807 139 47.5ZM141.5 54C140.119 54 139 55.1193 139 56.5C139 57.8807 140.119 59 141.5 59H153.5C154.881 59 156 57.8807 156 56.5C156 55.1193 154.881 54 153.5 54H141.5Z"
        fill="white"
      />
      <path
        d="M14 79C14 78.4473 14.4478 78 15 78H19C19.5522 78 20 78.4473 20 79V84C20 84.5527 19.5522 85 19 85H15C14.4478 85 14 84.5527 14 84V79Z"
        fill="black"
      />
      <path
        d="M23.3334 85C22.781 85 22.3334 84.5527 22.3334 84V79C22.3334 78.7012 22.4645 78.4326 22.6722 78.249C22.7379 78.1914 22.8113 78.1416 22.8906 78.1025C22.936 78.0801 22.9833 78.0615 23.0321 78.0459C23.1272 78.0166 23.2284 78 23.3334 78H27.3334C27.8856 78 28.3334 78.4473 28.3334 79V84C28.3334 84.5527 27.8856 85 27.3334 85H23.3334Z"
        fill="black"
      />
      <path
        d="M31.6666 85H35.6666C36.219 85 36.6666 84.5527 36.6666 84V79C36.6666 78.4473 36.219 78 35.6666 78H31.6666C31.1144 78 30.6666 78.4473 30.6666 79V84C30.6666 84.3154 30.8121 84.5957 31.0396 84.7793C31.1274 84.8496 31.2275 84.9062 31.3363 84.9443C31.4397 84.9805 31.5509 85 31.6666 85Z"
        fill="black"
      />
      <path
        d="M44 78C44.5522 78 45 78.4473 45 79V84C45 84.5527 44.5522 85 44 85H40C39.7113 85 39.451 84.8779 39.2686 84.6826C39.1019 84.5039 39 84.2637 39 84V79C39 78.4473 39.4478 78 40 78H44Z"
        fill="black"
      />
      <path
        d="M48.3334 85H52.3334C52.5853 85 52.8156 84.9072 52.9913 84.7539C53.2009 84.5703 53.3334 84.3008 53.3334 84V79C53.3334 78.4473 52.8856 78 52.3334 78H48.3334C47.781 78 47.3334 78.4473 47.3334 79V84C47.3334 84.5527 47.781 85 48.3334 85Z"
        fill="black"
      />
      <path
        d="M56.6666 85C56.3981 85 56.1542 84.8945 55.9745 84.7217C55.7848 84.54 55.6666 84.2842 55.6666 84V79C55.6666 78.4473 56.1144 78 56.6666 78H60.6666C60.9054 78 61.1246 78.084 61.2965 78.2236C61.5223 78.4062 61.6666 78.6865 61.6666 79V84C61.6666 84.5527 61.219 85 60.6666 85H56.6666Z"
        fill="black"
      />
      <path
        d="M69 85H65C64.4478 85 64 84.5527 64 84V79C64 78.832 64.0414 78.6738 64.1145 78.5352C64.1543 78.459 64.2036 78.3887 64.2607 78.3262C64.3304 78.25 64.4119 78.1846 64.5022 78.1328C64.6488 78.0488 64.8187 78 65 78H69C69.0667 78 69.1317 78.0068 69.1947 78.0195C69.2397 78.0283 69.2837 78.04 69.3264 78.0547C69.7184 78.1895 70 78.5615 70 79V84C70 84.5527 69.5522 85 69 85Z"
        fill="black"
      />
      <path
        d="M77.3459 79.707L75.5531 81.5L77.3459 83.293C77.7366 83.6836 77.7366 84.3164 77.3459 84.707C76.9554 85.0977 76.3223 85.0977 75.9318 84.707L72.7247 81.5L75.9318 78.293C76.3223 77.9023 76.9554 77.9023 77.3459 78.293C77.7366 78.6836 77.7366 79.3164 77.3459 79.707Z"
        fill="black"
      />
      <path
        d="M85.6516 78.293C85.261 77.9023 84.6278 77.9023 84.2373 78.293L81.0303 81.5L84.2373 84.707L84.2986 84.7627L84.3673 84.8164C84.4335 84.8633 84.504 84.9004 84.5774 84.9297L84.6484 84.9551L84.7106 84.9717C84.7487 84.9814 84.7871 84.9883 84.8258 84.9922C84.8657 84.9971 84.9059 85 84.946 85C85.0227 85 85.0994 84.9912 85.1744 84.9736L85.2286 84.959L85.2812 84.9424C85.4165 84.8945 85.5433 84.8154 85.6516 84.707C86.0421 84.3164 86.0421 83.6836 85.6516 83.293L83.8586 81.5L85.6516 79.707C86.0421 79.3164 86.0421 78.6836 85.6516 78.293Z"
        fill="black"
      />
      <path
        d="M93.9572 79.707L92.1642 81.5L93.9572 83.293C94.1219 83.458 94.2172 83.666 94.2429 83.8809C94.2615 84.0361 94.2437 84.1953 94.1895 84.3438C94.1411 84.4766 94.0636 84.6006 93.9572 84.707C93.5667 85.0977 92.9335 85.0977 92.5428 84.707L89.3358 81.5L92.5428 78.293C92.9335 77.9023 93.5667 77.9023 93.9572 78.293C94.3477 78.6836 94.3477 79.3164 93.9572 79.707Z"
        fill="black"
      />
      <path
        d="M102.263 78.293C101.872 77.9023 101.239 77.9023 100.849 78.293L97.6414 81.5L100.849 84.707C101.239 85.0977 101.872 85.0977 102.263 84.707C102.321 84.6494 102.37 84.5859 102.411 84.5186C102.528 84.3252 102.574 84.1016 102.549 83.8838C102.524 83.668 102.428 83.459 102.263 83.293L100.47 81.5L102.263 79.707C102.653 79.3164 102.653 78.6836 102.263 78.293Z"
        fill="black"
      />
      <path
        d="M110.855 79.1133C110.83 79.3301 110.735 79.541 110.568 79.707L108.775 81.5L110.568 83.293C110.795 83.5195 110.89 83.8281 110.854 84.123C110.827 84.3369 110.732 84.543 110.568 84.707C110.463 84.8125 110.34 84.8896 110.209 84.9375C110.17 84.9521 110.131 84.9639 110.091 84.9736L110.031 84.9854C109.999 84.9912 109.966 84.9951 109.933 84.9971C109.654 85.0176 109.368 84.9209 109.154 84.707L105.947 81.5L109.154 78.293C109.545 77.9023 110.178 77.9023 110.568 78.293C110.792 78.5176 110.888 78.8213 110.855 79.1133Z"
        fill="black"
      />
      <path
        d="M118.874 78.293C118.483 77.9023 117.85 77.9023 117.46 78.293L114.252 81.5L117.46 84.707C117.85 85.0977 118.483 85.0977 118.874 84.707C119.264 84.3164 119.264 83.6836 118.874 83.293L117.081 81.5L118.874 79.707C119.264 79.3164 119.264 78.6836 118.874 78.293Z"
        fill="black"
      />
      <path
        d="M127.179 79.707L125.386 81.5L127.179 83.293C127.57 83.6836 127.57 84.3164 127.179 84.707C126.789 85.0977 126.156 85.0977 125.765 84.707L122.558 81.5L125.765 78.293C126.156 77.9023 126.789 77.9023 127.179 78.293C127.57 78.6836 127.57 79.3164 127.179 79.707Z"
        fill="black"
      />
      <path
        d="M135.485 78.293C135.094 77.9023 134.461 77.9023 134.071 78.293L130.864 81.5L134.071 84.707C134.461 85.0977 135.094 85.0977 135.485 84.707C135.875 84.3164 135.875 83.6836 135.485 83.293L133.692 81.5L135.485 79.707C135.875 79.3164 135.875 78.6836 135.485 78.293Z"
        fill="black"
      />
      <path
        d="M143.79 79.707L141.998 81.5L143.79 83.293C144.181 83.6836 144.181 84.3164 143.79 84.707C143.4 85.0977 142.767 85.0977 142.376 84.707L139.169 81.5L142.376 78.293C142.767 77.9023 143.4 77.9023 143.79 78.293C144.181 78.6836 144.181 79.3164 143.79 79.707Z"
        fill="black"
      />
      <path
        d="M152.096 79.707C152.26 79.543 152.355 79.3369 152.381 79.124C152.388 79.0732 152.39 79.0215 152.388 78.9697C152.386 78.8857 152.373 78.8018 152.349 78.7197C152.304 78.5635 152.219 78.416 152.096 78.293C151.705 77.9023 151.072 77.9023 150.682 78.293L147.475 81.5L150.682 84.707C151.072 85.0977 151.705 85.0977 152.096 84.707C152.487 84.3164 152.487 83.6836 152.096 83.293L150.303 81.5L152.096 79.707Z"
        fill="black"
      />
      <path
        d="M160.402 79.707L158.609 81.5L160.402 83.293C160.792 83.6836 160.792 84.3164 160.402 84.707C160.011 85.0977 159.378 85.0977 158.987 84.707L155.78 81.5L158.987 78.293C159.378 77.9023 160.011 77.9023 160.402 78.293C160.792 78.6836 160.792 79.3164 160.402 79.707Z"
        fill="black"
      />
      <path
        d="M168.707 78.293C168.317 77.9023 167.683 77.9023 167.293 78.293L164.086 81.5L167.293 84.707C167.683 85.0977 168.317 85.0977 168.707 84.707C169.098 84.3164 169.098 83.6836 168.707 83.293L166.914 81.5L168.707 79.707C169.098 79.3164 169.098 78.6836 168.707 78.293Z"
        fill="black"
      />
      <path
        d="M19.2072 88.293C19.5977 88.6836 19.5977 89.3164 19.2072 89.707L17.4142 91.5L19.2072 93.293C19.343 93.4287 19.4316 93.5938 19.473 93.7676C19.4843 93.8154 19.4921 93.8633 19.4962 93.9121C19.4994 93.9482 19.5006 93.9854 19.4999 94.0215L19.4928 94.1211C19.4867 94.1709 19.4768 94.2207 19.4631 94.2695C19.4438 94.3389 19.4171 94.4062 19.3829 94.4707C19.3375 94.5557 19.2789 94.6357 19.2072 94.707C19.1388 94.7754 19.0631 94.832 18.9825 94.876C18.9152 94.9131 18.8444 94.9424 18.7717 94.9629C18.4348 95.0576 18.058 94.9717 17.7928 94.707L14.5858 91.5L17.7928 88.293C17.864 88.2217 17.9432 88.1631 18.0276 88.1182C18.0837 88.0889 18.1421 88.0645 18.2018 88.0459C18.2499 88.0303 18.2988 88.0195 18.3483 88.0117L18.4305 88.0029L18.4814 88H18.5182C18.6104 88.002 18.7024 88.0166 18.7914 88.0439C18.9436 88.0898 19.0869 88.1729 19.2072 88.293Z"
        fill="black"
      />
      <path
        d="M27.5127 89.707C27.9032 89.3164 27.9032 88.6836 27.5127 88.293C27.1222 87.9023 26.489 87.9023 26.0984 88.293L22.8914 91.5L26.0984 94.707L26.1693 94.7715L26.2416 94.8262C26.3516 94.9014 26.4733 94.9521 26.5991 94.9785C26.9187 95.0459 27.2646 94.9551 27.5127 94.707C27.7472 94.4727 27.8408 94.1504 27.7938 93.8457C27.7625 93.6436 27.6687 93.4492 27.5127 93.293L25.7197 91.5L27.5127 89.707Z"
        fill="black"
      />
      <path
        d="M35.8182 88.293C36.2087 88.6836 36.2087 89.3164 35.8182 89.707L34.0253 91.5L35.8182 93.293C35.8936 93.3682 35.9543 93.4531 36.0006 93.543C36.058 93.6543 36.093 93.7744 36.1057 93.8965C36.1357 94.1855 36.0399 94.4854 35.8182 94.707C35.4277 95.0977 34.7946 95.0977 34.4041 94.707L31.1969 91.5L34.4041 88.293C34.4645 88.2324 34.5309 88.1816 34.6012 88.1396C34.6652 88.1016 34.7325 88.0713 34.8018 88.0488C35.1479 87.9365 35.5432 88.0176 35.8182 88.293Z"
        fill="black"
      />
      <path
        d="M44 95C44.5522 95 45 94.5527 45 94V89C45 88.4473 44.5522 88 44 88H40C39.8242 88 39.6589 88.0459 39.5154 88.126C39.4159 88.1807 39.3269 88.252 39.252 88.3359C39.0952 88.5127 39 88.7451 39 89V94C39 94.5527 39.4478 95 40 95H44Z"
        fill="black"
      />
      <path
        d="M48 95C47.4478 95 47 94.5527 47 94V89C47 88.9072 47.0125 88.8174 47.036 88.7324C47.0476 88.6904 47.0619 88.6494 47.0786 88.6104C47.1245 88.502 47.189 88.4033 47.2679 88.3184C47.3264 88.2559 47.3929 88.2012 47.4658 88.1553C47.6205 88.0566 47.8036 88 48 88H52C52.5522 88 53 88.4473 53 89V94C53 94.2686 52.8939 94.5127 52.7214 94.6924C52.6531 94.7637 52.5742 94.8242 52.4874 94.873C52.4425 94.8984 52.3954 94.9209 52.3466 94.9385C52.3131 94.9512 52.2788 94.9619 52.2438 94.9707C52.1658 94.9902 52.0841 95 52 95H48Z"
        fill="black"
      />
      <path
        d="M60.7349 88.293C60.4564 88.0146 60.0544 87.9346 59.7052 88.0527C59.5647 88.1006 59.4327 88.1807 59.3207 88.293L56.1135 91.5L59.3207 94.707C59.7112 95.0977 60.3444 95.0977 60.7349 94.707C61.1254 94.3164 61.1254 93.6836 60.7349 93.293L58.942 91.5L60.7349 89.707C61.1254 89.3164 61.1254 88.6836 60.7349 88.293Z"
        fill="black"
      />
      <path
        d="M69.0404 89.707L67.2476 91.5L69.0404 93.293C69.2244 93.4766 69.3217 93.7148 69.3323 93.9551C69.3442 94.2256 69.2469 94.5 69.0404 94.707C68.9702 94.7773 68.8921 94.835 68.8091 94.8799L68.7373 94.915C68.6835 94.9385 68.6278 94.957 68.5713 94.9707C68.4744 94.9951 68.3746 95.0039 68.2755 94.998C68.1542 94.9912 68.0338 94.9619 67.921 94.9111C67.8141 94.8633 67.714 94.7949 67.6262 94.707L64.4191 91.5L67.6262 88.293C68.0167 87.9023 68.6499 87.9023 69.0404 88.293C69.4309 88.6836 69.4309 89.3164 69.0404 89.707Z"
        fill="black"
      />
      <path
        d="M73 88C72.4478 88 72 88.4473 72 89V94C72 94.5527 72.4478 95 73 95H77C77.5522 95 78 94.5527 78 94V89C78 88.4473 77.5522 88 77 88H73Z"
        fill="black"
      />
      <path
        d="M85.3334 88C85.8856 88 86.3334 88.4473 86.3334 89V94C86.3334 94.1221 86.3116 94.2383 86.272 94.3457C86.1956 94.5527 86.0525 94.7285 85.8688 94.8447C85.714 94.9434 85.5303 95 85.3334 95H81.3334C80.781 95 80.3334 94.5527 80.3334 94V89C80.3334 88.4473 80.781 88 81.3334 88H85.3334Z"
        fill="black"
      />
      <path
        d="M89.6666 88C89.1144 88 88.6666 88.4473 88.6666 89V94C88.6666 94.5527 89.1144 95 89.6666 95H93.6666C93.85 95 94.0219 94.9512 94.1696 94.8652C94.4668 94.6914 94.6666 94.3691 94.6666 94V89C94.6666 88.4473 94.219 88 93.6666 88H89.6666Z"
        fill="black"
      />
      <path
        d="M98 95H102C102.552 95 103 94.5527 103 94V89C103 88.7393 102.9 88.502 102.737 88.3242C102.635 88.2129 102.507 88.124 102.364 88.0684C102.251 88.0244 102.129 88 102 88H98C97.4478 88 97 88.4473 97 89V94C97 94.5527 97.4478 95 98 95Z"
        fill="black"
      />
      <path
        d="M106.333 88C105.781 88 105.333 88.4473 105.333 89V94C105.333 94.3936 105.561 94.7344 105.891 94.8975C106.025 94.9629 106.175 95 106.333 95H110.333C110.886 95 111.333 94.5527 111.333 94V89C111.333 88.4473 110.886 88 110.333 88H106.333Z"
        fill="black"
      />
      <path
        d="M114.667 95H118.667C119.219 95 119.667 94.5527 119.667 94V89C119.667 88.4473 119.219 88 118.667 88H114.667C114.114 88 113.667 88.4473 113.667 89V94C113.667 94.1836 113.716 94.3555 113.802 94.5029C113.886 94.6465 114.004 94.7676 114.146 94.8535C114.216 94.8965 114.292 94.9307 114.372 94.9551C114.422 94.9707 114.473 94.9824 114.525 94.9893C114.571 94.9961 114.619 95 114.667 95Z"
        fill="black"
      />
      <path
        d="M123 88C122.448 88 122 88.4473 122 89V94C122 94.5527 122.448 95 123 95H127C127.552 95 128 94.5527 128 94V89C128 88.4473 127.552 88 127 88H123Z"
        fill="black"
      />
      <path
        d="M134.071 88.293C134.461 87.9023 135.094 87.9023 135.485 88.293C135.875 88.6836 135.875 89.3164 135.485 89.707L133.692 91.5L135.485 93.293C135.875 93.6836 135.875 94.3164 135.485 94.707C135.094 95.0977 134.461 95.0977 134.071 94.707L130.864 91.5L134.071 88.293Z"
        fill="black"
      />
      <path
        d="M141.998 91.5L143.79 89.707C144.181 89.3164 144.181 88.6836 143.79 88.293C143.4 87.9023 142.767 87.9023 142.376 88.293L139.169 91.5L142.376 94.707C142.767 95.0977 143.4 95.0977 143.79 94.707C144.181 94.3164 144.181 93.6836 143.79 93.293L141.998 91.5Z"
        fill="black"
      />
      <path
        d="M152.375 89.165C152.342 89.3633 152.249 89.5537 152.096 89.707L150.303 91.5L152.096 93.293C152.487 93.6836 152.487 94.3164 152.096 94.707C151.705 95.0977 151.072 95.0977 150.682 94.707L147.475 91.5L150.682 88.293C151.072 87.9023 151.705 87.9023 152.096 88.293C152.333 88.5303 152.427 88.8574 152.375 89.165Z"
        fill="black"
      />
      <path
        d="M158.609 91.5L160.402 89.707C160.792 89.3164 160.792 88.6836 160.402 88.293C160.011 87.9023 159.378 87.9023 158.987 88.293L155.78 91.5L158.987 94.707C159.378 95.0977 160.011 95.0977 160.402 94.707C160.792 94.3164 160.792 93.6836 160.402 93.293L158.609 91.5Z"
        fill="black"
      />
      <path
        d="M167.293 88.293C167.683 87.9023 168.317 87.9023 168.707 88.293C169.098 88.6836 169.098 89.3164 168.707 89.707L166.914 91.5L168.707 93.293C169.098 93.6836 169.098 94.3164 168.707 94.707C168.317 95.0977 167.683 95.0977 167.293 94.707L164.086 91.5L167.293 88.293Z"
        fill="black"
      />
      <path
        d="M23.3334 105C22.781 105 22.3334 104.553 22.3334 104V99C22.3334 98.4473 22.781 98 23.3334 98H27.3334C27.8856 98 28.3334 98.4473 28.3334 99V104C28.3334 104.553 27.8856 105 27.3334 105H23.3334Z"
        fill="black"
      />
      <path
        d="M31.6666 105H35.6666C36.219 105 36.6666 104.553 36.6666 104V99C36.6666 98.4473 36.219 98 35.6666 98H31.6666C31.4276 98 31.2081 98.084 31.036 98.2236C30.8107 98.4072 30.6666 98.6865 30.6666 99V104C30.6666 104.236 30.7483 104.453 30.8848 104.624C31.068 104.854 31.3501 105 31.6666 105Z"
        fill="black"
      />
      <path
        d="M40 105C39.4478 105 39 104.553 39 104V99C39 98.4473 39.4478 98 40 98H44C44.5522 98 45 98.4473 45 99V104C45 104.553 44.5522 105 44 105H40Z"
        fill="black"
      />
      <path
        d="M51.0151 98.293L47.808 101.5L51.0151 104.707C51.4056 105.098 52.0388 105.098 52.4293 104.707C52.5601 104.576 52.6471 104.418 52.6903 104.252C52.7345 104.081 52.7328 103.9 52.6851 103.73C52.6403 103.57 52.5551 103.419 52.4293 103.293L50.6365 101.5L52.4293 99.707C52.8198 99.3164 52.8198 98.6836 52.4293 98.293C52.0388 97.9023 51.4056 97.9023 51.0151 98.293Z"
        fill="black"
      />
      <path
        d="M60.7349 99.707L58.942 101.5L60.7349 103.293C61.1254 103.684 61.1254 104.316 60.7349 104.707C60.3444 105.098 59.7112 105.098 59.3207 104.707L56.1135 101.5L59.3207 98.293C59.452 98.1611 59.611 98.0742 59.7787 98.0312C59.9513 97.9863 60.1334 97.9893 60.3049 98.0391L60.337 98.0488L60.3918 98.0684L60.4448 98.0908L60.5159 98.127C60.5944 98.1709 60.6681 98.2266 60.7349 98.293C61.1254 98.6836 61.1254 99.3164 60.7349 99.707Z"
        fill="black"
      />
      <path
        d="M65 105H69C69.3108 105 69.5885 104.858 69.772 104.636C69.9144 104.463 70 104.241 70 104V99C70 98.4473 69.5522 98 69 98H65C64.76 98 64.5398 98.084 64.3674 98.2246C64.1432 98.4082 64 98.6875 64 99V104C64 104.2 64.0585 104.386 64.1593 104.542C64.3373 104.818 64.6473 105 65 105Z"
        fill="black"
      />
      <path
        d="M73.3334 105C72.781 105 72.3334 104.553 72.3334 104V99C72.3334 98.4473 72.781 98 73.3334 98H77.3334C77.8856 98 78.3334 98.4473 78.3334 99V104C78.3334 104.553 77.8856 105 77.3334 105H73.3334Z"
        fill="black"
      />
      <path
        d="M81.6666 105H85.6666C86.219 105 86.6666 104.553 86.6666 104V99C86.6666 98.4473 86.219 98 85.6666 98H81.6666C81.438 98 81.2273 98.0771 81.0588 98.2061C80.8204 98.3887 80.6666 98.6758 80.6666 99V104C80.6666 104.553 81.1144 105 81.6666 105Z"
        fill="black"
      />
      <path
        d="M90 105C89.4478 105 89 104.553 89 104V99C89 98.4473 89.4478 98 90 98H94C94.5522 98 95 98.4473 95 99V104C95 104.553 94.5522 105 94 105H90Z"
        fill="black"
      />
      <path
        d="M100.849 98.293L97.6414 101.5L100.849 104.707C101.239 105.098 101.872 105.098 102.263 104.707C102.653 104.316 102.653 103.684 102.263 103.293L100.47 101.5L102.263 99.707C102.653 99.3164 102.653 98.6836 102.263 98.293C101.872 97.9023 101.239 97.9023 100.849 98.293Z"
        fill="black"
      />
      <path
        d="M110.849 99.1572C110.817 99.3584 110.723 99.5518 110.568 99.707L108.775 101.5L110.568 103.293C110.959 103.684 110.959 104.316 110.568 104.707C110.178 105.098 109.545 105.098 109.154 104.707L105.947 101.5L109.154 98.293C109.545 97.9023 110.178 97.9023 110.568 98.293C110.651 98.375 110.715 98.4678 110.763 98.5674C110.81 98.665 110.84 98.7686 110.853 98.874C110.859 98.916 110.861 98.958 110.861 99L110.856 99.0986L110.849 99.1572Z"
        fill="black"
      />
      <path
        d="M117.081 101.5L118.874 99.707C119.264 99.3164 119.264 98.6836 118.874 98.293C118.483 97.9023 117.85 97.9023 117.46 98.293L114.252 101.5L117.46 104.707C117.672 104.919 117.955 105.016 118.232 104.998C118.466 104.982 118.695 104.886 118.874 104.707C119.264 104.316 119.264 103.684 118.874 103.293L117.081 101.5Z"
        fill="black"
      />
      <path
        d="M125.765 98.293C126.156 97.9023 126.789 97.9023 127.179 98.293C127.57 98.6836 127.57 99.3164 127.179 99.707L125.386 101.5L127.179 103.293C127.57 103.684 127.57 104.316 127.179 104.707C126.789 105.098 126.156 105.098 125.765 104.707L122.558 101.5L125.765 98.293Z"
        fill="black"
      />
      <path
        d="M135.485 99.707C135.607 99.585 135.691 99.4395 135.736 99.2852C135.771 99.168 135.784 99.0469 135.775 98.9268C135.758 98.6963 135.661 98.4697 135.485 98.293C135.094 97.9023 134.461 97.9023 134.071 98.293L130.864 101.5L134.071 104.707C134.461 105.098 135.094 105.098 135.485 104.707C135.875 104.316 135.875 103.684 135.485 103.293L133.692 101.5L135.485 99.707Z"
        fill="black"
      />
      <path
        d="M142.376 98.293C142.767 97.9023 143.4 97.9023 143.79 98.293C144.181 98.6836 144.181 99.3164 143.79 99.707L141.998 101.5L143.79 103.293C144.181 103.684 144.181 104.316 143.79 104.707C143.4 105.098 142.767 105.098 142.376 104.707L139.169 101.5L142.376 98.293Z"
        fill="black"
      />
      <path
        d="M150.303 101.5L152.096 99.707C152.487 99.3164 152.487 98.6836 152.096 98.293C151.705 97.9023 151.072 97.9023 150.682 98.293L147.475 101.5L150.682 104.707C151.072 105.098 151.705 105.098 152.096 104.707C152.487 104.316 152.487 103.684 152.096 103.293L150.303 101.5Z"
        fill="black"
      />
      <path
        d="M160.402 99.707L158.609 101.5L160.402 103.293C160.792 103.684 160.792 104.316 160.402 104.707C160.011 105.098 159.378 105.098 158.987 104.707L155.78 101.5L158.987 98.293C159.111 98.1689 159.26 98.084 159.417 98.0391C159.562 97.9971 159.714 97.9883 159.861 98.0137C159.915 98.0225 159.967 98.0361 160.019 98.0537C160.105 98.083 160.187 98.125 160.264 98.1777C160.293 98.1982 160.321 98.2197 160.349 98.2432L160.402 98.293C160.792 98.6836 160.792 99.3164 160.402 99.707Z"
        fill="black"
      />
      <path
        d="M166.914 101.5L168.707 99.707C169.098 99.3164 169.098 98.6836 168.707 98.293C168.317 97.9023 167.683 97.9023 167.293 98.293L164.086 101.5L167.293 104.707C167.683 105.098 168.317 105.098 168.707 104.707C168.923 104.49 169.02 104.2 168.997 103.917C168.978 103.689 168.881 103.467 168.707 103.293L166.914 101.5Z"
        fill="black"
      />
      <path
        d="M14 99C14 98.4473 14.4478 98 15 98H19C19.5522 98 20 98.4473 20 99V104C20 104.553 19.5522 105 19 105H15C14.4478 105 14 104.553 14 104V99Z"
        fill="black"
      />
    </svg>
  )
}

import React from 'react'
import { Container } from './Header.styles'

interface HeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  children: React.ReactNode
  color?: string
  fontSizeMobile?: string
  lineHeightMobile?: string
  fontSize?: string
  lineHeight?: string
  margin?: string
  textAlign?: string
  width?: string
  fontFamily?: string
}

export const Header = ({
  children,
  color,
  fontSizeMobile,
  lineHeightMobile,
  fontSize,
  lineHeight,
  margin,
  textAlign,
  width,
  fontFamily,
}: HeaderProps) => (
  <Container
    color={color}
    fontSizeMobile={fontSizeMobile}
    lineHeightMobile={lineHeightMobile}
    fontSize={fontSize}
    lineHeight={lineHeight}
    margin={margin}
    textAlign={textAlign}
    width={width}
    fontFamily={fontFamily}
  >
    {children}
  </Container>
)

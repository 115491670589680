import React from 'react'
import { IkanoInfoContainer } from '../../styles/ikano.styles'

export const IkanoInfoGraph = () => {
  return (
    <IkanoInfoContainer>
      <svg
        width="216"
        height="73"
        viewBox="0 0 216 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M177.84 6.78561V14.6026C174.277 9.00706 167.954 5.28369 160.735 5.28369C149.604 5.28369 140.578 14.1555 140.578 25.0881C140.578 36.0207 149.604 44.8925 160.742 44.8925C167.954 44.8925 174.284 41.1691 177.847 35.5736V43.3906H215.116V6.78561H177.84Z"
          fill="#2A0062"
        />
        <path
          d="M153.778 29.2376C156.225 29.2376 157.854 27.8195 157.854 25.9054C157.854 24.0821 156.609 23.1041 154.276 22.6501C152.889 22.3916 151.495 21.9375 151.495 20.8268C151.495 20.1003 152.178 19.4786 153.408 19.4786C154.639 19.4786 155.727 20.0444 155.727 20.9176C155.727 21.1342 155.67 21.2809 155.613 21.4625L157.277 22.0283C157.462 21.7 157.597 21.3018 157.597 20.8408C157.597 19.0385 155.912 17.802 153.579 17.802C151.26 17.802 149.745 19.2201 149.745 20.8198C149.745 22.8596 151.282 23.8237 153.671 24.2079C155.45 24.5222 156.004 25.116 156.004 25.9543C156.004 26.8624 155.172 27.54 153.799 27.54C152.526 27.54 151.353 26.9952 151.353 25.8844C151.353 25.7028 151.388 25.5002 151.445 25.2837L149.781 24.613C149.61 24.9274 149.461 25.3745 149.461 25.8355C149.475 27.8893 151.111 29.2376 153.778 29.2376Z"
          fill="white"
        />
        <path
          d="M159.176 19.3739C159.176 19.9747 159.638 20.4287 160.25 20.4287C160.861 20.4287 161.345 19.9747 161.345 19.3739C161.345 18.7731 160.869 18.2981 160.25 18.2981C159.645 18.2981 159.176 18.7731 159.176 19.3739Z"
          fill="white"
        />
        <path
          d="M161.104 21.5532H159.418V29.0698H161.104V21.5532Z"
          fill="white"
        />
        <path
          d="M166.351 27.6378C165.106 27.6378 164.146 26.7855 164.146 25.3465C164.146 23.7607 165.114 22.8177 166.351 22.8177C167.61 22.8177 168.556 23.8376 168.556 25.2347C168.549 26.6388 167.589 27.6378 166.351 27.6378ZM168.478 21.5393V22.5592C168.001 21.8466 167.269 21.3926 166.052 21.3926C164.032 21.3926 162.418 22.9574 162.418 25.3395C162.418 27.6308 163.954 29.0698 165.882 29.0698C167.176 29.0698 168.065 28.525 168.456 27.7216V28.525C168.456 29.9431 167.902 30.942 166.436 30.942C165.377 30.942 164.58 30.4181 164.338 29.3982L162.802 30.0339C163.357 31.6895 164.658 32.3811 166.436 32.3811C168.862 32.3811 170.142 30.7464 170.142 28.4481V21.5323H168.478V21.5393Z"
          fill="white"
        />
        <path
          d="M173.593 24.8714C173.593 23.5442 174.411 22.8107 175.407 22.8107C176.353 22.8107 176.978 23.4813 176.978 24.7247V29.0768H178.664V24.1589C178.664 22.3915 177.533 21.3926 175.976 21.3926C175.03 21.3926 174.141 21.7419 173.565 22.643V21.5463H171.879V29.0629H173.565V24.8575L173.593 24.8714Z"
          fill="white"
        />
        <path
          d="M185.029 25.2558C185.029 23.6002 186.238 22.8178 187.291 22.8178C188.422 22.8178 189.24 23.5094 189.389 24.6201L190.925 24.1521C190.605 22.4057 189.147 21.3857 187.291 21.3857C185.193 21.3857 183.422 22.9505 183.422 25.2628C183.422 27.6449 185.036 29.2307 187.533 29.2307C189.389 29.2307 190.925 28.1898 191.331 26.2128L189.795 25.668C189.624 26.7787 188.977 27.8126 187.462 27.8126C186.253 27.8056 185.029 26.8346 185.029 25.2558Z"
          fill="white"
        />
        <path
          d="M196.055 27.7425C194.846 27.7425 193.779 26.8134 193.779 25.3394C193.779 23.8655 194.839 22.8805 196.055 22.8805C197.186 22.8805 198.353 23.8096 198.353 25.3394C198.331 26.8693 197.172 27.7425 196.055 27.7425ZM198.282 21.5323V22.7547C197.841 21.9025 196.93 21.3716 195.707 21.3716C193.58 21.3716 192.094 23.0272 192.094 25.3394C192.094 27.5609 193.616 29.2165 195.707 29.2165C196.873 29.2165 197.762 28.7485 198.374 27.7984V29.0698H199.988V21.5323H198.282Z"
          fill="white"
        />
        <path
          d="M205.321 29.2237C206.765 29.2237 207.746 28.3505 207.895 26.8416L206.281 26.5272C206.224 27.4004 205.868 27.7986 205.221 27.7986C204.745 27.7986 204.254 27.4843 204.254 26.7578V22.9715H207.163V21.5325H204.254V19.1294H202.575V21.5325H201.039V22.9506H202.575V26.6739C202.561 28.1828 203.45 29.2237 205.321 29.2237Z"
          fill="white"
        />
        <path
          d="M180.164 19.3739C180.164 19.9747 180.626 20.4287 181.238 20.4287C181.85 20.4287 182.333 19.9747 182.333 19.3739C182.333 18.7731 181.857 18.2981 181.238 18.2981C180.626 18.2981 180.164 18.7731 180.164 19.3739Z"
          fill="white"
        />
        <path
          d="M182.092 21.5532H180.406V29.0698H182.092V21.5532Z"
          fill="white"
        />
        <path
          d="M80.5781 24.7837H110.078M110.078 24.7837L103.578 18.2837M110.078 24.7837L103.578 31.2837"
          stroke="#CED4DC"
          strokeLinecap="round"
        />
        <path
          d="M3.35156 62.4946C2.26953 62.4946 1.50977 62.1157 1.07227 61.3579C0.638672 60.6001 0.421875 59.52 0.421875 58.1177C0.421875 56.7153 0.638672 55.6333 1.07227 54.8716C1.50977 54.1099 2.26953 53.729 3.35156 53.729C4.43359 53.729 5.19336 54.1099 5.63086 54.8716C6.06445 55.6333 6.28125 56.7153 6.28125 58.1177C6.28125 59.52 6.0625 60.6001 5.625 61.3579C5.19141 62.1157 4.43359 62.4946 3.35156 62.4946ZM4.33008 60.356C4.48242 59.8403 4.55859 59.0942 4.55859 58.1177C4.55859 57.0942 4.48047 56.3364 4.32422 55.8442C4.17188 55.3521 3.84766 55.106 3.35156 55.106C2.85547 55.106 2.52734 55.3521 2.36719 55.8442C2.20703 56.3364 2.12695 57.0942 2.12695 58.1177C2.12695 59.0942 2.20703 59.8423 2.36719 60.3618C2.52734 60.8774 2.85547 61.1353 3.35156 61.1353C3.84766 61.1353 4.17383 60.8755 4.33008 60.356Z"
          fill="#2A0062"
        />
        <path
          d="M9.11719 62.8813C9.04688 63.1704 8.91602 63.4204 8.72461 63.6313C8.51758 63.8579 8.27344 64.0278 7.99219 64.1411C7.71484 64.2583 7.52344 64.3169 7.41797 64.3169V63.6724C7.75391 63.5786 8 63.4224 8.15625 63.2036C8.31641 62.9849 8.4082 62.6782 8.43164 62.2837H7.41797V60.5376H9.22266V62.0493C9.22266 62.3149 9.1875 62.5923 9.11719 62.8813Z"
          fill="#2A0062"
        />
        <path
          d="M13.3711 62.4946C12.2891 62.4946 11.5293 62.1157 11.0918 61.3579C10.6582 60.6001 10.4414 59.52 10.4414 58.1177C10.4414 56.7153 10.6582 55.6333 11.0918 54.8716C11.5293 54.1099 12.2891 53.729 13.3711 53.729C14.4531 53.729 15.2129 54.1099 15.6504 54.8716C16.084 55.6333 16.3008 56.7153 16.3008 58.1177C16.3008 59.52 16.082 60.6001 15.6445 61.3579C15.2109 62.1157 14.4531 62.4946 13.3711 62.4946ZM14.3496 60.356C14.502 59.8403 14.5781 59.0942 14.5781 58.1177C14.5781 57.0942 14.5 56.3364 14.3438 55.8442C14.1914 55.3521 13.8672 55.106 13.3711 55.106C12.875 55.106 12.5469 55.3521 12.3867 55.8442C12.2266 56.3364 12.1465 57.0942 12.1465 58.1177C12.1465 59.0942 12.2266 59.8423 12.3867 60.3618C12.5469 60.8774 12.875 61.1353 13.3711 61.1353C13.8672 61.1353 14.1934 60.8755 14.3496 60.356Z"
          fill="#2A0062"
        />
        <path
          d="M21.2871 62.2837H19.5762V56.4243H17.5781V55.2876C18.1055 55.2642 18.4746 55.229 18.6855 55.1821C19.0215 55.1079 19.2949 54.9595 19.5059 54.7368C19.6504 54.5845 19.7598 54.3813 19.834 54.1274C19.877 53.9751 19.8984 53.8618 19.8984 53.7876H21.2871V62.2837Z"
          fill="#2A0062"
        />
        <path
          d="M33.6914 58.5103H29.4961V60.731H34.2773V62.2837H27.7324V53.647H34.0664V55.1763H29.4961V57.0103H33.6914V58.5103Z"
          fill="#2A0062"
        />
        <path
          d="M40.793 58.9556V53.647H42.627V58.9556C42.627 59.8735 42.4844 60.5884 42.1992 61.1001C41.668 62.0376 40.6543 62.5063 39.1582 62.5063C37.6621 62.5063 36.6465 62.0376 36.1113 61.1001C35.8262 60.5884 35.6836 59.8735 35.6836 58.9556V53.647H37.5176V58.9556C37.5176 59.5493 37.5879 59.9829 37.7285 60.2563C37.9473 60.7407 38.4238 60.9829 39.1582 60.9829C39.8887 60.9829 40.3633 60.7407 40.582 60.2563C40.7227 59.9829 40.793 59.5493 40.793 58.9556Z"
          fill="#2A0062"
        />
        <path
          d="M48.0234 58.897H46.1602V62.2837H44.3965V53.647H48.6328C49.2383 53.6587 49.7031 53.7329 50.0273 53.8696C50.3555 54.0063 50.6328 54.2075 50.8594 54.4731C51.0469 54.6919 51.1953 54.9341 51.3047 55.1997C51.4141 55.4653 51.4688 55.7681 51.4688 56.1079C51.4688 56.5181 51.3652 56.9224 51.1582 57.3208C50.9512 57.7153 50.6094 57.9946 50.1328 58.1587C50.5312 58.3188 50.8125 58.5474 50.9766 58.8442C51.1445 59.1372 51.2285 59.5864 51.2285 60.1919V60.772C51.2285 61.1665 51.2441 61.4341 51.2754 61.5747C51.3223 61.7974 51.4316 61.9614 51.6035 62.0669V62.2837H49.6172C49.5625 62.0923 49.5234 61.938 49.5 61.8208C49.4531 61.5786 49.4277 61.3306 49.4238 61.0767L49.4121 60.2739C49.4043 59.7231 49.3027 59.356 49.1074 59.1724C48.916 58.9888 48.5547 58.897 48.0234 58.897ZM49.1191 57.3267C49.4785 57.1626 49.6582 56.8384 49.6582 56.354C49.6582 55.8306 49.4844 55.479 49.1367 55.2993C48.9414 55.1978 48.6484 55.147 48.2578 55.147H46.1602V57.4673H48.2051C48.6113 57.4673 48.916 57.4204 49.1191 57.3267Z"
          fill="#2A0062"
        />
        <circle cx="25.5781" cy="24.2837" r="24" fill="#F8F7F6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5781 40.2837C34.4147 40.2837 41.5781 33.1202 41.5781 24.2837C41.5781 15.4471 34.4147 8.28369 25.5781 8.28369C16.7416 8.28369 9.57812 15.4471 9.57812 24.2837C9.57812 33.1202 16.7416 40.2837 25.5781 40.2837Z"
          fill="#EC0000"
        />
        <path
          d="M17.5781 21.0796C17.0258 21.0796 16.5781 21.5273 16.5781 22.0796C16.5781 22.6319 17.0258 23.0796 17.5781 23.0796H18.6173C18.5911 23.4136 18.5781 23.7478 18.5781 24.0797C18.5781 24.4116 18.5911 24.7457 18.6173 25.0796H17.5781C17.0258 25.0796 16.5781 25.5273 16.5781 26.0796C16.5781 26.6319 17.0258 27.0796 17.5781 27.0796H18.9408C19.3319 28.6388 20.047 30.0835 21.1247 31.1499C22.0166 32.0325 23.1512 32.6462 24.5115 32.8234C25.8632 32.9994 27.3657 32.7355 28.9948 31.9889C29.4968 31.7588 29.7173 31.1653 29.4872 30.6632C29.2571 30.1611 28.6635 29.9407 28.1615 30.1708C26.7905 30.7991 25.668 30.9571 24.7697 30.8401C23.8801 30.7243 23.1396 30.3302 22.5315 29.7284C21.8625 29.0663 21.3492 28.1437 21.017 27.0796H24.5781C25.1304 27.0796 25.5781 26.6319 25.5781 26.0796C25.5781 25.5273 25.1304 25.0796 24.5781 25.0796H20.6241C20.5936 24.7509 20.5781 24.4168 20.5781 24.0797C20.5781 23.7426 20.5936 23.4083 20.6241 23.0796H24.5781C25.1304 23.0796 25.5781 22.6319 25.5781 22.0796C25.5781 21.5273 25.1304 21.0796 24.5781 21.0796H21.0171C21.3493 20.0156 21.8626 19.0931 22.5315 18.4312C23.1396 17.8294 23.8801 17.4353 24.7697 17.3194C25.668 17.2025 26.7905 17.3605 28.1615 17.9888C28.6635 18.219 29.2571 17.9985 29.4872 17.4964C29.7173 16.9944 29.4969 16.4008 28.9948 16.1707C27.3658 15.4241 25.8632 15.1602 24.5115 15.3362C23.1512 15.5133 22.0166 16.127 21.1247 17.0096C20.0471 18.0759 19.332 19.5205 18.9409 21.0796H17.5781Z"
          fill="white"
        />
        <rect x="1.42188" y="1" width="48" height="48" rx="10" fill="white" />
        <path
          d="M31.1112 32.2326C29.6756 33.3408 27.8757 34 25.9219 34C22.2835 34 19.179 31.714 17.9664 28.5H17.2261C16.4407 28.5 15.9618 27.636 16.3781 26.97C16.5609 26.6776 16.8813 26.5 17.2261 26.5H17.4801C17.4416 26.172 17.4219 25.8383 17.4219 25.5C17.4219 25.1617 17.4416 24.828 17.4801 24.5H17.2261C16.4407 24.5 15.9618 23.636 16.3781 22.97C16.5609 22.6776 16.8813 22.5 17.2261 22.5H17.9664C19.179 19.286 22.2835 17 25.9219 17C27.2982 17 28.5981 17.3271 29.7481 17.9078C30.2041 18.1381 30.348 18.703 30.1098 19.155C29.8347 19.677 29.1667 19.8356 28.6304 19.5895C27.8059 19.211 26.8885 19 25.9219 19C23.4141 19 21.2381 20.4201 20.1541 22.5H26.6898C27.4425 22.5 27.9254 23.3003 27.5745 23.9662C27.4015 24.2945 27.0609 24.5 26.6898 24.5H19.4983C19.448 24.826 19.4219 25.1599 19.4219 25.5C19.4219 25.8401 19.448 26.174 19.4983 26.5H24.582C25.3347 26.5 25.8176 27.3003 25.4666 27.9662C25.2936 28.2945 24.9531 28.5 24.582 28.5H20.1541C21.2381 30.5799 23.4141 32 25.9219 32C27.3322 32 28.6376 31.5508 29.7029 30.7877C30.1213 30.4881 30.6963 30.4617 31.0914 30.7915C31.5483 31.1728 31.5823 31.8689 31.1112 32.2326Z"
          fill="#EC0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4219 10C17.1376 10 10.4219 16.7157 10.4219 25C10.4219 33.2843 17.1376 40 25.4219 40C33.7061 40 40.4219 33.2843 40.4219 25C40.4219 16.7157 33.7061 10 25.4219 10ZM12.4219 25C12.4219 17.8203 18.2422 12 25.4219 12C32.6016 12 38.4219 17.8203 38.4219 25C38.4219 32.1797 32.6016 38 25.4219 38C18.2422 38 12.4219 32.1797 12.4219 25Z"
          fill="#EC0000"
        />
      </svg>
    </IkanoInfoContainer>
  )
}

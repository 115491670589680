import React from 'react'

export const Euro = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M29.6893 31.2326C28.2537 32.3408 26.4538 33 24.5 33C20.8616 33 17.7572 30.714 16.5445 27.5H15.8042C15.0188 27.5 14.54 26.636 14.9562 25.97C15.139 25.6776 15.4595 25.5 15.8042 25.5H16.0582C16.0198 25.172 16 24.8383 16 24.5C16 24.1617 16.0198 23.828 16.0582 23.5H15.8042C15.0188 23.5 14.54 22.636 14.9562 21.97C15.139 21.6776 15.4595 21.5 15.8042 21.5H16.5445C17.7572 18.286 20.8616 16 24.5 16C25.8763 16 27.1762 16.3271 28.3262 16.9078C28.7822 17.1381 28.9261 17.703 28.6879 18.155C28.4128 18.677 27.7448 18.8356 27.2085 18.5895C26.384 18.211 25.4666 18 24.5 18C21.9923 18 19.8162 19.4201 18.7322 21.5H25.2679C26.0207 21.5 26.5035 22.3003 26.1526 22.9662C25.9796 23.2945 25.639 23.5 25.2679 23.5H18.0764C18.0261 23.826 18 24.1599 18 24.5C18 24.8401 18.0261 25.174 18.0764 25.5H23.1601C23.9128 25.5 24.3957 26.3003 24.0448 26.9662C23.8718 27.2945 23.5312 27.5 23.1601 27.5H18.7322C19.8162 29.5799 21.9923 31 24.5 31C25.9103 31 27.2158 30.5508 28.281 29.7877C28.6994 29.4881 29.2744 29.4617 29.6695 29.7915C30.1264 30.1728 30.1604 30.8689 29.6893 31.2326Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 9C15.7157 9 9 15.7157 9 24C9 32.2843 15.7157 39 24 39C32.2843 39 39 32.2843 39 24C39 15.7157 32.2843 9 24 9ZM11 24C11 16.8203 16.8203 11 24 11C31.1797 11 37 16.8203 37 24C37 31.1797 31.1797 37 24 37C16.8203 37 11 31.1797 11 24Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

import React from 'react'

export const Letter = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M21 17C21 16.4477 20.5523 16 20 16H14C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18H20C20.5523 18 21 17.5523 21 17Z"
        className="document--primary--fill"
      />
      <path
        d="M34 21C34 20.4477 33.5523 20 33 20H14C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22H33C33.5523 22 34 21.5523 34 21Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7075 7.55218L16.7671 11H11C9.89543 11 9 11.8954 9 13V17.7962L7.34398 19.2452C7.13323 19.4286 7 19.6987 7 20V39C7 40.6569 8.34315 42 10 42H38C39.6569 42 41 40.6569 41 39V20C41 19.6999 40.8678 19.4307 40.6585 19.2474L39 17.7962V13C39 11.8954 38.1046 11 37 11H31.2329L27.2925 7.55218C25.4074 5.90269 22.5926 5.90269 20.7075 7.55218ZM28.1957 11L25.9755 9.05734C24.8444 8.06764 23.1556 8.06764 22.0245 9.05733L19.8043 11H28.1957ZM39 22.5L30.9075 29.5135L39 38.5052V22.5ZM29.3959 30.8236L26.6197 33.2296C25.1163 34.5326 22.8837 34.5326 21.3803 33.2296L18.6041 30.8236L10.3454 40H37.6546L29.3959 30.8236ZM9 38.5052L17.0925 29.5135L9 22.5V38.5052ZM11 21.5867V13L37 13V21.5867L25.3099 31.7182C24.5581 32.3697 23.4419 32.3697 22.6901 31.7182L11 21.5867Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

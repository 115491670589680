import React from 'react'

export const SessionExpiredErrorIkano = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="194"
      height="194"
      viewBox="0 0 194 194"
      fill="none"
    >
      <path
        d="M26.5739 89.3711C26.5739 86.6097 28.8124 84.3711 31.5739 84.3711H162.426C165.188 84.3711 167.426 86.6097 167.426 89.3711V108.928H26.5739V89.3711Z"
        fill="#5D4FB2"
      />
      <path
        d="M40.0105 93.6374C40.0105 92.1021 41.2551 90.8574 42.7905 90.8574H144.26C145.795 90.8574 147.04 92.1021 147.04 93.6374C147.04 95.1727 145.795 96.4174 144.26 96.4174H42.7905C41.2551 96.4174 40.0105 95.1727 40.0105 93.6374Z"
        fill="white"
      />
      <circle cx="30.7437" cy="93.6365" r="1.85332" fill="white" />
      <circle cx="36.3038" cy="93.6365" r="1.85332" fill="white" />
      <circle cx="150.746" cy="93.6365" r="1.85332" fill="white" />
      <path
        d="M161.866 91.5524C161.866 91.1686 162.178 90.8574 162.561 90.8574C162.945 90.8574 163.256 91.1686 163.256 91.5524C163.256 91.9363 162.945 92.2474 162.561 92.2474C162.178 92.2474 161.866 91.9363 161.866 91.5524Z"
        fill="white"
      />
      <path
        d="M161.866 93.6374C161.866 93.2536 162.178 92.9424 162.561 92.9424C162.945 92.9424 163.256 93.2536 163.256 93.6374C163.256 94.0212 162.945 94.3324 162.561 94.3324C162.178 94.3324 161.866 94.0212 161.866 93.6374Z"
        fill="white"
      />
      <path
        d="M161.866 95.7224C161.866 95.3386 162.178 95.0274 162.561 95.0274C162.945 95.0274 163.256 95.3386 163.256 95.7224C163.256 96.1062 162.945 96.4174 162.561 96.4174C162.178 96.4174 161.866 96.1062 161.866 95.7224Z"
        fill="white"
      />
      <path
        d="M28.8904 106.977C28.8904 104.215 31.129 101.977 33.8904 101.977H160.11C162.871 101.977 165.11 104.215 165.11 106.977V108.927H28.8904V106.977Z"
        fill="white"
      />
      <circle
        cx="42.7904"
        cy="93.6377"
        r="4.09664"
        stroke="#EC0000"
        strokeWidth="2"
      />
      <circle
        cx="96.7684"
        cy="96.6489"
        r="76.1079"
        fill="#FCFBFD"
        stroke="#DCDBE2"
        strokeWidth="3"
      />
      <rect
        x="95.1465"
        y="28.3086"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        fill="#DCDBE2"
      />
      <rect
        x="95.1465"
        y="152.018"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        fill="#DCDBE2"
      />
      <rect
        x="165.341"
        y="94.7969"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(90 165.341 94.7969)"
        fill="#DCDBE2"
      />
      <rect
        x="41.6318"
        y="94.7969"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(90 41.6318 94.7969)"
        fill="#DCDBE2"
      />
      <rect
        x="144.014"
        y="47.0137"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(45 144.014 47.0137)"
        fill="#DCDBE2"
      />
      <rect
        x="56.5384"
        y="134.49"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(45 56.5384 134.49)"
        fill="#DCDBE2"
      />
      <rect
        x="146.635"
        y="143.664"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(135 146.635 143.664)"
        fill="#DCDBE2"
      />
      <rect
        x="59.1594"
        y="56.1875"
        width="3.70665"
        height="12.9733"
        rx="1.85332"
        transform="rotate(135 59.1594 56.1875)"
        fill="#DCDBE2"
      />
      <path
        d="M163.189 127.708C160.686 123.38 154.438 123.38 151.935 127.708L127.377 170.174C124.871 174.507 127.998 179.928 133.004 179.928H182.121C187.126 179.928 190.253 174.507 187.747 170.174L163.189 127.708Z"
        fill="white"
        stroke="#EC0000"
        strokeWidth="3"
      />
      <path
        d="M154.273 143.987C154.18 142.011 155.757 140.357 157.735 140.357C159.714 140.357 161.291 142.011 161.198 143.987L160.34 162.101C160.274 163.491 159.127 164.585 157.735 164.585C156.344 164.585 155.197 163.491 155.131 162.101L154.273 143.987Z"
        fill="#EC0000"
      />
      <circle cx="157.735" cy="169.602" r="2.59577" fill="#EC0000" />
      <path
        d="M97 96.4176V55.6445"
        stroke="#DCDBE2"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M97 96.4171L112.29 111.707"
        stroke="#DCDBE2"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <circle
        cx="97.2316"
        cy="96.6489"
        r="5.14496"
        fill="white"
        stroke="#DCDBE2"
        strokeWidth="5"
      />
    </svg>
  )
}

import React, { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import {
  Bar,
  BarContainer,
  Container,
  Counter,
  InfoMessage,
} from './ProgressBar.styles'

interface ProgresBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  time: number //in seconds
  secondChance?: boolean
  showCounter?: boolean
  border?: string
  height?: string
  margin?: string
  borderRadius?: string
  background?: string
  barFill?: string
  padding?: string
  longerWait?: string
  counterMargin?: string
  counterPadding?: string
  counterFontSize?: string
  counterColor?: string
  infoMessageTextAlign?: string
  infoMessageFontSize?: string
  infoMessageMargin?: string
}

export const ProgressBar = ({
  time,
  secondChance,
  showCounter,
  border,
  height,
  margin,
  borderRadius,
  background,
  barFill,
  padding,
  longerWait,
  counterMargin,
  counterPadding,
  counterColor,
  counterFontSize,
  infoMessageTextAlign,
  infoMessageFontSize,
  infoMessageMargin,
}: ProgresBarProps) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [countEnd, setCountEnd] = useState(false)
  const [chanceUsed, setChanceUser] = useState(false)

  function handleTimeCount() {
    setCurrentTime(currentTime + 1)
    setCurrentProgress((currentTime / time) * 100)

    if (currentTime === time) {
      if (secondChance && !chanceUsed) {
        setCurrentTime(0)
        setCurrentProgress(0)
        setChanceUser(true)
      } else {
        setCountEnd(true)
      }
    }
  }

  function format(time) {
    let seconds: number | string = time % 60
    let minutes: number | string = Math.floor(time / 60)
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes
    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds
    return minutes + ':' + seconds
  }

  useInterval(
    () => {
      handleTimeCount()
    },
    !countEnd ? 1000 : null
  )

  return (
    <Container margin={margin} padding={padding}>
      {showCounter ? (
        <Counter
          margin={counterMargin}
          padding={counterPadding}
          fontSize={counterFontSize}
          color={counterColor}
        >
          {format(time - currentTime)}
        </Counter>
      ) : (
        <></>
      )}
      <BarContainer
        border={border}
        height={height}
        borderRadius={borderRadius}
        background={background}
      >
        <Bar width={`${currentProgress}%`} barFill={barFill} />
      </BarContainer>
      {chanceUsed ? (
        <InfoMessage
          fontSize={infoMessageFontSize}
          textAlign={infoMessageTextAlign}
          margin={infoMessageMargin}
        >
          {longerWait
            ? longerWait
            : 'It takes longer than usual, please wait couple more minutes'}
        </InfoMessage>
      ) : (
        <></>
      )}
    </Container>
  )
}

import React from 'react'

export const AlreadyUsedErrorIkano = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="184"
      height="128"
      viewBox="0 0 184 128"
      fill="none"
    >
      <rect
        x="1.5"
        y="1.5"
        width="181"
        height="113"
        rx="8.5"
        fill="white"
        stroke="#DCDBE2"
        strokeWidth="3"
      />
      <path
        d="M1.06189 120C1.55399 123.946 4.92038 127 9 127H175C179.08 127 182.446 123.946 182.938 120H1.06189Z"
        fill="#DCDBE2"
        stroke="#DCDBE2"
        strokeWidth="2"
      />
      <path
        d="M112.375 114L9.99998 114C6.134 114 3 110.866 3 107C3 107 3.5 109 5 109C7 109 9.5 109 9.5 109L112.375 109L112.375 114Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 3L174 3C177.866 3 181 6.13401 181 10C181 10 180.502 7.99999 179.007 7.99999C177.014 7.99999 174.522 7.99999 174.522 7.99999L72 8V3Z"
        fill="#DCDBE2"
      />
      <path
        d="M112 3L10 3C6.13401 3 3 6.13401 3 10C3 10 3.49828 7.99999 4.99314 7.99999C6.98628 7.99999 9.47771 7.99999 9.47771 7.99999L112 8V3Z"
        fill="#DCDBE2"
      />
      <path
        d="M72 114L174 114C177.866 114 181 110.866 181 107C181 107 180.502 109 179.007 109C177.014 109 174.522 109 174.522 109L72 109L72 114Z"
        fill="#DCDBE2"
      />
      <circle cx="92" cy="4" r="1.5" fill="white" stroke="black" />
      <path d="M3 8H181V15H3V8Z" fill="#F6F5FA" />
      <path d="M12 20H37V27H12V20Z" fill="#FCFBFD" />
      <path d="M73 20H98V27H73V20Z" fill="#FCFBFD" />
      <path d="M110 20H135V27H110V20Z" fill="#F6F5FA" />
      <path d="M147 20H172V27H147V20Z" fill="#FCFBFD" />
      <path d="M98 32H172V71H98V32Z" fill="#FCFBFD" />
      <path d="M12 83H172V104H12V83Z" fill="#F6F5FA" />
      <path d="M55 32H86V71H55V32Z" fill="#FCFBFD" />
      <path d="M12 32H43V71H12V32Z" fill="#F6F5FA" />
      <path
        d="M53 12C53 10.8954 53.8954 10 55 10H76C77.1046 10 78 10.8954 78 12V15H53V12Z"
        fill="white"
      />
      <rect x="7" y="12" width="17" height="2" rx="1" fill="white" />
      <rect x="32" y="12" width="18" height="2" rx="1" fill="white" />
      <path
        d="M28 11V14"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <rect
        x="56.6592"
        y="11.75"
        width="17.3412"
        height="2.25"
        rx="1.125"
        fill="#F6F5FA"
      />
      <path
        d="M98.8527 35.7335C96.3498 31.4055 90.1019 31.4055 87.599 35.7335L63.0407 78.199C60.5346 82.5323 63.6617 87.953 68.6675 87.953H117.784C122.79 87.953 125.917 82.5323 123.411 78.199L98.8527 35.7335Z"
        fill="white"
        stroke="#EC0000"
        strokeWidth="3"
      />
      <path
        d="M89.9371 52.0128C89.8435 50.0364 91.4206 48.3828 93.3992 48.3828C95.3778 48.3828 96.955 50.0364 96.8613 52.0128L96.0033 70.1263C95.9375 71.5166 94.7911 72.61 93.3992 72.61C92.0073 72.61 90.8609 71.5166 90.7951 70.1263L89.9371 52.0128Z"
        fill="#EC0000"
      />
      <circle cx="93.399" cy="77.627" r="2.59577" fill="#EC0000" />
    </svg>
  )
}

import React from 'react'
import { IconContainer } from '../../styles/ikano.styles'

interface IconProps {
  fillColor: string
  backgroundColor: string
}

export const CompletePageIcon = (props: IconProps) => {
  return (
    <IconContainer>
      <svg
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="40" cy="40.2836" r="40" fill={props.backgroundColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9999 66.9503C54.7275 66.9503 66.6666 55.0112 66.6666 40.2836C66.6666 25.556 54.7275 13.6169 39.9999 13.6169C25.2723 13.6169 13.3333 25.556 13.3333 40.2836C13.3333 55.0112 25.2723 66.9503 39.9999 66.9503Z"
          fill={props.backgroundColor}
          stroke={props.fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M30 41.1169L36.6667 47.7835L50 34.4502"
          stroke={props.fillColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </IconContainer>
  )
}

import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  SelectInput,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../components/App'
import idin from '../assets/idin_logo.png'
import { ButtonText, SelectHolder, Label } from '../styles/generic.styles'
import {
  ContentContainerBottomLeft,
  ContentContainerBottomUpload,
  Margined,
} from '../styles/layout.styles'
import { CenterLiner } from '../styles/ikano.styles'

const banksList = [
  {
    country: 'Nederland',
    issuers: [
      {
        name: 'Currence Issuer',
        id: 'CURRNL2A',
      },
      {
        name: 'Rabobank iDIN issuer simulatie',
        id: 'RABONL2U',
      },
    ],
  },
]

export const BankSelectionPage = observer(() => {
  const store = useContext(StoreContext)

  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { bankSelectionPage: trans } = store.TranslationsState.translations

  const banksOptions = banksList
    .flatMap((country) => country.issuers)
    .map((item) => ({ value: item.id, label: item.name }))

  const [bank, setBank] = useState(null)
  const [bankId, setBankId] = useState(-1)
  const [loading, setLoading] = useState(false)

  const handleProceed = async () => {
    setLoading(true)

    setTimeout(() => {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }, 2000)
  }

  const bankChangeHandler = (e) => {
    const index = banksOptions.findIndex((item) => item.value === e.value)
    setBank(e)
    setBankId(index)
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {loading ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          height="68px"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          <Margined margin="0 0 50px 0">
            <CenterLiner>
              <img width="100" src={idin} />
            </CenterLiner>
          </Margined>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>

          <SelectHolder>
            <Label>{trans.label}</Label>
            <SelectInput
              options={banksOptions}
              value={banksOptions[bankId]}
              onChange={(e) => bankChangeHandler(e)}
              {...theme.selectInput}
            />
          </SelectHolder>

          <ContentContainerBottomUpload>
            <ContentContainerBottomLeft />
            <Button
              onClick={() => handleProceed()}
              {...theme[!bank ? 'buttonDisabled' : 'button']}
              width="164px"
              paddingMobile="14px 40px"
            >
              <>
                <ButtonText>{trans.proceed}</ButtonText>
              </>
            </Button>
          </ContentContainerBottomUpload>
        </>
      )}
    </ContentContainer>
  )
})

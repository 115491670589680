import React from 'react'

export const Camera = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M29 25C29 27.7614 26.7614 30 24 30C21.2386 30 19 27.7614 19 25C19 22.2386 21.2386 20 24 20C26.7614 20 29 22.2386 29 25Z"
        className="document--tenary--fill"
      />
      <path
        d="M13 21C14.1046 21 15 20.1046 15 19C15 17.8954 14.1046 17 13 17C11.8954 17 11 17.8954 11 19C11 20.1046 11.8954 21 13 21Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 25C31 28.866 27.866 32 24 32C20.134 32 17 28.866 17 25C17 21.134 20.134 18 24 18C27.866 18 31 21.134 31 25ZM24 30C26.7614 30 29 27.7614 29 25C29 22.2386 26.7614 20 24 20C21.2386 20 19 22.2386 19 25C19 27.7614 21.2386 30 24 30Z"
        className="document--primary--fill"
      />
      <path
        d="M33 17C32.4477 17 32 17.4477 32 18C32 18.5523 32.4477 19 33 19H36C36.5523 19 37 18.5523 37 18C37 17.4477 36.5523 17 36 17H33Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 10.8787C19.2697 10.3161 20.0328 10 20.8284 10H27.1716C27.9672 10 28.7303 10.3161 29.2929 10.8787L31.1213 12.7071C31.3089 12.8946 31.5632 13 31.8284 13H38C39.6569 13 41 14.3431 41 16V34C41 35.6569 39.6569 37 38 37H10C8.34315 37 7 35.6569 7 34V16C7 14.3431 8.34315 13 10 13C10 12.4477 10.4477 12 11 12H15C15.5523 12 16 12.4477 16 13H16.1716C16.4368 13 16.6911 12.8946 16.8787 12.7071L18.7071 10.8787ZM20.8284 12C20.5632 12 20.3089 12.1054 20.1213 12.2929L18.2929 14.1213C17.7303 14.6839 16.9672 15 16.1716 15H10C9.44772 15 9 15.4477 9 16V34C9 34.5523 9.44772 35 10 35H38C38.5523 35 39 34.5523 39 34V16C39 15.4477 38.5523 15 38 15H31.8284C31.0328 15 30.2697 14.6839 29.7071 14.1213L27.8787 12.2929C27.6911 12.1054 27.4368 12 27.1716 12H20.8284Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

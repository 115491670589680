import styled from 'styled-components'

interface ContainerProps {
  bgColor?: string
  textColor?: string
  borderColor?: string
  headerColor?: string
  primaryColor?: string
  primaryReverseColor?: string
  secondaryColor?: string
  mainHoverColor?: string
  mainHoverReverseColor?: string
  listHoverColor?: string
  disabledColor?: string
  errorColor?: string
  borderRadius?: string
  buttonBorderRadius?: string
  padding?: string
  minWidth?: string
  width?: string
  border?: string
  boxShadow?: string
  margin?: string
  paddingMobile?: string
  marginMobile?: string
  touchAction?: string
}

const defaultProps = {
  bgColor: '#FFF',
  textColor: '#000',
  borderColor: '#E5E5E5',
  headerColor: '#2E3F5B',
  primaryColor: '#2E3F5B',
  primaryReverseColor: '#FFF',
  secondaryColor: '586374',
  mainHoverColor: '#7CCDEE',
  mainHoverReverseColor: '#FFF',
  listHoverColor: '#F5F5F5',
  disabledColor: '#CED4DA',
  errorColor: '#BB0000',
  borderRadius: '25px',
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.bgColor || defaultProps.bgColor};
  color: ${(props) => props.textColor || defaultProps.textColor};
  padding: ${(props) => props.padding || '38px 25px'};
  border-radius: ${(props) => props.borderRadius || '8px'};
  min-width: ${(props) => props.minWidth || 'auto'};
  width: ${(props) => props.width || '100%'};
  box-sizing: border-box;
  margin: ${(props) => props.margin || '0 auto'};
  border: ${(props) => props.border || 'none'};
  box-shadow: ${(props) => props.boxShadow || 'none'};
  touch-action: ${(props) => props.touchAction || 'auto'};

  @media (max-width: 980px) {
    width: calc(100% - 40px);
    min-width: auto;
    margin: 10px 20px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: auto;
    margin: ${(props) => props.marginMobile || '10px'};
    padding: ${(props) => props.paddingMobile || '10px'};
  }

  input {
    background-color: ${(props) => props.bgColor || defaultProps.bgColor};
    color: ${(props) => props.textColor || defaultProps.textColor};
    border-color: ${(props) => props.borderColor || defaultProps.borderColor};
    border-radius: ${(props) =>
      props.borderRadius || defaultProps.borderRadius};

    &:focus,
    &:active {
      border-color: ${(props) =>
        props.primaryColor || defaultProps.primaryColor};
    }

    &:disabled,
    &[disabled] {
      color: ${(props) => props.disabledColor || defaultProps.disabledColor};
    }
  }

  button {
    border-radius: ${(props) =>
      props.buttonBorderRadius ||
      props.borderRadius ||
      defaultProps.borderRadius};

    &.primary {
      color: ${(props) =>
        props.primaryReverseColor || defaultProps.primaryReverseColor};
      background-color: ${(props) =>
        props.primaryColor || defaultProps.primaryColor};

      &:hover {
        color: ${(props) =>
          props.mainHoverReverseColor || defaultProps.mainHoverReverseColor};
        background-color: ${(props) =>
          props.mainHoverColor || defaultProps.mainHoverColor};
      }
    }

    &.secondary {
      color: ${(props) => props.secondaryColor || defaultProps.secondaryColor};
      background-color: ${(props) =>
        props.primaryColor || defaultProps.primaryColor};

      &:hover {
        color: ${(props) =>
          props.mainHoverColor || defaultProps.mainHoverColor};
      }
    }
  }

  a {
    color: ${(props) => props.primaryColor || defaultProps.primaryColor};
    &:hover {
      color: ${(props) => props.mainHoverColor || defaultProps.mainHoverColor};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    color: ${(props) => props.headerColor || defaultProps.headerColor};
  }

  ol,
  ul {
    color: ${(props) => props.textColor || defaultProps.textColor};
    border-color: ${(props) => props.borderColor || defaultProps.borderColor};
  }

  li {
    color: ${(props) => props.textColor || defaultProps.textColor};
  }

  div,
  p {
    color: ${(props) => props.textColor || defaultProps.textColor};
  }

  .error {
    color: ${(props) => props.errorColor || defaultProps.errorColor};
  }
`

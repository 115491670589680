import { forceRedirect } from './forceRedirect'

export const insertCloseButton = (
  apiUrl: string,
  eidError: string,
  trans: { close: string; retry: string }
) => {
  const targetContainer = document.querySelectorAll(
    '.eid-notification.eid-notification-fullscreen.eid-notification-warning-error .eid-equal-container > div'
  )[1]

  if (targetContainer) {
    const newButton = document.createElement('button')
    newButton.textContent = trans.close
    newButton.classList.add('eid-button-close')
    newButton.classList.add('eid-button')
    newButton.classList.add('eid-button-primary')
    newButton.classList.add('eid-call-to-action')
    newButton.addEventListener('click', () => {
      forceRedirect(
        `${apiUrl}/electronic-id/redirect-from-video?result=fail&error=${eidError}`
      )
    })

    const aElement = targetContainer.querySelector(
      '.eid-button.eid-button-primary.eid-call-to-action'
    )

    if (aElement) {
      aElement.insertAdjacentElement('afterend', newButton)
    }
  }
}

export const handleActionButtonsInject = (
  trans: { close: string; retry: string },
  closeOnClick: () => void,
  retryOnClick: () => void
) => {
  const targetContainer = document.querySelectorAll(
    '.eid-notification.eid-notification-fullscreen.eid-notification-warning-error .eid-equal-container > div'
  )[1]

  const targetContainer2 = document.querySelector(
    '.eid-row.eid-rows.eid-requirements'
  )

  const buttonContainer = document.createElement('div')
  buttonContainer.classList.add('eid-button-container')

  const closeButton = document.createElement('button')
  closeButton.textContent = trans.close
  closeButton.classList.add('eid-button-close')
  closeButton.classList.add('eid-button')
  closeButton.classList.add('eid-button-primary')
  closeButton.classList.add('eid-call-to-action')
  closeButton.addEventListener('click', closeOnClick)

  const retryButton = document.createElement('button')
  retryButton.textContent = trans.retry
  retryButton.classList.add('eid-button-retry')
  retryButton.classList.add('eid-button')
  retryButton.classList.add('eid-button-primary')
  retryButton.classList.add('eid-call-to-action')
  retryButton.addEventListener('click', retryOnClick)

  buttonContainer.appendChild(retryButton)
  buttonContainer.appendChild(closeButton)

  if (targetContainer) {
    const anchorElement = targetContainer.querySelector(
      '.eid-button.eid-button-primary.eid-call-to-action'
    )

    if (anchorElement) {
      anchorElement.insertAdjacentElement('afterend', buttonContainer)
      anchorElement.remove()
    }
  } else if (targetContainer2) {
    const anchorElement = targetContainer2.querySelector(
      '.eid-a.eid-call-to-action-3'
    )

    if (anchorElement) {
      anchorElement.insertAdjacentElement('afterend', buttonContainer)
      anchorElement.remove()
    }
  }
}

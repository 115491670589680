import styled from 'styled-components'

interface ContainerProps {
  width?: string
  columnGap?: string
  marginTop?: string
  marginBottom?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-otp-container',
})<ContainerProps>`
  display: flex;
  width: ${(props) => (props.width ? props.width : '384px')};
  column-gap: ${(props) => (props.columnGap ? props.columnGap : '10px')};
  margin: 0 auto;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '40px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '40px'};
`

interface DigitInputProps {
  height?: string
  border?: string
  borderRadius?: string
  fontSize?: string
  focusColor?: string
  bgColor?: string
  focusBgColor?: string
  fontColor?: string
}

export const DigitInput = styled.input<DigitInputProps>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '60px')};
  border: ${(props) => (props.border ? props.border : '1px solid #ccc')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '5px'};
  text-align: center;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '32px')};
  font-weight: 500;
  line-height: 1;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};
  color: ${(props) => (props.fontColor ? props.fontColor : 'black')};

  &:focus {
    outline: 2px solid
      ${(props) => (props.focusColor ? props.focusColor : 'orange')};
    background-color: ${(props) =>
      props.focusBgColor ? props.focusBgColor : '#FCFCFC'};
  }
`

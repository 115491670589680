import React from 'react'

interface DotProps {
  color: string
  thickness?: string
}

export const Dot = ({ color, thickness }: DotProps) => {
  return (
    <svg
      fill={color}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="12" fill={color} />
    </svg>
  )
}

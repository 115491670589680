import React from 'react'

interface AtSignProps {
  color: string
  thickness?: string
}

export const AtSign = ({ color, thickness }: AtSignProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03491 12.0867C3.47113 7.07306 7.8902 3 12.8523 3C15.7196 3 17.8266 3.9395 19.1651 5.39621C20.5022 6.85138 21.1747 8.93345 20.9611 11.4231C20.5044 14.9552 19.0656 16.0367 18.2488 16.214C17.8183 16.3075 17.4767 16.1935 17.2699 16.0157C17.0753 15.8483 16.9301 15.5668 16.9903 15.1395L17.9912 7.63216C18.0642 7.08472 17.6796 6.58176 17.1322 6.50877C16.5847 6.43578 16.0818 6.8204 16.0088 7.36784L15.9567 7.75854C15.4146 7.10868 14.7082 6.5939 13.8497 6.28585C10.9247 5.23623 7.78714 7.14862 6.6724 10.1682C5.55654 13.1908 6.71512 16.6608 9.65021 17.714C11.6179 18.4201 13.6817 17.7858 15.1612 16.3758C15.328 16.8251 15.6029 17.22 15.9658 17.5321C16.6868 18.1521 17.6813 18.3838 18.6731 18.1685C20.6993 17.7287 22.4458 15.5789 22.9475 11.657C22.9492 11.6438 22.9506 11.6306 22.9518 11.6174C23.2107 8.66121 22.4226 5.9854 20.6378 4.04299C18.8509 2.09828 16.1674 1 12.8523 1C6.83759 1 1.56725 5.88148 1.04244 11.9133C0.512091 18.0087 5.01427 23 11.1008 23C13.002 23 14.1991 22.8445 15.9667 22.0972C16.4754 21.8821 16.7134 21.2954 16.4984 20.7867C16.2833 20.278 15.6966 20.04 15.1879 20.2551C13.7302 20.8714 12.8133 21 11.1008 21C6.21043 21 2.60422 17.0367 3.03491 12.0867ZM8.54863 10.8608C9.38014 8.60848 11.5269 7.5772 13.1742 8.16832C14.8114 8.75582 15.7817 10.8897 14.9513 13.139C14.1198 15.3914 11.973 16.4227 10.3257 15.8316C8.68852 15.2441 7.71826 13.1102 8.54863 10.8608Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}

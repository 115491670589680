import React from 'react'

export const Passport = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 22C31 18.134 27.866 15 24 15C20.134 15 17 18.134 17 22C17 25.866 20.134 29 24 29C27.866 29 31 25.866 31 22ZM28.9 21C28.7007 20.0182 28.2137 19.1409 27.5309 18.4598C27.5166 18.5219 27.5023 18.5851 27.488 18.6493C27.322 19.3931 27.1621 20.2306 27.0726 21H28.9ZM25.5361 18.2136C25.6093 17.8855 25.6821 17.5812 25.7486 17.3143C25.2043 17.1111 24.6151 17 24 17C23.3824 17 22.7909 17.112 22.2448 17.3167C22.3213 17.6321 22.4066 18.0011 22.4915 18.4024C22.6545 19.1735 22.823 20.0956 22.9195 21H25.061C25.1576 20.0453 25.3526 19.0356 25.5361 18.2136ZM20.9068 21H19.1C19.2984 20.0228 19.7817 19.1491 20.4595 18.4694C20.4845 18.5816 20.5096 18.6974 20.5347 18.8161C20.6808 19.5069 20.8193 20.2711 20.9068 21ZM20.9758 23C20.914 23.8217 20.7451 24.7745 20.5606 25.6292C19.8298 24.9363 19.3081 24.0251 19.1 23H20.9758ZM22.363 26.7259C22.3962 26.5854 22.43 26.4384 22.4639 26.2864C22.6799 25.319 22.9117 24.0918 22.9805 23H25.0347C25.1144 24.0574 25.3153 25.1836 25.5085 26.0976C25.5549 26.3167 25.6013 26.5262 25.6464 26.7226C25.1309 26.9023 24.5768 27 24 27C23.4267 27 22.8759 26.9035 22.363 26.7259ZM28.9 23C28.693 24.0195 28.1759 24.9263 27.4514 25.6177C27.2767 24.7833 27.1149 23.8481 27.0411 23H28.9Z"
        className="document--primary--fill"
      />
      <path
        d="M18 37C18 36.4477 18.4477 36 19 36L29 36C29.5523 36 30 36.4477 30 37C30 37.5523 29.5523 38 29 38L19 38C18.4477 38 18 37.5523 18 37Z"
        className="document--primary--fill"
      />
      <path
        d="M21 32C20.4477 32 20 32.4477 20 33C20 33.5523 20.4477 34 21 34H27C27.5523 34 28 33.5523 28 33C28 32.4477 27.5523 32 27 32H21Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 8C34.6569 8 36 9.34315 36 11L36 39C36 40.6569 34.6569 42 33 42H15C13.3431 42 12 40.6569 12 39V11C12 9.34315 13.3431 8 15 8H33ZM34 11C34 10.4477 33.5523 10 33 10L15 10C14.4477 10 14 10.4477 14 11L14 39C14 39.5523 14.4477 40 15 40H33C33.5523 40 34 39.5523 34 39L34 11Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

export const EID_ERROR_CODES_RETRY = [
  'Connection.Lost',
  'Connection.Error',
  'Camera.NotFound',
  'Camera.Unavailable',
  'CameraPermission.Denied',
  'CameraResolution.TooLow',
  'Microphone.NotFound',
  'MicrophonePermission.Denied',
  'VideoID.Error',
  'Device.NotSupported',
  'User.Aborted',
  'Constraints.NotSatisfied',
  'MediaSource.Error',
  'Document.Illegible',
  'Browser.NotSupported',
  'Device.NotSupported',
  'IdType.Invalid',
  'IdType.Unauthorized',
  'VideoID.NotSupported',
  'CameraResolution.TooLow',
  'CameraRequirements.NotSatisfied',
  'Image.Blurred',
  'WebRTC.NotAvailable',
  'Recording.Error',
  'Bandwidth.TooLow',
  'UserConnection.Error',
  'Luminance.TooLow',
  'Luminance.TooHigh',
  'Document.NotAllowed',
  'DocumentSides.Mismatch',
  'DocumentSidesMatch.NotPossible',
  'Document.WrongSide',
  'Document.Glared',
  'Document.Expired',
  'Document.Tampered',
  'Hologram.NotDetected',
  'Surface.NotReflective',
  'BWCopy.Detected',
  'Photo.NotDetected',
  'MaxLength.Exceeded',
  'InactiveUser.Aborted',
  'Liveness.NotDetected',
  'MultipleFaces.Detected',
  'Pose.NotFacingFront',
  'Captcha.Invalid',
  'SmileID.NotValidated',
  'User.Underage',
  'Video.InternalError',
]

export interface PostStartVideoResponse {
  electronicIdAuthorization: string
  electronicIdDocType: number
  electronicIdUrl: string
  isBiometricConsentAccepted: boolean
}

import styled from 'styled-components'

interface ContainerProps {
  width?: string
  height?: string
  border?: string
  padding?: string
  fontFamily?: string
  fontSize?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-webcam-photo-capture-container',
})<ContainerProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.width ? props.width : '500px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  overflow: hidden;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  border: ${(props) => (props.border ? props.border : 'none')};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'sans-serif'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`

export const WebcamCapture = styled.div.attrs({
  className: 'authflow-webcam-photo-capture',
})`
  border-radius: 5px;
  width: 100%;
  aspect-ratio: 1/1;
  margin: 0 auto;

  canvas {
    display: none;
  }
`

export const VideoContainer = styled.div.attrs({
  className: 'authflow-webcam-photo-capture-video-container',
})<{ borderRadius?: string }>`
  width: 100%;
  position: relative;

  .frame {
    width: 80%;
    aspect-ratio: 3/2;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 3px solid #23f623;
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '6px'};
  }

  video,
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : '6px'};
  }

  img {
    top: 0px;
    left: 0px;
    position: absolute;
  }
`

export const ErrorMessage = styled.div.attrs({
  className: 'authflow-webcam-photo-capture-error',
})`
  color: red;
`

export const DeviceSelect = styled.div.attrs({
  className: 'authflow-webcam-photo-capture-select',
})`
  position: absolute;
  top: 10px;
  right: 10px;

  .select-input {
    &__indicator,
    &__control {
      opacity: 0;
      cursor: pointer;
    }

    &__menu {
      top: 30px;
      right: 0;
      min-width: 300px;
    }
  }

  & > div:first-child {
    position: absolute;
    top: 0;
    right: 12px;
    cursor: pointer;
  }
`

export const HiddenSourceWrap = styled.div`
  position: relative;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const HiddenSourceScale = styled.div`
  width: 4096px;

  video,
  canvas {
    width: 100%;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`

import React from 'react'
import { DtpInfoContainer } from '../../styles/dtp.styles'

export const DTPInfoGraph = () => {
  return (
    <DtpInfoContainer>
      <svg
        width="76"
        height="41"
        viewBox="0 0 76 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.7617 1.78549V9.60247C34.1984 4.00694 27.8755 0.283569 20.6565 0.283569C9.52561 0.283569 0.5 9.15538 0.5 20.088C0.5 31.0206 9.5256 39.8924 20.6636 39.8924C27.8755 39.8924 34.2055 36.169 37.7688 30.5735V38.3905H75.0377V1.78549H37.7617Z"
          fill="#2A0062"
        />
        <path
          d="M13.7006 24.2375C16.1472 24.2375 17.776 22.8194 17.776 20.9053C17.776 19.082 16.5313 18.104 14.1984 17.65C12.8115 17.3915 11.4175 16.9374 11.4175 15.8267C11.4175 15.1002 12.1003 14.4785 13.3307 14.4785C14.5612 14.4785 15.6494 15.0443 15.6494 15.9175C15.6494 16.1341 15.5925 16.2808 15.5356 16.4624L17.1999 17.0282C17.3848 16.6999 17.5199 16.3017 17.5199 15.8407C17.5199 14.0384 15.8343 12.8019 13.5014 12.8019C11.1828 12.8019 9.66785 14.22 9.66785 15.8197C9.66785 17.8595 11.2041 18.8236 13.5939 19.2078C15.372 19.5221 15.9267 20.1159 15.9267 20.9542C15.9267 21.8623 15.0946 22.5399 13.7219 22.5399C12.4488 22.5399 11.2753 21.9951 11.2753 20.8843C11.2753 20.7027 11.3108 20.5001 11.3677 20.2836L9.70341 19.6129C9.53272 19.9273 9.38336 20.3744 9.38336 20.8354C9.39758 22.8892 11.0334 24.2375 13.7006 24.2375Z"
          fill="white"
        />
        <path
          d="M19.0989 14.3737C19.0989 14.9745 19.5612 15.4285 20.1728 15.4285C20.7845 15.4285 21.2681 14.9745 21.2681 14.3737C21.2681 13.7729 20.7916 13.2979 20.1728 13.2979C19.5683 13.2979 19.0989 13.7729 19.0989 14.3737Z"
          fill="white"
        />
        <path
          d="M21.0263 16.5532H19.3407V24.0698H21.0263V16.5532Z"
          fill="white"
        />
        <path
          d="M26.2753 22.6377C25.0306 22.6377 24.0704 21.7855 24.0704 20.3464C24.0704 18.7607 25.0377 17.8176 26.2753 17.8176C27.5341 17.8176 28.4801 18.8375 28.4801 20.2347C28.473 21.6388 27.5128 22.6377 26.2753 22.6377ZM28.4019 16.5392V17.5592C27.9253 16.8466 27.1927 16.3925 25.9765 16.3925C23.9566 16.3925 22.3421 17.9573 22.3421 20.3395C22.3421 22.6308 23.8784 24.0698 25.8058 24.0698C27.1003 24.0698 27.9893 23.5249 28.3805 22.7216V23.5249C28.3805 24.943 27.8257 25.942 26.3606 25.942C25.3009 25.942 24.5043 25.418 24.2624 24.3981L22.7262 25.0338C23.2809 26.6894 24.5825 27.381 26.3606 27.381C28.7859 27.381 30.0661 25.7464 30.0661 23.4481V16.5323H28.4019V16.5392Z"
          fill="white"
        />
        <path
          d="M33.5157 19.8714C33.5157 18.5441 34.3336 17.8106 35.3293 17.8106C36.2753 17.8106 36.9011 18.4813 36.9011 19.7247V24.0768H38.5868V19.1589C38.5868 17.3915 37.4559 16.3925 35.8983 16.3925C34.9523 16.3925 34.0633 16.7418 33.4872 17.643V16.5462H31.8016V24.0628H33.4872V19.8574L33.5157 19.8714Z"
          fill="white"
        />
        <path
          d="M44.9523 20.2556C44.9523 18.6 46.1614 17.8176 47.2141 17.8176C48.3449 17.8176 49.1629 18.5092 49.3122 19.6199L50.8485 19.1519C50.5285 17.4055 49.0704 16.3856 47.2141 16.3856C45.1159 16.3856 43.345 17.9504 43.345 20.2626C43.345 22.6447 44.9595 24.2305 47.4559 24.2305C49.3122 24.2305 50.8485 23.1896 51.2539 21.2127L49.7176 20.6678C49.5469 21.7785 48.8997 22.8124 47.3848 22.8124C46.1757 22.8054 44.9523 21.8344 44.9523 20.2556Z"
          fill="white"
        />
        <path
          d="M55.9765 22.7425C54.7674 22.7425 53.7006 21.8134 53.7006 20.3395C53.7006 18.8655 54.7603 17.8805 55.9765 17.8805C57.1074 17.8805 58.2738 18.8096 58.2738 20.3395C58.2525 21.8693 57.0932 22.7425 55.9765 22.7425ZM58.2027 16.5323V17.7548C57.7617 16.9025 56.8514 16.3716 55.628 16.3716C53.5014 16.3716 52.0149 18.0272 52.0149 20.3395C52.0149 22.5609 53.537 24.2165 55.628 24.2165C56.7945 24.2165 57.6835 23.7485 58.2952 22.7984V24.0698H59.9097V16.5323H58.2027Z"
          fill="white"
        />
        <path
          d="M65.2439 24.2235C66.6878 24.2235 67.6693 23.3503 67.8186 21.8414L66.2041 21.527C66.1472 22.4002 65.7916 22.7984 65.1444 22.7984C64.6678 22.7984 64.1771 22.4841 64.1771 21.7576V17.9713H67.0861V16.5323H64.1771V14.1292H62.4986V16.5323H60.9623V17.9504H62.4986V21.6737C62.4843 23.1826 63.3734 24.2235 65.2439 24.2235Z"
          fill="white"
        />
        <path
          d="M40.0875 14.3737C40.0875 14.9745 40.5498 15.4285 41.1615 15.4285C41.7731 15.4285 42.2568 14.9745 42.2568 14.3737C42.2568 13.7729 41.7802 13.2979 41.1615 13.2979C40.5498 13.2979 40.0875 13.7729 40.0875 14.3737Z"
          fill="white"
        />
        <path
          d="M42.0149 16.5532H40.3293V24.0698H42.0149V16.5532Z"
          fill="white"
        />
      </svg>
    </DtpInfoContainer>
  )
}

import styled from 'styled-components'

interface ConditionProps {
  width?: string
}

export const Condition = styled.div.attrs({
  className: 'authflow-document-checkbox-condition',
})<ConditionProps>`
  width: ${(props) => (props.width ? props.width : '384px')};
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    width: 100%;
  }
`

interface ContentProps {
  margin?: string
  contentFontsize?: string
}

export const Content = styled.div.attrs({
  className: 'authflow-document-checkbox-content',
})<ContentProps>`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => (props.margin ? props.margin : '0 0 38px')};

  .label-text {
    font-size: ${(props) =>
      props.contentFontsize ? props.contentFontsize : '14px'};
  }
`

export const GoTo = styled.a.attrs({
  className: 'authflow-document-checkbox-link',
})`
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const MainLabel = styled.label.attrs({
  className: 'authflow-document-checkbox-label',
})`
  max-width: 90%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

import React from 'react'

interface DeviceDesktopProps {
  color: string
  thickness?: string
}

export const DeviceDesktop = ({ color, thickness }: DeviceDesktopProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.85714C1 3.21069 2.41423 2 4 2H20C21.5858 2 23 3.21069 23 4.85714V14.1429C23 15.7893 21.5858 17 20 17H13V20H16.5C17.0523 20 17.5 20.4477 17.5 21C17.5 21.5523 17.0523 22 16.5 22H7.5C6.94772 22 6.5 21.5523 6.5 21C6.5 20.4477 6.94772 20 7.5 20H11V17H4C2.41423 17 1 15.7893 1 14.1429V4.85714ZM4 4C3.37663 4 3 4.45225 3 4.85714V14.1429C3 14.5477 3.37663 15 4 15H20C20.6234 15 21 14.5477 21 14.1429V4.85714C21 4.45225 20.6234 4 20 4H4Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}

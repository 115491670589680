export const importantCountries = [
  // en
  'UnitedStates',
  'Morocco',
  'Mayotte',
  'Finland',
  'Italy',
  'Norway',
  'UnitedKingdom',
  'Russia',
  'Australia',
  'CaribbeanNetherlands',
  'Guadeloupe',
  // it
  'Italia',
]

import styled from 'styled-components'

export const CodeInputWrapper = styled.div<{
  isLoading?: boolean
  isError?: boolean
}>`
  width: 100%;

  p {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    color: #ec0000;
  }

  span {
    display: block;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
  }

  & > div > input {
    height: 50px;
    border-radius: 2px;
    border: 2px solid #ced4dc;

    color: #000;
    text-align: center;
    font-family: inherit;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;

    ${({ isLoading }) =>
      isLoading &&
      `
    background: #CED4DC;
    color: #9B9B9B;
  `}

    ${({ isError }) =>
      isError &&
      `
    color: #EC0000;
  `}
  }
`

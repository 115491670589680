import { ContentContainer } from '../components/Generic'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../components/App'
import { HorizontalIconContainer } from '../styles/ikano.styles'

const Icon = styled.div`
  width: 130px;
  height: 130px;
  margin-top: 70px;

  svg {
    width: 100%;
    height: 100%;
  }
`

const Header = styled.h1<{ color: string; fontFamily: string }>`
  color: ${({ color }) => color};
  text-align: center;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 29px;
  font-weight: 900;
  margin-bottom: 46px;
`

const Subtitle = styled.h2<{ color: string; fontFamily: string }>`
  color: ${({ color }) => color};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ fontFamily }) => fontFamily};
`

export const GoToMobileThankYouPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { SwitchToMobile: trans } = store.TranslationsState.translations

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header
        {...theme.header}
        fontFamily={theme.globals.fontFamilyHeadline}
        margin="30px 0 50px 0"
      >
        {trans.thankYouPage.header}
      </Header>

      <Subtitle {...theme.header} fontFamily={theme.globals.fontFamily}>
        {trans.thankYouPage.description}
      </Subtitle>

      <HorizontalIconContainer>
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="129"
            height="129"
            viewBox="0 0 129 129"
            fill="none"
          >
            <path
              d="M93.036 46.7452C93.8928 45.8884 93.8928 44.4993 93.036 43.6426C92.1792 42.7858 90.7901 42.7858 89.9334 43.6426L53.0918 80.4841L39.286 66.6783C38.4292 65.8215 37.0401 65.8215 36.1834 66.6783C35.3266 67.535 35.3266 68.9241 36.1834 69.7809L53.0918 86.6893L93.036 46.7452Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129 64.5C129 100.122 100.122 129 64.5 129C28.8776 129 0 100.122 0 64.5C0 28.8776 28.8776 0 64.5 0C100.122 0 129 28.8776 129 64.5ZM124.612 64.5C124.612 97.6991 97.6991 124.612 64.5 124.612C31.3009 124.612 4.38775 97.6991 4.38775 64.5C4.38775 31.3009 31.3009 4.38775 64.5 4.38775C97.6991 4.38775 124.612 31.3009 124.612 64.5Z"
              fill="black"
            />
          </svg>
        </Icon>
      </HorizontalIconContainer>
    </ContentContainer>
  )
})

import React from 'react'
import { HorizontalIconContainer } from '../../../styles/ikano.styles'

export const AndroidShieldErrorIcon = () => {
  return (
    <HorizontalIconContainer>
      <svg
        width="305"
        height="142"
        viewBox="0 0 305 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.212891 27C0.212891 12.0883 12.3012 0 27.2129 0H277.213C292.125 0 304.213 12.0883 304.213 27V142H0.212891V27Z"
          fill="#DCDBE2"
        />
        <path
          d="M52.2129 55C52.2129 45.0589 60.2718 37 70.2129 37H213.213C223.154 37 231.213 45.0589 231.213 55C231.213 64.9411 223.154 73 213.213 73H70.2129C60.2718 73 52.2129 64.9411 52.2129 55Z"
          fill="white"
        />
        <path
          d="M15.2129 55C15.2129 48.9249 20.1378 44 26.2129 44C32.288 44 37.2129 48.9249 37.2129 55C37.2129 61.0751 32.288 66 26.2129 66C20.1378 66 15.2129 61.0751 15.2129 55Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.213 46H251.213C249.556 46 248.213 47.3431 248.213 49V61C248.213 62.6569 249.556 64 251.213 64H263.213C264.87 64 266.213 62.6569 266.213 61V49C266.213 47.3431 264.87 46 263.213 46ZM251.213 44C248.451 44 246.213 46.2386 246.213 49V61C246.213 63.7614 248.451 66 251.213 66H263.213C265.974 66 268.213 63.7614 268.213 61V49C268.213 46.2386 265.974 44 263.213 44H251.213Z"
          fill="white"
        />
        <path
          d="M283.213 47.625C283.213 46.1753 284.388 45 285.838 45C287.288 45 288.463 46.1753 288.463 47.625C288.463 49.0747 287.288 50.25 285.838 50.25C284.388 50.25 283.213 49.0747 283.213 47.625Z"
          fill="white"
        />
        <path
          d="M283.213 55.5C283.213 54.0503 284.388 52.875 285.838 52.875C287.288 52.875 288.463 54.0503 288.463 55.5C288.463 56.9497 287.288 58.125 285.838 58.125C284.388 58.125 283.213 56.9497 283.213 55.5Z"
          fill="white"
        />
        <path
          d="M283.213 63.375C283.213 61.9253 284.388 60.75 285.838 60.75C287.288 60.75 288.463 61.9253 288.463 63.375C288.463 64.8247 287.288 66 285.838 66C284.388 66 283.213 64.8247 283.213 63.375Z"
          fill="white"
        />
        <path
          d="M15.2129 18.5C15.2129 16.0147 17.2276 14 19.7129 14H47.7129C50.1982 14 52.2129 16.0147 52.2129 18.5C52.2129 20.9853 50.1982 23 47.7129 23H19.7129C17.2276 23 15.2129 20.9853 15.2129 18.5Z"
          fill="white"
        />
        <path
          d="M272.213 18.5C272.213 16.0147 274.228 14 276.713 14H283.713C286.198 14 288.213 16.0147 288.213 18.5C288.213 20.9853 286.198 23 283.713 23H276.713C274.228 23 272.213 20.9853 272.213 18.5Z"
          fill="white"
        />
        <path
          d="M251.213 18.5C251.213 16.0147 253.228 14 255.713 14H262.713C265.198 14 267.213 16.0147 267.213 18.5C267.213 20.9853 265.198 23 262.713 23H255.713C253.228 23 251.213 20.9853 251.213 18.5Z"
          fill="white"
        />
        <path
          d="M8.21289 100C8.21289 93.3726 13.5855 88 20.2129 88H284.213C290.84 88 296.213 93.3726 296.213 100V142H8.21289V100Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.2129 80C84.02 80 95.2129 68.8071 95.2129 55C95.2129 41.1929 84.02 30 70.2129 30C56.4058 30 45.2129 41.1929 45.2129 55C45.2129 68.8071 56.4058 80 70.2129 80ZM70.2129 84C86.2291 84 99.2129 71.0163 99.2129 55C99.2129 38.9837 86.2291 26 70.2129 26C54.1966 26 41.2129 38.9837 41.2129 55C41.2129 71.0163 54.1966 84 70.2129 84Z"
          fill="#EC0000"
        />
        <path
          d="M64.7956 50.9871L70.2622 48.2275C70.5456 48.0844 70.8801 48.0844 71.1635 48.2275L76.5959 50.9698C76.9672 51.1572 77.183 51.5514 77.1173 51.9621C76.7749 54.1003 75.3919 60.6663 71.0839 62.828C70.85 62.9454 70.5736 62.949 70.3356 62.8402C65.6111 60.6801 64.5059 54.0366 64.2713 51.9295C64.2274 51.5353 64.4415 51.1658 64.7956 50.9871Z"
          fill="black"
          stroke="black"
        />
      </svg>
    </HorizontalIconContainer>
  )
}

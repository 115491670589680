import React from 'react'

export const Lightbulb = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" className="document--tenary--fill" />
      <path
        d="M23 10C23 10.5523 23.4477 11 24 11C24.5523 11 25 10.5523 25 10V7C25 6.44772 24.5523 6 24 6C23.4477 6 23 6.44772 23 7V10Z"
        className="document--primary--fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 22.2C33 17.119 28.9706 13 24 13C19.0294 13 15 17.119 15 22.2C15 24.8269 15.9718 26.3729 17.0122 28.0279C17.9938 29.5894 19.0365 31.248 19.3816 34.0033C19.5189 35.0994 20.3954 36 21.5 36H26.5C27.6046 36 28.4811 35.0994 28.6184 34.0033C28.9635 31.248 30.0062 29.5894 30.9878 28.0279C32.0282 26.3729 33 24.8269 33 22.2ZM24 15C27.8248 15 31 18.1819 31 22.2C31 24.1573 30.3509 25.2811 29.3819 26.8244L29.2758 26.9932C28.2777 28.5796 27.0346 30.5554 26.6339 33.7548C26.6214 33.8545 26.5751 33.9329 26.5314 33.9752C26.5147 33.9913 26.5029 33.998 26.4988 34H21.5012C21.4971 33.998 21.4853 33.9913 21.4686 33.9752C21.4249 33.9329 21.3786 33.8545 21.3661 33.7548C20.9654 30.5554 19.7223 28.5796 18.7242 26.9932L18.6181 26.8244C17.6491 25.2811 17 24.1573 17 22.2C17 18.1819 20.1752 15 24 15Z"
        className="document--primary--fill"
      />
      <path
        d="M15.2426 14.2427C14.8521 14.6332 14.2189 14.6332 13.8284 14.2427L11.7071 12.1213C11.3166 11.7308 11.3166 11.0977 11.7071 10.7071C12.0976 10.3166 12.7308 10.3166 13.1213 10.7071L15.2426 12.8284C15.6332 13.219 15.6332 13.8521 15.2426 14.2427Z"
        className="document--primary--fill"
      />
      <path
        d="M34.1213 29.7071C33.7308 29.3166 33.0976 29.3166 32.7071 29.7071C32.3166 30.0976 32.3166 30.7308 32.7071 31.1213L34.8284 33.2426C35.219 33.6332 35.8521 33.6332 36.2426 33.2426C36.6332 32.8521 36.6332 32.219 36.2426 31.8284L34.1213 29.7071Z"
        className="document--primary--fill"
      />
      <path
        d="M32.7071 14.2426C32.3166 13.8521 32.3166 13.2189 32.7071 12.8284L34.8284 10.7071C35.219 10.3166 35.8521 10.3166 36.2426 10.7071C36.6332 11.0976 36.6332 11.7308 36.2426 12.1213L34.1213 14.2426C33.7308 14.6332 33.0976 14.6332 32.7071 14.2426Z"
        className="document--primary--fill"
      />
      <path
        d="M15.2426 31.1213C15.6332 30.7308 15.6332 30.0976 15.2426 29.7071C14.8521 29.3166 14.2189 29.3166 13.8284 29.7071L11.7071 31.8284C11.3166 32.2189 11.3166 32.8521 11.7071 33.2426C12.0976 33.6331 12.7308 33.6331 13.1213 33.2426L15.2426 31.1213Z"
        className="document--primary--fill"
      />
      <path
        d="M8 22C8 21.4477 8.44772 21 9 21H12C12.5523 21 13 21.4477 13 22C13 22.5523 12.5523 23 12 23H9C8.44772 23 8 22.5523 8 22Z"
        className="document--primary--fill"
      />
      <path
        d="M36 21C35.4477 21 35 21.4477 35 22C35 22.5523 35.4477 23 36 23H39C39.5523 23 40 22.5523 40 22C40 21.4477 39.5523 21 39 21H36Z"
        className="document--primary--fill"
      />
      <path
        d="M20 38C20 37.4477 20.4477 37 21 37H27C27.5523 37 28 37.4477 28 38C28 38.5523 27.5523 39 27 39H21C20.4477 39 20 38.5523 20 38Z"
        className="document--primary--fill"
      />
      <path
        d="M22 40C21.4477 40 21 40.4477 21 41C21 41.5523 21.4477 42 22 42H26C26.5523 42 27 41.5523 27 41C27 40.4477 26.5523 40 26 40H22Z"
        className="document--primary--fill"
      />
    </svg>
  )
}

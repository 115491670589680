import styled from 'styled-components'

export const JitWelcomeLogos = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
`

export const WelcomeLogo = styled.div`
  width: ${(props) => (props.width ? props.width : '76px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : '0')};

  img {
    width: 100%;
  }
`

export const LogoDivider = styled.div`
  height: 40px;
  width: 1px;
  background-color: #ebebeb;
  margin-top: 8px;
`

export const JitpayLogo = styled.div``

export const WelcomeCheck = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0')};

  @media (max-width: 900px) {
    padding: ${(props) => (props.mobilePadding ? props.mobilePadding : '')};
  }
`

export const Spacer = styled.div`
  width: 100%;
  height: 200px;
`

export const Divider = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  background-color: #dbdbdb;
  margin: ${(props) => (props.margin ? props.margin : '20px')};
`

export const OnboardingWarning = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: #edeeef;
  display: flex;
  padding: 10px 30px;
  box-sizing: border-box;
`

export const WarnLeft = styled.div`
  margin: 15px 30px 15px 0px;
`

export const WarnRight = styled.div`
  color: #4c4c4c;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 134.187%;
  margin-top: 2px;
`

export const JitpayInfoContainer = styled.div`
  width: 100%;

  svg {
    width: 100%;
  }
`

export const JitpayGeneralConditionsSpacerMobile = styled.div`
  width: 100%;
  height: 100px;
`

export const IconContainer = styled.div`
  width: 100%;
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'
import { Language, TranslationsInterface } from '../types/translations'

function getFirstTwoCharacters(inputString) {
  if (inputString.length < 2) {
    return inputString
  }
  return inputString.substring(0, 2)
}

const findSubstringInString = (mainString, substrings) => {
  for (const substring of substrings) {
    if (mainString.includes(substring)) {
      return substring
    }
  }
  return ''
}

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

const customLanguageConfigurations = ['ikano', 'bmw']

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = translations.en
  language = 'en'
  paramLanguage = 'en'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTranslations(
    paramLanguage: Language,
    configurationId?: string
  ): void {
    this.language = getFirstTwoCharacters(paramLanguage)

    const lang = configurationId
      ? `${getFirstTwoCharacters(paramLanguage)}${capitalize(
          findSubstringInString(configurationId, customLanguageConfigurations)
        )}`
      : paramLanguage

    this.paramLanguage = lang
    this.translations = translations[lang]
      ? { ...translations.en, ...translations[lang] }
      : translations[paramLanguage]
  }
}

async function getChecksumSha256(blob: Blob): Promise<string> {
  const uint8Array = new Uint8Array(await blob.arrayBuffer())
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Array)
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  return hashArray.map((h) => h.toString(16).padStart(2, '0')).join('')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getChecksumSha256FromFile(file: any, type: string) {
  const pdfstr = await fetch(file)
  const blobFromFetch = await pdfstr.blob()

  const blob = new Blob([blobFromFetch], { type })

  return await getChecksumSha256(blob)
}

import { AxiosError } from 'axios'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'

const excludeUrls = ['kontomatik/check-status']

const AxiosErrorHandler = observer(({ children }) => {
  const store = useContext(StoreContext)
  const { themeId } = store.InterfaceState
  const { language } = store.TranslationsState

  axiosInstance.interceptors.response.use(
    undefined,
    async (error: AxiosError) => {
      if (
        !window.location.pathname.includes('error') &&
        !excludeUrls.some((v) => error.config.url.includes(v))
      ) {
        if (
          error.response &&
          error.response.status >= 401 &&
          error.response.status <= 404 &&
          process.env.NODE_ENV === 'production'
        ) {
          window.location.replace(
            `${process.env.WEB_API_URL}/${process.env.ONBOARDING_WEB_API_PATH}/session-unauthorized?themeId=${themeId}&language=${language}`
          )
        }
      }
      return Promise.reject(error)
    }
  )

  return children
})

export default AxiosErrorHandler

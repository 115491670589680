import React from 'react'

interface AlertTriangleProps {
  color: string
  thickness?: string
}

export const AlertTriangle = ({ color, thickness }: AlertTriangleProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_949_22806)">
        <path
          d="M12 6.5C12.5523 6.5 13 6.94772 13 7.5L13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5L11 7.5C11 6.94772 11.4477 6.5 12 6.5Z"
          fill={`${color ? color : '#280b60'}`}
          stroke={`${color ? color : '#280b60'}`}
          strokeWidth={`${thickness ? thickness : '0'}`}
          strokeLinecap="round"
          className="colorized--fill colorized--stroke"
        />
        <path
          d="M12 18.5C12.8284 18.5 13.5 17.8284 13.5 17C13.5 16.1716 12.8284 15.5 12 15.5C11.1716 15.5 10.5 16.1716 10.5 17C10.5 17.8284 11.1716 18.5 12 18.5Z"
          fill={`${color ? color : '#280b60'}`}
          stroke={`${color ? color : '#280b60'}`}
          strokeWidth={`${thickness ? thickness : '0'}`}
          strokeLinecap="round"
          className="colorized--fill colorized--stroke"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.82664 2.22902C10.7938 0.590326 13.2063 0.590325 14.1735 2.22902L23.6599 18.3024C24.6578 19.9933 23.3638 22 21.4865 22H2.51362C0.63634 22 -0.657696 19.9933 0.340215 18.3024L9.82664 2.22902ZM12.4511 3.24557C12.2578 2.91814 11.7423 2.91814 11.549 3.24557L2.06261 19.319C1.90904 19.5792 2.07002 20 2.51362 20H21.4865C21.9301 20 22.0911 19.5792 21.9375 19.319L12.4511 3.24557Z"
          fill={`${color ? color : '#280b60'}`}
          stroke={`${color ? color : '#280b60'}`}
          strokeWidth={`${thickness ? thickness : '0'}`}
          strokeLinecap="round"
          className="colorized--fill colorized--stroke"
        />
      </g>
      <defs>
        <clipPath id="clip0_949_22806">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import axios from 'axios'
import { devLog } from './devLog'

interface actionReportPayload {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
}

export async function actionReport(event: actionReportPayload) {
  try {
    await axios.post(
      `${process.env.WEB_API_URL}/${process.env.ONBOARDING_WEB_API_PATH}/audit-log-gateway`,
      event,
      {
        withCredentials: true,
      }
    )
  } catch (e) {
    devLog(e)
  }
}

import {
  ContentContainer,
  Header,
  LoadingSpinner,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../components/App'
import { MarginedWithHeight } from '../../styles/layout.styles'
import { IkanoStyleWrap } from '../../styles/ikano.styles'
import { DefaultScenario } from '../../components/AddressUploadIkano/DefaultScenario'
import { PayslipScenario } from '../../components/AddressUploadIkano/PayslipScenario'
import { ResidenceProofRetryScenario } from '../../components/AddressUploadIkano/ResidenceProofRetryScenario'
import { IdCardRetryScenario } from '../../components/AddressUploadIkano/IdCardRetryScenario'

export const AddressUploadIkanoPage = observer(() => {
  const store = useContext(StoreContext)

  const { theme } = store.InterfaceState
  const { context } = store.ScenarioState
  const { pixelSpeak: trans } = store.TranslationsState.translations

  const [loading, setLoading] = useState(false)

  const renderUploadComponent = () => {
    if (context?.isPaySlip) {
      return <PayslipScenario setLoading={setLoading} />
    }

    if (
      context?.isRetryAttempt &&
      context?.previousAttempt?.documentType === 'ResidenceProof'
    ) {
      return <ResidenceProofRetryScenario setLoading={setLoading} />
    } else if (
      context?.isRetryAttempt &&
      context?.previousAttempt?.documentType === 'IdCard'
    ) {
      return <IdCardRetryScenario setLoading={setLoading} />
    }

    return <DefaultScenario setLoading={setLoading} />
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <IkanoStyleWrap>
        {loading ? (
          <>
            <MarginedWithHeight margin="0 0 15px" height="0">
              <Header
                {...theme.header}
                fontFamily={theme.globals.fontFamilyHeadline}
              >
                {trans.verifying}
              </Header>
            </MarginedWithHeight>
            <LoadingSpinner
              width="68px"
              padding="100px 0"
              {...theme.loadingSpinner}
            />
          </>
        ) : (
          <>{renderUploadComponent()}</>
        )}
      </IkanoStyleWrap>
    </ContentContainer>
  )
})

import React from 'react'

interface PenProps {
  color: string
  thickness?: string
}

export const Pen = ({ color, thickness }: PenProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29289 1.29289C1.55303 1.03276 1.92083 0.945902 2.25287 1.03233L16.7347 4.52794C17.0542 4.60506 17.3154 4.83427 17.4334 5.14104L19.8572 11.443L22 13.5858C22.7811 14.3669 22.7811 15.6332 22 16.4142L16.4142 22C15.6332 22.7811 14.3669 22.7811 13.5858 22L11.443 19.8572L5.14104 17.4334C4.83427 17.3154 4.60506 17.0542 4.52794 16.7347L1.03233 2.25287C0.945902 1.92083 1.03276 1.55303 1.29289 1.29289ZM8.87889 10.2931L3.80604 5.22025L6.34893 15.7551L12.359 18.0667C12.4896 18.1169 12.6082 18.194 12.7071 18.2929L15 20.5858L20.5858 15L18.2929 12.7071C18.194 12.6082 18.1169 12.4896 18.0667 12.359L15.7551 6.34893L5.22025 3.80604L10.2931 8.87889C10.354 8.93977 10.4054 9.00654 10.4473 9.07736C10.6228 9.02698 10.8083 9 11 9C12.1046 9 13 9.89543 13 11C13 12.1046 12.1046 13 11 13C9.89543 13 9 12.1046 9 11C9 10.8083 9.02698 10.6228 9.07736 10.4473C9.00654 10.4054 8.93977 10.354 8.87889 10.2931Z"
        fill={`${color ? color : '#280b60'}`}
        stroke={`${color ? color : '#280b60'}`}
        strokeWidth={`${thickness ? thickness : '0'}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  )
}

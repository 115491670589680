import { Button, ContentContainer, Header } from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import {
  ButtonText,
  IntroParagraph,
  UploadTypeList,
} from '../../styles/generic.styles'
import { IconContainer, IkanoStyleWrap } from '../../styles/ikano.styles'
import {
  Divider,
  JitpayGeneralConditionsSpacerMobile,
} from '../../styles/jitpay.styles'
import {
  ContentContainerBottomLeft,
  ContentContainerBottomUpload,
} from '../../styles/layout.styles'

export const AddressVerificationIkanoPage = observer(() => {
  const store = useContext(StoreContext)

  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState

  const { addressVerificationIkano: trans } =
    store.TranslationsState.translations

  const [mobileVersion, setMobileVersion] = useState(false)

  const handleProceed = async () => {
    // try {
    // } catch (e) {}
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <IkanoStyleWrap>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.header}
        </Header>
        <IntroParagraph
          color={theme.globals.linkColor}
          colorHover={theme.globals.linkColorHover}
        >
          {trans.description}

          <UploadTypeList>
            {trans.documentsList.map((doctype) => (
              <li key={doctype}>{doctype}</li>
            ))}
          </UploadTypeList>
        </IntroParagraph>

        <Divider margin="30px 0" />

        <IconContainer>
          <svg
            width="124"
            height="169"
            viewBox="0 0 124 169"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 2124684">
              <path
                id="Rectangle 2730"
                d="M113.5 1.2832C118.471 1.2832 122.5 5.31264 122.5 10.2832L122.5 35.0272L122.5 138.955C122.5 143.926 118.471 147.955 113.5 147.955L32.5 147.955C27.5294 147.955 23.5 143.926 23.5 138.955L23.5 10.2832C23.5 5.31265 27.5294 1.2832 32.5 1.2832L89.16 1.2832L113.5 1.2832Z"
                fill="white"
                stroke="#DCDBE2"
                strokeWidth="2"
              />
              <path
                id="Rectangle 2700"
                d="M103.5 11.2832C108.471 11.2832 112.5 15.3126 112.5 20.2832L112.5 45.0272L112.5 148.955C112.5 153.926 108.471 157.955 103.5 157.955L22.5 157.955C17.5294 157.955 13.5 153.926 13.5 148.955L13.5 20.2832C13.5 15.3126 17.5294 11.2832 22.5 11.2832L79.16 11.2832L103.5 11.2832Z"
                fill="white"
                stroke="#DCDBE2"
                strokeWidth="2"
              />
              <path
                id="Rectangle 2699"
                d="M66.16 45.0272C66.16 51.1023 71.0849 56.0272 77.16 56.0272L100.5 56.0272L100.5 158.955C100.5 163.926 96.4706 167.955 91.5 167.955L10.5 167.955C5.52944 167.955 1.5 163.926 1.5 158.955L1.50001 30.2832C1.50001 25.3126 5.52945 21.2832 10.5 21.2832L66.16 21.2832L66.16 45.0272Z"
                fill="white"
                stroke="#DCDBE2"
                strokeWidth="2"
              />
              <path
                id="Vector 283"
                d="M16.5 38.2832L50.5 38.2832"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 285"
                d="M50.5 70.2832L85.5 70.2832"
                stroke="black"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 286"
                d="M50.5 80.2832L62.5 80.2832"
                stroke="black"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 287"
                d="M50.5 90.2832L74.5 90.2832"
                stroke="black"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 284"
                d="M16.5 48.2832L40.5 48.2832"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 288"
                d="M16.5 108.283L85.5 108.283"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 289"
                d="M16.5 118.283L85.5 118.283"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 290"
                d="M16.5 128.283L85.5 128.283"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 291"
                d="M16.5 138.283L85.5 138.283"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 292"
                d="M65.5 153.283L85.5 153.283"
                stroke="#DCDBE2"
                strokeWidth="5"
                strokeLinecap="round"
              />
              <path
                id="Vector 282"
                d="M66.3521 21.0908L101.096 55.8348"
                stroke="#DCDBE2"
                strokeWidth="2"
              />
              <g id="Group 2124681">
                <path
                  id="Union"
                  d="M34.1364 68.2832C35.492 68.2832 36.5909 69.4025 36.5909 70.7832V75.9977L40.7811 80.2654C41.7396 81.2417 41.7396 82.8247 40.7811 83.801C39.978 84.6189 38.7569 84.7516 37.8182 84.199V93.2832H30.4545V87.0332C30.4545 85.6525 29.3556 84.5332 28 84.5332C26.6444 84.5332 25.5455 85.6525 25.5455 87.0332V93.2832H18.1818V84.199C17.2431 84.7516 16.022 84.6189 15.2189 83.801C14.2604 82.8247 14.2604 81.2417 15.2189 80.2654L26.0908 69.1922C27.1452 68.1183 28.8548 68.1183 29.9092 69.1922L31.6818 70.9977V70.7832C31.6818 69.4025 32.7808 68.2832 34.1364 68.2832Z"
                  fill="black"
                />
              </g>
            </g>
          </svg>
        </IconContainer>

        {mobileVersion ? <JitpayGeneralConditionsSpacerMobile /> : <></>}
        <ContentContainerBottomUpload>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            id="btn-proceed"
            onClick={handleProceed}
            {...theme.button}
            minWidth="224px"
            paddingMobile="14px 40px"
          >
            <ButtonText>{trans.proceed}</ButtonText>
          </Button>
        </ContentContainerBottomUpload>
      </IkanoStyleWrap>
    </ContentContainer>
  )
})

import styled from 'styled-components'

export const DtpInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Divider = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  background-color: #ced4dc;
  margin: ${(props) => (props.margin ? props.margin : '20px')};
`

export const DtpGeneralConditionsSpacerMobile = styled.div`
  width: 100%;
  height: 100px;
`
